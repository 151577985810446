import { post, put } from "services/api"
import { employerPrefix } from "utilities/url"
import { updatePatientUrl } from "../../urls"
import { Patient } from "../../sharedTypes"

export const updatePatient = async (
  supplierOrganizationId: string,
  csrInboxStateId: string,
  patient: Partial<Patient> | { masterPatientId: string }
): Promise<Patient> => {
  return await put(updatePatientUrl(supplierOrganizationId, csrInboxStateId), {
    patient,
  }).then(({ data }) => data as Patient)
}

type PatientSearchParameters = {
  firstName?: string
  lastName?: string
  dateOfBirth?: string
  sex?: "male" | "female" | "unknown"
  supplierId: string
  supplierSystemInstanceId?: string
}

export type ParachutePatient = {
  firstName: string
  lastName: string
  dateOfBirth: string
  sex: "male" | "female" | "unknown"
  masterPatientId: string
  supplierSystemPatients: { supplierSystemPatientId: string }[]
}
export type ExternalPatient = {
  firstName: string
  lastName: string
  dateOfBirth: string
  deliveryZip: string
  supplierSystemPatientKey: string
  supplierSystemInstanceName: string
  profileUrl: string
}
type Errors = {
  external: string[]
  parachute: string[]
}

type PatientSearchResponse = {
  parachute: ParachutePatient[]
  external: ExternalPatient[]
  errors: Errors
}
export const searchPatients = async (
  params: PatientSearchParameters,
  supplierOrganizationId
): Promise<PatientSearchResponse> => {
  const prefix = employerPrefix("SupplierOrganization", supplierOrganizationId)
  const { supplierId, supplierSystemInstanceId, ...rest } = params
  const searchParams = {
    ...rest,
    supplierIds: [supplierId],
    supplierSystemInstanceIds: supplierSystemInstanceId
      ? [supplierSystemInstanceId]
      : [],
    includeParachuteResults: true,
  }

  return post(
    `${prefix}/supplier_system_integrated_search/patient_search.json`,
    searchParams
  ).then((response) => response.data)
}
