import React, { useState, useCallback } from "react"
import { useMutation } from "@tanstack/react-query"
import { canopyColorTextSecondary } from "./index.module.scss"
import { actionColumnPadding } from "../../index.module.scss"
import { ExternalPatient, ParachutePatient, searchPatients } from "./api"
import { CanopyForm } from "@parachutehealth/canopy-form"
import { CanopyTextInputField } from "@parachutehealth/canopy-text-input-field"
import { CanopyButton } from "@parachutehealth/canopy-button"
import { CanopyFlex } from "@parachutehealth/canopy-flex"
import { CanopyPickerField } from "@parachutehealth/canopy-picker-field"
import { isOptionItem } from "../DataEntryColumn/helpers"
import { updatePatient } from "./api"
import { usePatientQuery } from "../../queries"
import { ExternalPatientCard, ParachutePatientCard } from "./PatientCard"

type PatientSearchProps = {
  supplier: {
    id: string
    supplierSystemInstanceId?: string
  }
  supplierOrganizationId: string
  csrInboxStateId: string
}

const sexOptions = {
  male: "male",
  female: "female",
  unknown: "unknown",
}

export const PatientSearch = (props: PatientSearchProps) => {
  const { data: patient } = usePatientQuery()
  const [formData, setFormData] = useState({
    ...patient,
    sex: patient?.sex ? sexOptions[patient.sex] : "none_selected",
  })
  const [searchHasRun, setSearchHasRun] = useState(false)
  const [parachutePatients, setParachutePatients] = useState<
    ParachutePatient[]
  >([])
  const [externalPatients, setExternalPatients] = useState<ExternalPatient[]>(
    []
  )

  const { mutate, isLoading } = useMutation({
    mutationFn: async (data: typeof formData) => {
      const updateData = {
        dateOfBirth: data.dateOfBirth,
        firstName: data.firstName,
        lastName: data.lastName,
        sex: sexOptions[data.sex] || null,
      }
      updatePatient(
        props.supplierOrganizationId,
        props.csrInboxStateId,
        updateData
      )
      const response = await searchPatients(
        {
          ...updateData,
          supplierId: props.supplier.id,
          supplierSystemInstanceId: props.supplier.supplierSystemInstanceId,
        },
        props.supplierOrganizationId
      )
      setSearchHasRun(true)
      return response
    },
    onSuccess: (data) => {
      setParachutePatients(data.parachute)
      setExternalPatients(data.external)
    },
    onError: (error) => {
      console.error(error)
    },
  })

  const handleInputChange = useCallback((name: string, value: string) => {
    setFormData((prev) => ({ ...prev, [name]: value }))
  }, [])

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault()
    mutate(formData)
  }

  return (
    <div className={actionColumnPadding}>
      <h2 className="canopy-typography-heading-xlarge">Patient search</h2>
      <p
        className={`canopy-typography-body-medium ${canopyColorTextSecondary}`}
      >
        Enter the information below to search for a matching patient record.
      </p>

      <CanopyForm onSubmit={handleSubmit}>
        <CanopyFlex gap="12X">
          <CanopyTextInputField
            label="First name"
            style={{ width: "100%" }}
            value={formData.firstName}
            onChange={(e) => handleInputChange("firstName", e.target.value)}
          />

          <CanopyTextInputField
            label="Last name"
            style={{ width: "100%" }}
            value={formData.lastName}
            onChange={(e) => handleInputChange("lastName", e.target.value)}
          />
        </CanopyFlex>
        <CanopyFlex gap="12X">
          <CanopyTextInputField
            style={{ width: "100%" }}
            label="Date of birth"
            value={formData.dateOfBirth}
            onChange={(e) => handleInputChange("dateOfBirth", e.target.value)}
          />
          <CanopyPickerField
            label="Sex"
            style={{ width: "100%" }}
            value={{ value: formData.sex, label: formData.sex }}
            onChange={(value) => {
              if (isOptionItem(value)) {
                handleInputChange("sex", value.value)
              } else if (Array.isArray(value)) {
                console.log("We shouldn't be here")
              }
            }}
            options={[
              { label: "Select sex...", value: "none_selected" },
              { label: "female", value: "female" },
              { label: "male", value: "male" },
              { label: "unspecified/prefer not to say", value: "unknown" },
            ]}
          />
        </CanopyFlex>
        <CanopyButton type="submit" disabled={isLoading}>
          Search
        </CanopyButton>
      </CanopyForm>

      {searchHasRun && (
        <p
          className={`canopy-typography-body-medium ${canopyColorTextSecondary}`}
        >
          Select a patient to view their order history, or create a new one if
          not listed.
        </p>
      )}
      <div aria-live="polite">
        {searchHasRun && (
          <h2 className="canopy-typography-heading-medium canopy-mt-10x">
            {`Results (${parachutePatients.length + externalPatients.length})`}
          </h2>
        )}
      </div>

      {searchHasRun && (
        <div role="list" aria-label="Patient search results">
          {parachutePatients.map((p) => (
            <ParachutePatientCard
              key={`mpid:${p.masterPatientId}`}
              patient={p}
              supplierOrganizationId={props.supplierOrganizationId}
              csrInboxStateId={props.csrInboxStateId}
            />
          ))}

          {externalPatients.map((p) => (
            <ExternalPatientCard
              key={`supplierSystemPatientKey:${p.supplierSystemPatientKey}`}
              patient={p}
            />
          ))}
        </div>
      )}
    </div>
  )
}
