import React from "react"
import { ExternalPatient, ParachutePatient, updatePatient } from "../api"
import { CanopyFlex } from "@parachutehealth/canopy-flex"
import { canopyColorTextSecondary } from "./index.module.scss"
import { CanopyIcon } from "@parachutehealth/canopy-icon"
import { useQueryClient, useMutation } from "@tanstack/react-query"
import { patientQueryKey } from "applications/SupplierOrganizationInboxDocument/queries"
import { useHistory, Link } from "react-router-dom"
import { handleError } from "utilities/error"
import { CanopyButton } from "@parachutehealth/canopy-button"
import { Patient } from "../../../sharedTypes"

type PatientHeaderProps = {
  firstName: string
  lastName: string
}

const PatientHeader = ({ firstName, lastName }: PatientHeaderProps) => (
  <h3 className="canopy-typography-body-large canopy-typography-font-weight-bold">
    {`${firstName} ${lastName}`}
  </h3>
)

const StackedData = ({ label, value }) => (
  <CanopyFlex gap="4X" direction="column">
    <span
      className={`canopy-typography-body-xsmall ${canopyColorTextSecondary}`}
    >
      {label}
    </span>
    <span className="canopy-typography-body-medium">{value}</span>
  </CanopyFlex>
)

type BasePatientCardProps = {
  children: React.ReactNode
  ariaLabel: string
}

const BasePatientCard = ({ children, ariaLabel }: BasePatientCardProps) => (
  <div className="card canopy-p-12" role="listitem" aria-label={ariaLabel}>
    <CanopyFlex gap="12X" direction="column">
      {children}
    </CanopyFlex>
  </div>
)

type Props = {
  patient: ParachutePatient
  supplierOrganizationId: string
  csrInboxStateId: string
}
export const ParachutePatientCard = ({
  patient,
  supplierOrganizationId,
  csrInboxStateId,
}: Props) => {
  const queryClient = useQueryClient()
  const history = useHistory()
  const mutatePatient = useMutation({
    mutationFn: async () => {
      return updatePatient(supplierOrganizationId, csrInboxStateId, {
        masterPatientId: patient.masterPatientId,
      })
    },
    onSuccess: (responsePatient) => {
      queryClient.setQueryData<Patient>(patientQueryKey, responsePatient)
      history.push("/order_history")
    },
    onError: () => {
      handleError(undefined, "error updating patient")
    },
  })
  return (
    <BasePatientCard
      ariaLabel={`Parachute Patient Card for ${patient.firstName} ${patient.lastName}`}
    >
      <CanopyFlex
        gap="16X"
        justifyContent="space-between"
        style={{ width: "100%" }}
        alignItems="flex-start"
        wrap
      >
        <CanopyFlex gap="6X">
          <PatientHeader
            firstName={patient.firstName}
            lastName={patient.lastName}
          />
          <span
            className={`canopy-typography-body-small ${canopyColorTextSecondary}`}
          >
            {patient.sex}
          </span>
        </CanopyFlex>
        <CanopyButton
          as={Link}
          onClick={() => mutatePatient.mutateAsync()}
          to="#"
          variant="secondary"
          iconEnd="arrow-right"
          aria-label={`View orders for ${patient.firstName} ${patient.lastName}`}
        >
          View orders
        </CanopyButton>
      </CanopyFlex>
      <StackedData label="DOB" value={patient.dateOfBirth} />
    </BasePatientCard>
  )
}

type ExternalProps = {
  patient: ExternalPatient
}

export const ExternalPatientCard = ({ patient }: ExternalProps) => {
  return (
    <BasePatientCard
      ariaLabel={`External Patient Card for ${patient.firstName} ${patient.lastName}`}
    >
      <PatientHeader
        firstName={patient.firstName}
        lastName={patient.lastName}
      />
      <CanopyFlex gap="16X" wrap>
        <StackedData label="DOB" value={patient.dateOfBirth} />
        <StackedData label="Zip" value={patient.deliveryZip} />
      </CanopyFlex>
      <a
        href={patient.profileUrl}
        target="_blank"
        rel="noreferrer"
        className="link"
      >
        View profile in brightree <CanopyIcon name="arrow-up-right" />
      </a>
    </BasePatientCard>
  )
}
