import React from "react"
import Logo from "../../components/Logo"
import SignOutButton from "../Workflow/containers/Navigation/components/TopNavigation/TopLeftNavButton/SignOutButton"

interface Props {
  firstName: string
  lastName: string
  externalPatientId: string
  showLogoutButton: boolean
}

const DoctorPendingOrderWorkflowHeader = ({
  firstName,
  lastName,
  externalPatientId,
  showLogoutButton,
}: Props) => {
  return (
    <div className="navbar-fixed-top fixed-top">
      <nav className="navbar navbar-light bg-light">
        <div>
          <div className="bold">
            Order for {firstName} {lastName}
          </div>
          <div className="color-dark-gray font-m">MRN: {externalPatientId}</div>
        </div>
        <div>
          {showLogoutButton && (
            <span style={{ verticalAlign: "top", marginRight: "0.5rem" }}>
              <SignOutButton />
            </span>
          )}
          <Logo width="36" className="canopy-mb-4x"></Logo>
        </div>
      </nav>
    </div>
  )
}

export default DoctorPendingOrderWorkflowHeader
