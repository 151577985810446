import React, { useRef } from "react"
import { Box, Grid } from "@material-ui/core"
import { CanopyTextInputField } from "@parachutehealth/canopy-text-input-field"
import { CanopyButton } from "@parachutehealth/canopy-button"

interface SearchBarProps {
  onSearch: (search: string) => void
  label?: string
  hiddenLabel?: boolean
  required?: boolean
  placeholder?: string
  searchButtonVariant?: "primary" | "secondary"
}

export const SearchBar = ({
  label = "search bar",
  hiddenLabel = false,
  placeholder,
  required = false,
  onSearch,
  searchButtonVariant = "primary",
}: SearchBarProps): JSX.Element => {
  const search = useRef("")

  return (
    <Box mb={3} className="canopy-mbe-8x">
      <Grid container spacing={2} alignItems="flex-end">
        <Grid item className="canopy-pie-0">
          <CanopyTextInputField
            label={label}
            hiddenLabel={hiddenLabel}
            placeholder={placeholder}
            type="search"
            required={required}
            data-testid="search-bar"
            onChange={(e) => {
              search.current = e.target.value
            }}
            onKeyDown={(e) => {
              if (e.key === "Enter") {
                void onSearch(search.current)
              }
            }}
          />
        </Grid>
        <Grid item>
          <CanopyButton
            variant={searchButtonVariant}
            onClick={() => void onSearch(search.current)}
          >
            Search
          </CanopyButton>
        </Grid>
      </Grid>
    </Box>
  )
}
