// @team @cat-crew

export const productsUrl = (): string => {
  return "/cms/catalog/products/"
}

export const productDetailUrl = (id: string): string => {
  return `/cms/catalog/product/${id}`
}

export const editProductUrl = (id: string): string => {
  return `${productDetailUrl(id)}/edit`
}
