import React, { useContext, useState } from "react"
import { DataGridPro, GridColDef, GridSortModel } from "@mui/x-data-grid-pro"
import { isTest } from "utilities/environment"
import { Box, Grid } from "@material-ui/core"
import SearchField from "applications/Cms/components/SearchField"
import useServerSideDataGrid, {
  ServerSideDataGridOptions,
  ServerSideDataGridParams,
} from "../../../../../../hooks/useServerSideDataGrid"
import {
  CatalogAttributesParams,
  CatalogAttributesResponse,
  getCatalogAttributes,
} from "applications/Cms/api/attributes"
import AddAttributeFormModal from "../../components/AddAttributeFormModal"
import { Link, RouterChildContext, useHistory } from "react-router-dom"
import { attributeDetailUrl } from "../../../../../../urls/attributes"
import ActionsFormGroup from "../../../../../../components/ActionsFormGroup"
import { NoticeContext } from "../../../../../../contexts/NoticeContext"
import { CatalogCustomAttribute } from "../../../../../../types/sharedTypes"
import { useFeatureFlags } from "../../../../../../../../components/FeatureFlagContext"
import { CanopyButton } from "@parachutehealth/canopy-button"
import { attributesCreateFormPageUrl } from "../../../../../../urls/attributes"

/**
 * @deprecated Passing these in through properties in deprecated
 * and will be replaced by access via query params
 */
export type AttributesPageProps = {
  filters?: {
    searchKeywords?: CatalogAttributesParams["searchKeywords"]
  }
  pageSize?: number
  page?: CatalogAttributesParams["page"]
  sort?: GridSortModel
}

type CatalogCustomAttributeOptions = ServerSideDataGridOptions & {
  searchKeywords?: string
}

const columns: GridColDef[] = [
  {
    field: "name",
    flex: 1,
    headerName: "Name",
    valueGetter: (params) => params.row.name,
    renderCell: (params) => (
      <Link to={attributeDetailUrl(params.row.externalId)}>{params.value}</Link>
    ),
  },
  {
    field: "selectionCaption",
    flex: 1,
    headerName: "Caption",
  },
  {
    field: "productCount",
    flex: 1,
    headerName: "# of Products",
    minWidth: 100,
    type: "number",
    headerAlign: "left",
  },
]

/**
 * @todo Rewrite this component to pull params from query params since props are no longer passed in
 */
const AttributesDataGrid: React.FC<AttributesPageProps> = (
  props: AttributesPageProps
) => {
  const { page, pageSize = 50, filters } = props

  const history: RouterChildContext["router"]["history"] = useHistory()
  const { showNotice } = useContext(NoticeContext)
  const { isFeatureEnabled } = useFeatureFlags()

  const beforeFetch = () => {
    setLoading(true)
  }
  const afterFetch = () => {
    setLoading(false)
  }

  const [customAttributesData, setCustomAttributesData] = useState<
    CatalogAttributesResponse
  >({
    attributes: [],
    totalCount: 0,
  })

  const defaultOptions: CatalogCustomAttributeOptions = {
    page: page || 1,
    searchKeywords: filters?.searchKeywords,
    sort: [{ field: "name", sort: "asc" }],
  }

  const [autoFocus, setAutoFocus] = useState<string | null>(null)
  const [loading, setLoading] = useState<boolean>(false)

  const fetchFunction = async (
    params: ServerSideDataGridParams
  ): Promise<void> => {
    const data = await getCatalogAttributes(params)

    setCustomAttributesData((prev) => ({
      ...prev,
      attributes: data.attributes,
      totalCount: data.totalCount,
    }))
  }

  const {
    options,
    handleFilterChange,
    handlePageChange,
    handleFilterModelChange,
    handleSortModelChange,
  } = useServerSideDataGrid<CatalogCustomAttributeOptions>({
    defaultOptions,
    columnDefinitions: columns,
    fetchFunction,
    beforeFetch,
    afterFetch,
  })

  const onAddAttributeSuccess = (attribute: CatalogCustomAttribute): void => {
    history.push(attributeDetailUrl(attribute.externalId))
    showNotice(
      `Successfully created the new attribute ${attribute.name}`,
      "success"
    )
  }

  const isAttrEnhancementFlagOn = isFeatureEnabled(
    "cmsAttributeEnhancementsRefresh"
  )

  const density = isAttrEnhancementFlagOn ? "standard" : "compact"

  return (
    <>
      <Box mb={2}>
        <Grid container spacing={2}>
          <Grid xs={12} sm={3} lg={3} item>
            <SearchField
              onSearch={(searchText) => {
                setAutoFocus("searchKeywords")
                handleFilterChange("searchKeywords", searchText)
              }}
              dirtyTooltip
              disabled={loading}
              data-testid="search-keywords-filter"
              label="Search"
              autoFocus={autoFocus === "searchKeywords"}
              defaultValue={options?.searchKeywords}
            />
          </Grid>
          <Grid xs={12} sm={9} lg={9} item>
            <Box display="flex" justifyContent="flex-end">
              {isAttrEnhancementFlagOn ? (
                <CanopyButton
                  variant="primary"
                  size="small"
                  as="a"
                  href={attributesCreateFormPageUrl()}
                >
                  Add Attribute
                </CanopyButton>
              ) : (
                <ActionsFormGroup label="Add Attribute">
                  <AddAttributeFormModal onSuccess={onAddAttributeSuccess} />
                </ActionsFormGroup>
              )}
            </Box>
          </Grid>
        </Grid>
      </Box>
      <Box mt={2}>
        <DataGridPro
          className="borderless"
          columns={columns}
          autoHeight
          disableVirtualization={isTest()} // Needs to be true for tests to work but ideally false in production, esp. for higher row counts
          rows={customAttributesData.attributes}
          rowCount={customAttributesData.totalCount}
          onFilterModelChange={handleFilterModelChange}
          density={density}
          loading={loading}
          page={options.page - 1} // account for DataGrid's zero-based indexing
          onPageChange={(page) => {
            handlePageChange(page + 1)
          }} // account for DataGrid's zero-based indexing
          getRowId={(row) => row.externalId}
          sortingMode="server"
          sortModel={options.sort}
          onSortModelChange={handleSortModelChange}
          pagination={true}
          paginationMode="server"
          pageSize={pageSize}
          rowsPerPageOptions={[pageSize]}
        />
      </Box>
    </>
  )
}

export default AttributesDataGrid
