import { camelizeKeys } from "humps"
import { meta } from "utilities/meta"
import { ApiResponse } from "./utilities"
import { post } from "../../../services/api"

export const rearrangeProductCustomAttributes = async (
  ids: number[]
): Promise<boolean> => {
  return await post("/cms/catalog/product_custom_attributes/rearrange", {
    id: ids,
  })
    .then(() => true)
    .catch(() => false)
}

export const createProductCustomAttribute = async (
  productId: number,
  customAttribute?: { id?: number; text: string },
  defaultAttributeValue?: { text: string }
): Promise<ApiResponse<boolean>> => {
  return await post("/cms/catalog/product_custom_attributes.json", {
    catalogProductCustomAttribute: {
      customAttributeId: customAttribute?.id || customAttribute?.text,
      catalogProductId: productId,
      defaultCustomAttributeOption: defaultAttributeValue?.text,
    },
  })
    .then(() => true)
    .catch((error) => error.response.data)
}

export const destroyProductCustomAttribute = async (
  id: number
): Promise<boolean> => {
  const response = await fetch(
    `/cms/catalog/product_custom_attributes/${id}.json`,
    {
      method: "DELETE",
      headers: {
        "X-CSRF-Token": meta("csrf-token"),
      },
    }
  )

  // reading the empty response prevents chrome from sometimes spamming the console with a false-positive error
  await response.text()

  return response.ok
}

export type CustomAttributeOptionsResponse = {
  results: { id: number; text: string }[]
}

/**
 * @deprecated Prefer the version in attributeOptions.ts
 */
export const getCustomAttributeOptions = async (
  customAttributeId: number
): Promise<CustomAttributeOptionsResponse> => {
  const response = await fetch(
    `/cms/catalog/attributes/${customAttributeId}/custom_attribute_options.json?page_size=9999`
  )
  const jsonData = camelizeKeys(await response.json())
  return jsonData
}
