import { post } from "services/api"
import { pendingOrdersTherapySelectionsUrl } from "./urls"
import { SurveyQuestion } from "../../sharedTypes"

export interface TherapySelectionsResponse {
  data: {
    questions: SurveyQuestion[]
  }
}

export const saveTherapy = (
  pendingOrderId: string,
  therapyId: string
): Promise<TherapySelectionsResponse> => {
  return post(pendingOrdersTherapySelectionsUrl(pendingOrderId), {
    therapySelection: { therapyId },
  })
    .then((response) => response)
    .catch(({ response }) => Promise.reject(response.data))
}
