import React from "react"
import { ClinicalFacilityProfile } from ".."
import { CanopyTextInputField } from "@parachutehealth/canopy-text-input-field"
import {
  CanopyPickerField,
  OptionItem,
} from "@parachutehealth/canopy-picker-field"
import { CanopyButton } from "@parachutehealth/canopy-button"
import { CanopyForm } from "@parachutehealth/canopy-form"
import * as styles from "../index.module.scss"
import { states } from "utilities/address"
import { useFormikParachute } from "applications/shared/hooks/useFormikParachute/useFormikParachute"
import { updateFacilityAddress } from "../api"

type Props = {
  clinicalFacility: ClinicalFacilityProfile
  onUpdate: () => Promise<void>
  next: () => void
  back: () => void
}

interface AddressFormValues {
  line1: string
  line2: string
  city: string
  state: string
  zip: string
}

const stateOptions: OptionItem[] = states.map((state) => ({
  label: state[0],
  value: state[1],
}))

const ClinicalFacilityProfileAddress = (props: Props) => {
  const { clinicalFacility, onUpdate, next, back } = props

  const onSubmit = async (values: AddressFormValues) => {
    try {
      const response = await updateFacilityAddress(values)
      if (response.success) {
        await onUpdate()
        next()
      } else {
        formik.setErrors(response.errors)
      }
    } catch (error) {
      console.error("Error updating facility address:", error)
    }
  }

  const formik = useFormikParachute<AddressFormValues>({
    initialValues: {
      line1: clinicalFacility.line1,
      line2: clinicalFacility.line2,
      city: clinicalFacility.city,
      state: clinicalFacility.state,
      zip: clinicalFacility.zip,
    },
    onSubmit: (values) => {
      return onSubmit(values)
    },
  })

  return (
    <div className={styles.profileWorkflowStepContainer}>
      <div className={styles.formContainer} data-testid="facility-address-form">
        <h1 className={styles.header}>
          Is this the correct address for {clinicalFacility.name}?
        </h1>
        <p>
          Suppliers rely on this address to send your patients what they need.
          Take a moment to confirm everything is correct.
        </p>
        <CanopyForm onSubmit={formik.handleSubmit}>
          <div className={styles.formField}>
            <CanopyTextInputField
              label="Address"
              name="line1"
              value={formik.values.line1}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              feedbackMessage={formik.errors.line1}
            />
          </div>
          <div className={styles.formField}>
            <CanopyTextInputField
              label="Address line 2"
              name="line2"
              value={formik.values.line2}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              feedbackMessage={formik.errors.line2}
              optional
            />
          </div>
          <div className={styles.formField}>
            <CanopyTextInputField
              label="City"
              name="city"
              value={formik.values.city}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              feedbackMessage={formik.errors.city}
            />
          </div>
          <div className={styles.inlineFields}>
            <CanopyPickerField
              label="State"
              options={stateOptions}
              style={{ flex: 1 }}
              value={formik.values.state}
              onChange={(newValue) =>
                formik.setFieldValue("state", (newValue as OptionItem).value)
              }
              onBlur={formik.handleBlur}
              feedbackMessage={formik.errors.state}
            />
            <CanopyTextInputField
              label="Zip"
              name="zip"
              style={{ flex: 1 }}
              value={formik.values.zip}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              feedbackMessage={formik.errors.zip}
            />
          </div>
          <div className={styles.buttonContainer}>
            <CanopyButton variant="secondary" onClick={back} type="button">
              Back
            </CanopyButton>
            <CanopyButton type="submit" loading={formik.isSubmitting}>
              Continue
            </CanopyButton>
          </div>
        </CanopyForm>
      </div>
    </div>
  )
}

export default ClinicalFacilityProfileAddress
