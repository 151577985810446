import React from "react"
import * as styles from "./index.module.scss"
import { format } from "utilities/date"
import { capitalize } from "utilities/string"
import { usePatientQuery } from "../../../queries"

const PatientData = () => {
  const { data: patient } = usePatientQuery()
  if (!patient) return null
  const { firstName, lastName, sex, dateOfBirth } = patient
  return (
    <div>
      <div
        className={`canopy-typography-heading-medium ${styles.canopyColorTextSecondary}`}
      >
        Initial order for
      </div>
      <span className="canopy-typography-heading-large canopy-mie-8x">
        {firstName} {lastName}
      </span>
      <span className={styles.canopyColorTextSecondary}>
        {capitalize(sex)} &#x2022; {!!dateOfBirth && format(dateOfBirth)}
      </span>
    </div>
  )
}

export default PatientData
