import React from "react"
import { convertFollowerToOption } from "./utilities/followers"
import { initials } from "utilities/person"
import Select, { GroupedOptions } from "components/form/Select"
import Suggestion from "components/form/Select/Suggestion"
import { Follower, FollowerEmployerType } from "sharedTypes"
import ReadOnlyFollowers from "./ReadOnlyFollowers"

type Props = {
  dmeOrder: { followers: Follower[] }
  editable: boolean
  getFollowers(term: string): Promise<GroupedOptions[]>
}

const InternalFollowers = ({ dmeOrder, editable, getFollowers }: Props) => {
  const currentUserEmployerType = window.parachute?.employerType
  const isFacility = currentUserEmployerType === "ClinicalFacility"
  const allFollowers = dmeOrder.followers
  const supplierFollowers = allFollowers.filter(
    (follower) => follower.employerType === FollowerEmployerType.Supplier
  )

  const isAvailableToRemove = (record) => {
    return !(
      record.employerType === FollowerEmployerType.Supplier && isFacility
    )
  }
  const followers = dmeOrder.followers.filter(isAvailableToRemove)
  const initialOptions = followers.map(convertFollowerToOption)

  if (!editable) {
    if (allFollowers.length === 0) {
      return <div>This order does not have any followers yet</div>
    }
    return (
      <ul className="list-unstyled">
        {allFollowers.map((follower) => {
          return (
            <li
              className="canopy-mbe-8x"
              key={`${follower.name}-${follower.id}`}
            >
              <div className="gray-o square d-inline-block">
                {initials(follower.name)}
              </div>
              <strong className="canopy-mis-8x font-primary font-xs d-inline-block">
                {follower.name}
              </strong>
            </li>
          )
        })}
      </ul>
    )
  }

  return (
    <div className="form-group">
      <label className="d-block" htmlFor="followerIds">
        Followers
      </label>
      <Select
        name="followerIds"
        placeholder="Search"
        isSearchable
        closeMenuOnSelect={false}
        isMulti
        minLength={1}
        options={initialOptions}
        fetchOptions={getFollowers}
        menuPosition="fixed"
        renderOption={(option, { isFocused }) => (
          <Suggestion isHighlighted={isFocused}>{option.label}</Suggestion>
        )}
      />
      {isFacility && supplierFollowers.length > 0 && (
        <ReadOnlyFollowers followers={supplierFollowers} />
      )}
    </div>
  )
}

export default InternalFollowers
