import React, { useState, useEffect } from "react"
import { Redirect, Route } from "react-router-dom"
import * as routes from "applications/Workflow/routes"
import {
  History,
  Employer,
  EmployerType,
  DmeOrder,
  DmeOrderWarning,
} from "sharedTypes"
import { handleError } from "utilities/error"
import { navigate, reload } from "utilities/navigation"
import withInitialData from "components/withInitialData"
import InternalReview from "./components/InternalReview"
import * as api from "./api"
import {
  ReviewData,
  Submission,
  UpdateClinicalFacilityAttributes,
  AcceptanceResponse,
  CreateSupplierTransferAttributes,
} from "./sharedTypes"
import { orderedWarningsWithStep } from "./utilities/validation"
import { employerHomeUrl, workflowReviewUrl, workflowsUrl } from "../../urls"
import PatientPreferenceConfiguration from "../PatientPreferenceConfiguration"
import SignatureModal from "./components/SignatureModal"
import SupplierSendBack from "./components/SupplierSendBack"
import SupplierTransferForm from "./components/SupplierTransfer"
import SupplierLegalEntityModal from "./components/SupplierLegalEntityModal"
import OrderLinkageModal from "./components/OrderLinkageModal"
import SupplierSystemClinicalFacilityExternalSystemIdModal from "./components/SupplierSystemClinicalFacilityExternalSystemIdModal"
import PushbackSignUpModal from "./components/PushbackSignUpModal"
import UpdateClinicalFacilityModal from "./components/UpdateClinicalFacilityModal"
import ErrorBoundary from "components/ErrorBoundary"
import { SidebarTab } from "./../Navigation/sharedTypes"
import DeliveryReceipt from "./components/DeliveryReceipt/DeliveryReceipt"
import GenerateInviteLinkModal from "./components/GenerateInviteLinkModal"
import { pollPromise } from "utilities/poll"
import { EventCategory, trackEvent } from "utilities/tracking"
import SupplierSendBackFacilityWarning from "./components/SupplierSendBackFacilityWarning"
import SupplierRejectForm from "./components/SupplierSendBack/SupplierRejectForm"

type Props = {
  history: History
  location: any
  dmeOrder: DmeOrder
  initialData: ReviewData
  refreshDmeOrder(): Promise<void>
  setDmeOrder(dmeOrder: DmeOrder): Promise<void>
  currentEmployer: Employer
  requiresTwoStepDownload: boolean
  warnings: DmeOrderWarning[]
  dmeOrderSpecificUser: boolean
  emrSession: boolean
  isOptumFlow: boolean
}

const Review = (props: Props) => {
  const {
    history,
    location,
    dmeOrder,
    initialData,
    refreshDmeOrder,
    setDmeOrder,
    currentEmployer,
    requiresTwoStepDownload,
    warnings,
    dmeOrderSpecificUser,
    emrSession,
    isOptumFlow,
  } = props

  const [submission, setSubmission] = useState<Submission>(
    initialData.submission
  )
  const [reviewData, setReviewData] = useState<ReviewData>(initialData)

  const {
    permissions,
    signatureRequests,
    downloadableDocuments,
    deliveryTicketSignedAt,
    selfSign,
    rxDetailsOutputs,
    lineItemGroups,
    extraAttestationRequested,
    userEmail,
    externalQuestionnairesCompleted,
  } = reviewData

  useEffect(() => {
    const { permissions, submission } = initialData
    if (permissions.submit && submission.status === "pending") {
      pollForSubmissionStatus()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [initialData])

  // @ts-ignore
  useEffect(() => refreshReviewData, [dmeOrder])

  const isSupplier = currentEmployer.employerType === EmployerType.Supplier

  const submit = () => {
    if (isSupplier) {
      return api
        .createAcceptance()
        .then((response) => {
          navigate(response.data.redirectUrl)
        })
        .catch((err) =>
          handleSubmissionError(err, err.response && err.response.data)
        )
    }

    emrSession &&
      trackEvent(
        EventCategory.FHIRIntegration,
        "emr-session-submit-order",
        currentEmployer.employerId
      )

    return api
      .createSubmission()
      .then((response) => {
        setSubmission(response.data)
        return pollForSubmissionStatus()
      })
      .catch((err) =>
        handleSubmissionError(err, err.response && err.response.data)
      )
  }

  const handleSubmissionError = (err, submissionData) => {
    if (submissionData && submissionData.status) {
      setSubmission(submissionData)
      return
    }
    handleError(err)
  }

  const pollForSubmissionStatus = () => {
    if (isSupplier) {
      return pollPromise(api.getAcceptanceStatus, {
        delay: 1000,
        maxRetries: 600,
      }).then(({ redirectUrl }: AcceptanceResponse) => {
        navigate(redirectUrl)
      })
    }

    return pollPromise(api.getSubmissionStatus, {
      delay: 200,
      maxRetries: 600,
    })
      .then((response: Submission) => {
        if (dmeOrderSpecificUser) {
          navigate(`${workflowsUrl()}${routes.reviewUserEmailPath()}`)
        } else if (response.splitOrder) {
          navigate(employerHomeUrl())
        } else {
          navigate(
            `${workflowsUrl()}?sidebar_tab=${
              SidebarTab.Activity
            }&just_submitted=true`
          )
        }
      })
      .catch((err) => handleSubmissionError(err, err))
  }

  const pullBack = () => {
    return api
      .pullBack()
      .then(() => reload())
      .catch(handleError)
  }

  const archive = () => {
    return api
      .archive()
      .then((response) => {
        navigate(response.data.redirectUrl)
      })
      .catch(handleError)
  }

  const createSignature = () => {
    return api
      .createSignature()
      .then(() => {
        navigate(workflowReviewUrl())
      })
      .catch(handleError)
  }

  const sendSignatureRequest = (params = {}): Promise<void> => {
    return api
      .sendSignatureRequest(params)
      .then(() => {
        navigate(workflowReviewUrl())
      })
      .catch((error) => {
        return handleError(error, error.response?.data?.error)
      })
  }

  const supplierEdit = () => {
    return api
      .supplierEdit()
      .then(() => {
        reload()
      })
      .catch(handleError)
  }

  const supplierUnaccept = () => {
    return api
      .supplierUnaccept()
      .then(() => {
        reload()
      })
      .catch(handleError)
  }

  const updateFulfillment = (id, values) => {
    return api.updateFulfillment(id, values).then((dmeOrder) => {
      setDmeOrder(dmeOrder)
    })
  }

  const refreshReviewData = () =>
    api.getReviewData().then(({ data }) => setReviewData(data))

  const refreshDmeOrderAndReviewData = () => {
    return Promise.all([refreshDmeOrder(), refreshReviewData()])
      .then(() => Promise.resolve())
      .catch((e) => handleError(e))
  }

  const updateSupplierLegalEntity = (values: {
    [supplierLegalEntityId: string]: string
  }) => {
    return api
      .updateSupplierLegalEntity(values)
      .then(refreshDmeOrderAndReviewData)
      .catch(handleError)
  }

  const updateSupplierFulfillment = (fulfillmentId, supplierSystemId) => {
    return api
      .updateSupplierFulfillment(fulfillmentId, supplierSystemId)
      .then(refreshDmeOrderAndReviewData)
      .catch(handleError)
  }

  const updateSupplierSystemClinicalFacilityId = (externalSystemId) => {
    return api
      .updateSupplierSystemClinicalFacilityId(
        dmeOrder.clinicalFacility.externalId,
        externalSystemId
      )
      .then(refreshDmeOrderAndReviewData)
      .catch(handleError)
  }

  const updateClinicalFacility = (
    attributes: UpdateClinicalFacilityAttributes
  ) => {
    return api
      .updateClinicalFacility(attributes)
      .then(refreshDmeOrderAndReviewData)
      .catch(handleError)
  }

  const createSupplierTransfer = async (
    attributes: CreateSupplierTransferAttributes
  ) => {
    return api
      .createSupplierTransfer(attributes)
      .then(refreshDmeOrderAndReviewData)
      .catch(handleError)
  }

  return (
    <ErrorBoundary>
      <Route
        path={routes.reviewPath.matcher}
        render={() => (
          <InternalReview
            warnings={orderedWarningsWithStep({ currentEmployer }, warnings)}
            trackConversionEvents={initialData.trackConversionEvents}
            currentEmployer={currentEmployer}
            requiresTwoStepDownload={requiresTwoStepDownload}
            downloadableDocuments={downloadableDocuments}
            dmeOrder={dmeOrder}
            permissions={permissions}
            signatureRequests={signatureRequests}
            pullBack={pullBack}
            sendSignatureRequest={sendSignatureRequest}
            setUserEmail={() => history.push(routes.reviewUserEmailPath())}
            updateClinicalFacility={() =>
              history.push(routes.reviewUpdateFacilityPath())
            }
            supplierEdit={supplierEdit}
            supplierUnaccept={supplierUnaccept}
            submission={submission}
            submit={submit}
            deliveryTicketSignedAt={deliveryTicketSignedAt}
            selfSign={selfSign}
            updateFulfillment={updateFulfillment}
            rxDetailsOutputs={rxDetailsOutputs}
            lineItemGroups={lineItemGroups}
            extraAttestationRequested={extraAttestationRequested}
            signProofOfDelivery={() => {
              history.push(routes.reviewProofOfDeliveryPath())
            }}
            userEmail={userEmail}
            dmeOrderSpecificUser={dmeOrderSpecificUser}
            archive={archive}
            externalQuestionnairesCompleted={externalQuestionnairesCompleted}
            questionnaireResponses={dmeOrder.questionnaireResponses || []}
            isOptumFlow={isOptumFlow}
          />
        )}
      />
      <Route
        path={routes.reviewSignaturePath.matcher}
        render={() => {
          const dismiss = () => {
            history.push(routes.reviewPath())
          }
          return (
            <SignatureModal
              dmeOrder={dmeOrder}
              dismiss={dismiss}
              refreshDmeOrder={refreshDmeOrderAndReviewData}
              createSignature={createSignature}
              sendSignatureRequest={(sendImmediately: boolean) =>
                sendSignatureRequest({ sendImmediately })
              }
              extraAttestationRequested={extraAttestationRequested}
            />
          )
        }}
      />
      <Route
        path={routes.reviewGenerateInvitePath.matcher}
        render={() => {
          const dismiss = () => {
            history.push(routes.reviewPath())
          }
          return <GenerateInviteLinkModal closeModal={dismiss} />
        }}
      />
      <Route
        path={routes.reviewSendBackFacilityWarningPath.matcher}
        render={() => {
          const navigateToUpdateFacilityPage = () => {
            history.push({
              pathname: routes.reviewUpdateFacilityPath(),
              search: "?redirectToSupplierSendBack=true",
            })
          }
          const navigateToSupplierSendBackPage = () => {
            history.push(routes.reviewSendBackPath())
          }

          return (
            <SupplierSendBackFacilityWarning
              navigateToSupplierSendBackPage={navigateToSupplierSendBackPage}
              navigateToUpdateFacilityPage={navigateToUpdateFacilityPage}
              onClose={() => history.push(routes.reviewPath())}
            />
          )
        }}
      />
      <Route
        path={routes.reviewRejectPath.matcher}
        render={() => {
          const dismiss = () => {
            history.push(routes.reviewPath())
          }
          return <SupplierRejectForm cancel={dismiss} />
        }}
      />
      <Route
        path={routes.reviewSendBackPath.matcher}
        render={() => {
          const dismiss = () => {
            history.push(routes.reviewPath())
          }
          return (
            <SupplierSendBack
              dismiss={dismiss}
              doctorId={dmeOrder.doctor?.doctorId}
              supplierId={dmeOrder.supplier?.externalId}
            />
          )
        }}
      />
      <Route
        path={routes.supplierTransferPath.matcher}
        render={() => {
          if (!permissions.showSupplierTransfer) {
            return <Redirect to={routes.reviewPath()} />
          }
          const dismiss = () => {
            history.push(routes.reviewPath())
          }
          const onClick = (
            attributes: CreateSupplierTransferAttributes
          ): Promise<void> => createSupplierTransfer(attributes).then(dismiss)

          return (
            <SupplierTransferForm
              dismiss={dismiss}
              onClick={onClick}
              dmeOrderId={dmeOrder.id}
            />
          )
        }}
      />
      <Route
        path={routes.reviewUserEmailPath.matcher}
        render={() => {
          if (!permissions.setUserEmail) {
            return <Redirect to={routes.reviewPath()} />
          }
          const dismiss = () => {
            void refreshDmeOrderAndReviewData()
            history.push(routes.reviewPath())
          }

          return (
            <PushbackSignUpModal source="OrderSubmitted" dismiss={dismiss} />
          )
        }}
      />
      <Route
        path={routes.patientPreferenceConfigurationPath.matcher}
        render={({ match }) => {
          const lineItemGroupId = match.params.lineItemGroupId
          const dismiss = () => {
            history.push(routes.reviewPath())
            reload()
          }

          return (
            <PatientPreferenceConfiguration
              lineItemGroupId={lineItemGroupId}
              dismiss={dismiss}
            />
          )
        }}
      />
      <Route
        path={routes.supplierLegalEntityModalPath.matcher}
        render={() => {
          const dismiss = () => {
            history.push(routes.reviewPath())
          }
          return (
            <SupplierLegalEntityModal
              updateSupplierLegalEntity={updateSupplierLegalEntity}
              close={dismiss}
              supplierLegalEntityId={dmeOrder.supplierLegalEntity.id}
            />
          )
        }}
      />
      <Route
        path={routes.reviewUpdateFacilityPath.matcher}
        render={() => {
          const successDismiss = () => {
            const redirectToSupplierSendBack = new URLSearchParams(
              location.search
            ).get("redirectToSupplierSendBack")
            if (redirectToSupplierSendBack) {
              history.push(routes.reviewSendBackPath())
            } else {
              history.push(routes.reviewPath())
            }
          }

          const closeDismiss = () => {
            history.push(routes.reviewPath())
          }
          const updateClinicalFacilityAndCloseModal = (
            attributes: UpdateClinicalFacilityAttributes
          ): Promise<void> =>
            updateClinicalFacility(attributes).then(successDismiss)

          return (
            <UpdateClinicalFacilityModal
              savedFacilityGid={dmeOrder.clinicalFacility.gid}
              closeModal={closeDismiss}
              updateClinicalFacility={updateClinicalFacilityAndCloseModal}
              defaultDoctor={dmeOrder.doctor}
            />
          )
        }}
      />
      <Route
        path={routes.orderLinkageModalPath.matcher}
        render={({ match }) => {
          const dismiss = () => {
            history.push(routes.reviewPath())
          }
          const fulfillmentId = match.params.fulfillmentId
          const supplierSystemId = dmeOrder.fulfillments.find(
            (fulfillment) => fulfillment.id === fulfillmentId
          )?.supplierFulfillment?.supplierSystemId
          return (
            <OrderLinkageModal
              onClose={dismiss}
              onSubmit={(supplierSystemId) =>
                updateSupplierFulfillment(fulfillmentId, supplierSystemId)
              }
              supplierSystemId={supplierSystemId}
            />
          )
        }}
      />
      <Route
        path={
          routes.supplierSystemClinicalFacilityExternalSystemIdModalPath.matcher
        }
        render={() => {
          const dismiss = () => {
            history.push(routes.reviewPath())
          }
          return (
            <SupplierSystemClinicalFacilityExternalSystemIdModal
              onClose={dismiss}
              onSubmit={(externalSystemId) =>
                updateSupplierSystemClinicalFacilityId(externalSystemId)
              }
            />
          )
        }}
      />
      <Route
        path={routes.reviewProofOfDeliveryPath.matcher}
        render={() => {
          const dismiss = () => history.push(routes.reviewPath())
          let errors = {}

          const signDeliveryTicket = (values) => {
            return api
              .createSignedDeliveryTicket(values)
              .then(() => {
                navigate(workflowReviewUrl())
              })
              .catch(({ response }) => {
                errors = response.data.errors
              })
          }
          return (
            <DeliveryReceipt
              dmeOrder={dmeOrder}
              errors={errors}
              onClose={dismiss}
              onSubmit={signDeliveryTicket}
            />
          )
        }}
      />
    </ErrorBoundary>
  )
}

const fetchInitialData = () => api.getReviewData().then(({ data }) => data)
export default withInitialData(fetchInitialData)(Review)
