import React from "react"
import InternalFollowers from "./components/InternalFollowers"
import SpinIcon from "components/Icon/SpinIcon"
import { getFollowers } from "./api"
import { convertToGroupOption } from "./components/utilities/followers"
import { GroupedOptions } from "components/form/Select"
import { Follower } from "sharedTypes"

type Props = {
  dmeOrder: { externalId: string; followers: Follower[] }
  editable: boolean
  loading: boolean
}

const Followers: React.FC<Props> = ({ dmeOrder, editable, loading }) => {
  const searchFollowers = (term: string): Promise<GroupedOptions[]> => {
    return getFollowers(term, dmeOrder.externalId).then((response) =>
      response.map(convertToGroupOption)
    )
  }

  if (loading) {
    return (
      <div className="text-center">
        <SpinIcon />
      </div>
    )
  }

  return (
    <InternalFollowers
      editable={editable}
      dmeOrder={dmeOrder}
      getFollowers={searchFollowers}
    />
  )
}

export default Followers
