import { post, get } from "services/api"
import {
  updateFacilityNameUrl,
  updateFacilityAddressUrl,
  getFacilityProfileUrl,
} from "./urls"

export function updateFacilityName(name: string): Promise<any> {
  return post(updateFacilityNameUrl(), { name }).then(({ data }) => data)
}

export function updateFacilityAddress(address: {
  line1: string
  city: string
  state: string
  zip: string
}): Promise<any> {
  return post(updateFacilityAddressUrl(), { address }).then(({ data }) => data)
}

export function getFacilityProfile(): Promise<any> {
  return get(getFacilityProfileUrl()).then(({ data }) => data)
}
