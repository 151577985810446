import React, { PropsWithChildren } from "react"
import { Drawer } from "@material-ui/core"
import * as styles from "./EditDrawer.module.scss"
import { CanopyButton } from "@parachutehealth/canopy-button"
import classNames from "classnames"

interface EditDrawerProps {
  anchor?: "right" | "left" | "top" | "bottom" | undefined
  open: boolean
  heading: string
  onClose: () => void
}

export const EditDrawer = ({
  open,
  anchor = "right",
  onClose,
  heading,
  children,
}: PropsWithChildren<EditDrawerProps>): React.JSX.Element => {
  return (
    <Drawer
      variant="persistent"
      anchor={anchor}
      open={open}
      PaperProps={{
        className: classNames("shadow", styles.lessZIndex, styles.drawerWidth),
      }}
    >
      <div>
        <div className={styles.editDrawerHeader}>
          <h3 className="canopy-typography-heading-xlarge canopy-my-0 canopy-mr-12x">
            {heading}
          </h3>
          <CanopyButton
            iconStart="xmark"
            variant="tertiary"
            accessibleText="close edit drawer"
            title="closer edit drawer"
            onClick={onClose}
          />
        </div>
        <div className={styles.editDrawerContainer}>{children}</div>
      </div>
    </Drawer>
  )
}
