import React, { useContext } from "react"
import { attributesUrl } from "../../../../../urls/attributes"
import Breadcrumbs from "../../../../../components/Breadcrumbs"
import AttributeFormFields from "../AttributeFormFields"
import { createCatalogAttribute } from "applications/Cms/api/attributes"
import { NoticeContext } from "../../../../../contexts/NoticeContext"
import { useHistory } from "react-router-dom"

const CreateAttributeFormPage: React.FC = () => {
  const { showNotice } = useContext(NoticeContext)
  const history = useHistory()
  const onSave = async (values, setErrors) => {
    await createCatalogAttribute(values).then((response) => {
      if (response.errors) {
        setErrors(response.errors)
      } else {
        history.push(attributesUrl())
        showNotice(
          `${response.attribute.name} has been added as an Attribute`,
          "success",
          [],
          true
        )
      }
    })
  }

  return (
    <>
      <Breadcrumbs>
        {[
          {
            label: "All Attributes",
            to: attributesUrl(),
          },
        ]}
      </Breadcrumbs>
      <h1 className="canopy-typography-heading-2xlarge">Add new Attribute</h1>
      <AttributeFormFields onSave={onSave} />
    </>
  )
}

export default CreateAttributeFormPage
