import { employerPrefix } from "utilities/url"

export const inboxDashboardUrl = (
  supplierOrganizationId: string,
  supplierId: string
): string =>
  `${employerPrefix(
    "SupplierOrganization",
    supplierOrganizationId
  )}/inbox/${supplierId}`

export const inboxDocumentUrl = (
  supplierOrganizationId: string,
  csrInboxStateId: string
) =>
  `${employerPrefix(
    "SupplierOrganization",
    supplierOrganizationId
  )}/inbox_documents/${csrInboxStateId}`

export const updateInternalStatusUrl = (
  supplierOrganizationId: string,
  csrInboxStateId: string
) =>
  `${employerPrefix(
    "SupplierOrganization",
    supplierOrganizationId
  )}/inbox_documents/${csrInboxStateId}/internal_status`

export const fetchExternalSystemBranchesUrl = (supplierId: string) =>
  `${employerPrefix("Supplier", supplierId)}/external_system_branches.json`

export const updatePatientAddressUrl = (
  supplierOrganizationId: string,
  csrInboxStateId: string
) =>
  `${employerPrefix(
    "SupplierOrganization",
    supplierOrganizationId
  )}/inbox_documents/${csrInboxStateId}/patient_address`

export const updatePatientEmergencyContactUrl = (
  supplierOrganizationId: string,
  csrInboxStateId: string
) =>
  `${employerPrefix(
    "SupplierOrganization",
    supplierOrganizationId
  )}/inbox_documents/${csrInboxStateId}/patient_emergency_contact`

export const updatePatientUrl = (
  supplierOrganizationId: string,
  csrInboxStateId: string
) =>
  `${employerPrefix(
    "SupplierOrganization",
    supplierOrganizationId
  )}/inbox_documents/${csrInboxStateId}/patient`

export const updatePatientContactInformationUrl = (
  supplierOrganizationId: string,
  csrInboxStateId: string
) =>
  `${employerPrefix(
    "SupplierOrganization",
    supplierOrganizationId
  )}/inbox_documents/${csrInboxStateId}/patient_contact_information`

export const updateDiagnosesUrl = (
  supplierOrganizationId: string,
  csrInboxStateId: string
) =>
  `${employerPrefix(
    "SupplierOrganization",
    supplierOrganizationId
  )}/inbox_documents/${csrInboxStateId}/diagnoses`

export const searchDiagnosesUrl = (
  supplierOrganizationId: string,
  csrInboxStateId: string
) =>
  `${employerPrefix(
    "SupplierOrganization",
    supplierOrganizationId
  )}/inbox_documents/${csrInboxStateId}/diagnoses/search`

export const createOrderUrl = (
  supplierOrganizationId: string,
  csrInboxStateId: string
) =>
  `${employerPrefix(
    "SupplierOrganization",
    supplierOrganizationId
  )}/inbox_documents/${csrInboxStateId}/order`

export const orderUrl = (supplierId: string, orderId: string) =>
  `${employerPrefix("Supplier", supplierId)}/orders/${orderId}`

export const searchInsuranceProductsUrl = (
  supplierOrganizationId: string,
  csrInboxStateId: string
) =>
  `${employerPrefix(
    "SupplierOrganization",
    supplierOrganizationId
  )}/inbox_documents/${csrInboxStateId}/insurances/insurance_products.json`

export const updateInsuranceUrl = (
  supplierOrganizationId: string,
  csrInboxStateId: string,
  rank: string
) =>
  `${employerPrefix(
    "SupplierOrganization",
    supplierOrganizationId
  )}/inbox_documents/${csrInboxStateId}/insurances/${rank}.json`
