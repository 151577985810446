// @team @destroythefax
import React, { FC } from "react"
import { useFeatureFlags } from "components/FeatureFlagContext"
import { CanopyNotice } from "@parachutehealth/canopy-notice"

interface Props {
  justSubmitted: boolean
}

const IncomingOrderNotificationBanner: FC<Props> = ({ justSubmitted }) => {
  const { getFlagValue } = useFeatureFlags()
  if (!justSubmitted) return null

  const featureFlag = getFlagValue("incomingOrderBannerFacilityReviewPage")
  const validFeatureFlagValues = [
    "signing_clinician",
    "next_incoming_order",
    "incoming_order",
  ]
  if (!validFeatureFlagValues.includes(featureFlag as string)) {
    return null
  }

  const title =
    featureFlag === "signing_clinician"
      ? "Signing Clinician"
      : featureFlag === "next_incoming_order"
      ? "Next Incoming Order"
      : featureFlag === "incoming_order"
      ? "Incoming Orders"
      : "" // this should never happen

  return (
    <CanopyNotice
      className="canopy-mbe-16x"
      closable
      title={title}
      variant="discovery"
    />
  )
}

export default IncomingOrderNotificationBanner
