import React, { useEffect, useState } from "react"
import { actionColumnPadding } from "../../index.module.scss"
import { format } from "utilities/date"
import { capitalize } from "utilities/string"
import { Link } from "react-router-dom"
import { CanopyIcon } from "@parachutehealth/canopy-icon"
import { CanopyButton } from "@parachutehealth/canopy-button"
import { usePatientQuery } from "../../queries"
import { searchOrders, DmeOrder } from "./api"
import { handleError } from "utilities/error"
import { useCsrInboxState } from "../../csrInboxStateContext"
import { ROUTES } from "../../routes"
import { OrderCard } from "./OrderCard"

export const OrderHistory = () => {
  const { data: patient } = usePatientQuery()
  const [dmeOrders, setDmeOrders] = useState<DmeOrder[]>([])
  const { supplierOrganization, supplier } = useCsrInboxState()

  useEffect(() => {
    if (!patient?.masterPatientId) {
      return
    }
    searchOrders({
      supplierOrganizationId: supplierOrganization.id,
      supplierId: supplier.id,
      masterPatientId: patient.masterPatientId,
      supplierSystemPatientIds: [],
    })
      .then((response) => {
        setDmeOrders(response.dmeOrders)
      })
      .catch(handleError)
  }, [patient?.masterPatientId, supplierOrganization.id, supplier.id])

  if (!patient) return null
  return (
    <div className={actionColumnPadding}>
      <Link role="link" to={ROUTES.PATIENT_SEARCH}>
        <CanopyIcon name="arrow-left" />
        Back
      </Link>
      <div>
        <span className="canopy-typography-heading-medium">
          {patient.firstName} {patient.lastName}
        </span>{" "}
        {capitalize(patient.sex)}
      </div>
      <div>DOB: {format(patient.dateOfBirth)}</div>

      <div className="canopy-mt-12x">
        <div className="float-right">
          <CanopyButton
            as={Link}
            to={ROUTES.NEW_ORDER}
            variant="primary"
            iconStart="plus"
            size="small"
          >
            New Order
          </CanopyButton>
        </div>
        <h3 className="canopy-typography-heading-medium canopy-mb-12x">
          Order history ({dmeOrders.length})
        </h3>
        {dmeOrders.map((order) => (
          <OrderCard key={order.id} order={order} />
        ))}
      </div>
    </div>
  )
}
