import React, { useImperativeHandle, forwardRef, useState } from "react"
import { CanopyDialogModal } from "@parachutehealth/canopy-dialog-modal"
import { CanopyButton } from "@parachutehealth/canopy-button"
import CancelOrderWithReasonModal from "./CancelOrderWithReasonModal"
import { useFeatureFlags } from "components/FeatureFlagContext"
import { getEmployerType } from "utilities/url"
import { ApplicationError, Originator } from "sharedTypes"
import { EventCategory, trackEvent } from "utilities/tracking"
import { handleError } from "utilities/error"

interface Props {
  activatorElementRef: React.RefObject<HTMLButtonElement>
  cancelDmeOrder: (
    cancelReason?: string,
    cancelComment?: string
  ) => Promise<void>
  onClose: () => void
  originator: Originator
  removeClickOutsideDropdownMenuListener?(): void
  patientName: string
}

export interface CancelModalRef {
  open: () => void
  cancelWithReason: boolean
}

const CancelModal = forwardRef<CancelModalRef, Props>(
  (
    {
      activatorElementRef,
      cancelDmeOrder,
      onClose,
      originator,
      removeClickOutsideDropdownMenuListener,
      patientName,
    },
    ref
  ) => {
    const [cancelling, setCancelling] = useState(false)
    const [cancelModalOpen, setCancelModalOpen] = useState(false)
    const [cancelWithReasonOpen, setCancelWithReasonOpen] = useState(false)

    useImperativeHandle(ref, () => ({
      open: () => {
        if (cancelWithReason) {
          openCancelWithReasonModal()
        } else {
          setCancelModalOpen(true)
        }
      },
      cancelWithReason,
    }))

    const { isFeatureEnabled } = useFeatureFlags()
    const collectFacilityCancelReasonEnabled = isFeatureEnabled(
      "collectFacilityCancelReason"
    )
    const collectFacilityGeneratedOrderCancelReasonEnabled = isFeatureEnabled(
      "collectFacilityGeneratedOrderCancelReason"
    )

    const employerType = getEmployerType()
    const isFacilityUser =
      employerType === "ClinicalFacility" ||
      employerType === "ClinicalOrganization"
    const sgo = originator === Originator.Csr
    const isFacilityUserCancellingSgo = isFacilityUser && sgo
    const cancelWithReason =
      (collectFacilityCancelReasonEnabled && isFacilityUserCancellingSgo) ||
      (collectFacilityGeneratedOrderCancelReasonEnabled &&
        isFacilityUser &&
        !sgo)

    const handleModalClose = () => {
      cancelWithReasonOpen
        ? closeCancelWithReasonModal()
        : setCancelModalOpen(false)
      onClose()
    }

    const openCancelWithReasonModal = () => {
      removeClickOutsideDropdownMenuListener?.()
      trackEvent(
        EventCategory.FacilityCancellationWithReason,
        "cancel_order_with_reason_modal_opened"
      )
      setCancelWithReasonOpen(true)
    }

    const closeCancelWithReasonModal = () => {
      trackEvent(
        EventCategory.FacilityCancellationWithReason,
        "cancel_order_with_reason_modal_closed"
      )
      setCancelWithReasonOpen(false)
    }

    const handleCancel = async (
      cancelReason?: string,
      cancelComment?: string
    ) => {
      setCancelling(true)
      try {
        await cancelDmeOrder(cancelReason, cancelComment)
        handleModalClose()
      } catch (error) {
        handleError(error as ApplicationError)
      } finally {
        setCancelling(false)
      }
    }

    return (
      <>
        <CanopyDialogModal
          activatorElementRef={activatorElementRef}
          onClose={handleModalClose}
          open={cancelModalOpen}
          header="Cancel Order"
          primaryFooterButton={
            <CanopyButton
              onClick={() => handleCancel()}
              variant="danger"
              loading={cancelling}
            >
              Confirm order cancellation
            </CanopyButton>
          }
          secondaryFooterButton={
            <CanopyButton onClick={handleModalClose} variant="secondary">
              No, take me back
            </CanopyButton>
          }
          size="small"
        >
          Are you sure you want to cancel this order?
        </CanopyDialogModal>
        <CancelOrderWithReasonModal
          activatorElementRef={activatorElementRef}
          cancelDmeOrder={handleCancel}
          cancelling={cancelling}
          onClose={handleModalClose}
          isOpen={cancelWithReasonOpen}
          isFacilityUserCancellingSgo={isFacilityUserCancellingSgo}
          patientName={patientName}
        />
      </>
    )
  }
)

export default CancelModal
