// @team @clinical-intelligence

import React, { useEffect, useState } from "react"
import withInitialData from "components/withInitialData"
import { answerQuestion, startQuestionnaireResponse } from "./api"
import {
  DmeOrder,
  History,
  QuestionnaireResponse,
  SurveyAnswerType,
  SurveyQuestion,
} from "sharedTypes"
import { reportError } from "utilities/error"
import InternalChartNotes from "../ChartNotes/components/InternalChartNotes"
import { ChartNoteHelperType } from "../ChartNotes/sharedTypes"

type Props = {
  history: History
  refreshDmeOrder(): Promise<void>
  setDmeOrder(dmeOrder: DmeOrder): Promise<void>
  initialData: {
    dmeOrder: DmeOrder
    progress: {
      questionnaireResponse: QuestionnaireResponse
      questions: SurveyQuestion[]
      requiresConfirmation: boolean // TODO: will always be false from the backend?
      disqualifiedMessage?: string // TODO: maybe delete this
      qualificationStatus: string
      error?: string
    }
  }
  nextPagePath?: string
  questionnaireResponseId: string
  isOptumFlow: boolean
}

const QuestionnaireResponses: React.FC<Props> = ({
  initialData,
  refreshDmeOrder,
  setDmeOrder,
  nextPagePath,
  isOptumFlow,
}) => {
  const { dmeOrder, progress } = initialData
  useEffect(() => {
    setDmeOrder(dmeOrder)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
  const [questionnaireResponse, setQuestionnaireResponse] = useState<
    QuestionnaireResponse
  >(progress.questionnaireResponse)
  const [questions, setQuestions] = useState(progress.questions)
  const [requiresConfirmation, setRequiresConfirmation] = useState(
    progress.requiresConfirmation
  )
  const [disqualifiedMessage, setDisqualifiedMessage] = useState(
    progress.disqualifiedMessage
  )
  const [qualificationStatus, setQualificationStatus] = useState(
    progress.qualificationStatus
  )
  const [error, setError] = useState(progress.error)

  const updateState = (response) => {
    if (response.data.questionnaireResponse) {
      setQuestionnaireResponse(response.data.questionnaireResponse)
      setQuestions(response.data.questions)
      setRequiresConfirmation(response.data.requiresConfirmation)
      setDisqualifiedMessage(response.data.disqualifiedMessage)
      setQualificationStatus(response.data.qualificationStatus)
    }
    return response
  }

  const answerQuestionWrapper = (
    question: SurveyQuestion,
    answerType: SurveyAnswerType,
    answerValue
  ) => {
    return answerQuestion(
      questionnaireResponse.id,
      question,
      answerType,
      answerValue
    )
      .then((response) => {
        refreshDmeOrder()
        return updateState(response)
      })
      .catch((error) => {
        setError(error.response.data.error)
        reportError(error)
      })
  }

  return (
    <InternalChartNotes
      answerQuestion={answerQuestionWrapper}
      confirmPrefilledAnswers={async () => {}}
      name={`${questionnaireResponse.title} Medical Review`}
      questions={questions}
      requiresConfirmation={requiresConfirmation}
      disqualifiedMessage={disqualifiedMessage}
      qualificationStatus={qualificationStatus}
      hasDefaultChoices={false}
      chartNoteHelperType={ChartNoteHelperType.Manual}
      nextPagePath={nextPagePath}
      isOptumFlow={isOptumFlow}
      optumError={error}
    />
  )
}

const fetchInitialData = ({ questionnaireResponseId }) =>
  startQuestionnaireResponse(questionnaireResponseId)
export default withInitialData(fetchInitialData)(QuestionnaireResponses)
