import React, { useState } from "react"
import { MaterialThemeProvider } from "../../themes/theme"
import { GridColDef } from "@mui/x-data-grid-pro"
import { isTest } from "../../utilities/environment"
import ParachuteMuiDataGridPro from "../../components/ParachuteMuiDataGrid/ParachuteMuiDataGrid"
import ParachuteMuiDataGridContainer from "../../components/ParachuteMuiDataGrid/ParachuteMuiDataGridContainer"
import useServerSideDataGrid from "../Cms/hooks/useServerSideDataGrid"
import { getSalesCollaborationFacilities } from "./api"
import { withBrowserRouter } from "../../routers/BrowserRouter"
import {
  SalesCollaborationFacilitiesOptions,
  SalesCollaborationFacilitiesRow,
  SalesCollaborationToolsNetworkTableProps,
} from "./sharedTypes"
import { handleError } from "../../utilities/error"
import { ApplicationError } from "../../sharedTypes"

const SalesCollaborationToolsNetworkTable = (
  props: SalesCollaborationToolsNetworkTableProps
): React.JSX.Element => {
  const { page, supplierOrgExternalId } = props

  const columns: GridColDef[] = [
    {
      field: "facilityName",
      headerName: "Facility Name",
      sortable: false, // do server side sorting instead
      hide: false,
      type: "string",
      cellClassName: "canopy-typography-font-weight-bold",
      valueFormatter: (params) => params.value?.toString(),
      flex: 1,
    },
    {
      field: "onParachute",
      headerName: "On Parachute",
      sortable: false, // do server side sorting instead
      hide: false,
      type: "boolean",
      valueFormatter: (params) => params.value?.toString(),
      flex: 1,
    },
    {
      field: "avgMonthlyOrders",
      headerName: "Avg. monthly orders (last 3 months)",
      sortable: false, // do server side sorting instead
      hide: false,
      type: "number",
      valueFormatter: (params) => params.value?.toString(),
      flex: 1,
    },
    {
      field: "outstandingOrdersCount",
      headerName: "Outstanding Orders",
      sortable: false, // do server side sorting instead
      hide: false,
      type: "number",
      valueFormatter: (params) => params.value?.toString(),
      flex: 1,
    },
    {
      field: "totalOrdersCount",
      headerName: "Orders (total)",
      sortable: false, // do server side sorting instead
      hide: false,
      type: "number",
      valueFormatter: (params) => params.value?.toString(),
      flex: 1,
    },
    {
      field: "salesRep",
      headerName: "Sales Rep",
      sortable: false, // do server side sorting instead
      hide: false,
      type: "string",
      valueFormatter: (params) => params.value?.toString(),
      flex: 1,
    },
    {
      field: "facilityAddress",
      headerName: "Address",
      sortable: false, // do server side sorting instead
      hide: false,
      type: "string",
      valueFormatter: (params) => params.value?.toString(),
      flex: 1,
    },
  ]

  const defaultOptions: SalesCollaborationFacilitiesOptions = {
    page: page || 1,
    sort: [{ field: "facilityName", sort: "asc" }],
    supplierOrgExternalId: supplierOrgExternalId,
  }
  const [loading, setLoading] = useState<boolean>(false)
  const [data, setData] = useState<{
    rows: SalesCollaborationFacilitiesRow[]
    totalCount: number
  }>({
    rows: [],
    totalCount: 0,
  })

  const fetchFunction = async (params): Promise<void> => {
    try {
      const data = await getSalesCollaborationFacilities(
        supplierOrgExternalId,
        params
      )
      setData({
        rows: data.rows,
        totalCount: data.totalCount,
      })
    } catch (e) {
      handleError(e as ApplicationError)
    }
  }

  const beforeFetch = () => {
    setLoading(true)
  }
  const afterFetch = () => {
    setLoading(false)
  }

  const { options, handlePageChange } = useServerSideDataGrid<
    SalesCollaborationFacilitiesOptions
  >({
    defaultOptions,
    columnDefinitions: columns,
    trackHistory: false,
    fetchFunction,
    beforeFetch,
    afterFetch,
  })

  return (
    <div style={{ maxWidth: "inherit" }}>
      <MaterialThemeProvider>
        <ParachuteMuiDataGridContainer>
          <ParachuteMuiDataGridPro
            disableColumnFilter
            columns={columns}
            autoHeight
            disableVirtualization={isTest()} // Needs to be true for tests to work but ideally false in production, esp. for higher row counts
            rows={data.rows}
            rowCount={data.totalCount}
            density="standard"
            loading={loading}
            sortModel={options.sort}
            page={(options.page || 1) - 1} // account for DataGrid's zero-based indexing
            onPageChange={(page) => {
              handlePageChange(page + 1)
            }} // account for DataGrid's zero-based indexing
            getRowId={(row) => row.externalId}
            pagination={true}
            paginationMode="server"
            pageSize={25}
            rowsPerPageOptions={[25]}
            rowHeight={62}
          />
        </ParachuteMuiDataGridContainer>
      </MaterialThemeProvider>
    </div>
  )
}

export default withBrowserRouter(SalesCollaborationToolsNetworkTable, {})
