import React from "react"
import { CanopyButton } from "@parachutehealth/canopy-button"
import { supplierIntakeNavbar, documentActions } from "./index.module.scss"
import { InternalStatus } from "./components/InternalStatus"
import { InternalStatus as InternalStatusType } from "../../sharedTypes"
import { Discard } from "./components/Discard"

interface Props {
  dashboardUrl: string
  documentTitle: string | undefined
  internalStatuses: InternalStatusType[]
  internalStatus?: InternalStatusType
}

export const SupplierIntakeNavbar = ({
  dashboardUrl,
  documentTitle,
  internalStatuses,
  internalStatus,
}: Props) => {
  return (
    <nav className={supplierIntakeNavbar}>
      <CanopyButton
        as="a"
        href={dashboardUrl}
        iconStart="arrow-left"
        size="small"
        variant="tertiary"
      >
        Return to dashboard
      </CanopyButton>

      <div className={documentActions}>
        <InternalStatus
          internalStatuses={internalStatuses}
          internalStatus={internalStatus}
        />
        <Discard documentTitle={documentTitle} dashboardUrl={dashboardUrl} />
      </div>
    </nav>
  )
}
