import React from "react"
import { Field, useFormikContext } from "formik"
import { Autocomplete, createFilterOptions } from "@material-ui/lab"
import ExpandMoreIcon from "@material-ui/icons/ExpandMore"
import { TextField } from "@material-ui/core"

const filter = createFilterOptions()

const getOptionLabel = (option) => {
  // Value selected with enter, right from the input
  if (typeof option === "string") {
    return option
  }
  return option.text
}

export interface Props {
  name: string
  placeholder?: string
  label?: string
  options: { id: string | number; text: string }[]
  size?: string
  error?: boolean
  id?: string
  freeSolo: boolean
  required?: boolean
}

const AutocompleteField = (props: Props) => {
  const {
    name,
    placeholder,
    options,
    label,
    error,
    freeSolo,
    required,
    ...other
  } = props
  const { values, errors, setFieldValue } = useFormikContext<any>()

  const freeSoloFilterOptions = (options, params) => {
    const filtered = filter(options, params)
    // Suggest the creation of a new value
    if (freeSolo && params.inputValue !== "") {
      filtered.push({
        inputValue: params.inputValue,
        text: `Add "${params.inputValue}"`,
      })
    }

    return filtered
  }

  const onChange = (event, option: any) => {
    if (typeof option === "string") {
      // Create a new value from the user input
      setFieldValue(name, { text: option })
    } else if (option && option.inputValue) {
      // Create a new value from the user input
      setFieldValue(name, { text: option.inputValue })
    } else {
      setFieldValue(name, option)
    }
  }

  return (
    <>
      <Field
        clearOnBlur
        component={Autocomplete}
        filterOptions={freeSoloFilterOptions}
        forcePopupIcon
        freeSolo={freeSolo}
        getOptionLabel={getOptionLabel}
        handleHomeEndKeys
        name={name}
        onChange={onChange}
        options={options}
        label={label}
        popupIcon={<ExpandMoreIcon />}
        renderInput={(params) => (
          <TextField
            {...params}
            required={required}
            variant="outlined"
            placeholder={placeholder}
            label={label}
            fullWidth
            error={error}
          />
        )}
        selectOnFocus
        value={values[name]}
        {...other}
      />
      {errors[name] && <div className="color-danger">{errors[name]}</div>}
    </>
  )
}

export default AutocompleteField
