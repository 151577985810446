import React from "react"
import ReviewCard from "../../ReviewCard"
import {
  DmeOrder,
  DmeOrderWarning,
  Employer,
  DocumentationRequirementSatisfyStatus,
  Severity,
  QuestionnaireResponse,
} from "sharedTypes"
import { useFeatureFlags } from "components/FeatureFlagContext"
import {
  requestedDocumentationRequirements,
  titleWithCategory,
  titleWithUsageContext,
} from "applications/Workflow/utilities/documentationRequirements"
import DocumentationRequirementRow from "../../../../../ClinicalFacilityClinical/components/DocumentationRequirements/DocumentationRequirementRow"
import MedicalNecessitySummaryRow from "./components/MedicalNecessitySummaryRow/MedicalNecessitySummaryRow"
import { isChartNoteRelatedToDocumentationRequirement } from "applications/Workflow/containers/ClinicalFacilityClinical/components/utilities"
import MedicalNecessityRow from "./components/MedicalNecessityRow/MedicalNecessityRow"
import { DocumentationRequirementStatus } from "applications/Workflow/containers/ClinicalFacilityClinical/components/DocumentationRequirements/DocumentationRequirementRow/DocumentationRequirementStatus"
import ExternalQuestionnaireStatusPill from "../../../ExternalQuestionnaireStatusPill"

type Props = {
  currentEmployer?: Employer
  dmeOrder: DmeOrder
  expanded: boolean
  warnings?: DmeOrderWarning[]
  questionnaireResponses: QuestionnaireResponse[]
}

const statusSeverity = {
  [DocumentationRequirementSatisfyStatus.Complete]: 2,
  [DocumentationRequirementSatisfyStatus.Incomplete]: 1,
  [DocumentationRequirementSatisfyStatus.Unstarted]: 1,
  [DocumentationRequirementSatisfyStatus.Invalid]: 0,
}

const statusToSeverity = (status) => {
  switch (status) {
    case DocumentationRequirementSatisfyStatus.Complete:
      return Severity.Success
    case DocumentationRequirementSatisfyStatus.Incomplete:
    case DocumentationRequirementSatisfyStatus.Unstarted:
      return Severity.Warning
    case DocumentationRequirementSatisfyStatus.Invalid:
      return Severity.Danger
  }
}

function MedicalNecessityReviewCard({
  dmeOrder,
  expanded,
  warnings,
  questionnaireResponses,
}: Props) {
  const { isFeatureEnabled } = useFeatureFlags()
  const contextDrivenDocs = isFeatureEnabled("contextDrivenDocs")
  const demoClinicalGuidelines =
    isFeatureEnabled("demoClinicalGuidelines") && dmeOrder.optum

  const { chartNotes } = dmeOrder
  const visibleDocumentationRequirements = requestedDocumentationRequirements(
    dmeOrder.documentationRequirements
  )

  const productConfigurationWarning =
    warnings &&
    warnings.find(
      (warning) =>
        warning.key === "rx_details" || warning.key === "product_requirements"
    )

  const summary = [
    ...visibleDocumentationRequirements.map((d) => (
      <MedicalNecessitySummaryRow
        title={
          contextDrivenDocs ? titleWithUsageContext(d) : titleWithCategory(d)
        }
        statusPill={
          <DocumentationRequirementStatus satisfyStatus={d.satisfyStatus} />
        }
        key={`Key - ${
          contextDrivenDocs ? titleWithUsageContext(d) : titleWithCategory(d)
        }`}
      />
    )),
    demoClinicalGuidelines
      ? []
      : questionnaireResponses.map(
          ({ title, id, questionsWithSelectedAnswers, status }) => (
            <MedicalNecessitySummaryRow
              title={title}
              questionsWithSelectedAnswers={questionsWithSelectedAnswers}
              statusPill={
                <ExternalQuestionnaireStatusPill satisfyStatus={status} />
              }
              key={id}
            />
          )
        ),
  ]

  const mostSevereStatus = visibleDocumentationRequirements
    .map((d) => d.satisfyStatus)
    .sort(
      (statusA, statusB) => statusSeverity[statusA] - statusSeverity[statusB]
    )[0]
  const severity = statusToSeverity(mostSevereStatus)

  const expandedContent = () => {
    if (
      visibleDocumentationRequirements.length === 0 &&
      questionnaireResponses.length === 0
    )
      return

    return (
      <div className="well well-expand canopy-p-8x">
        {visibleDocumentationRequirements.map(
          (documentationRequirement, index) => (
            <div key={documentationRequirement.externalId}>
              {index !== 0 && <hr />}
              <DocumentationRequirementRow
                dmeOrderId={dmeOrder.id}
                chartNote={chartNotes.find((cn) =>
                  isChartNoteRelatedToDocumentationRequirement(
                    documentationRequirement,
                    cn,
                    contextDrivenDocs
                  )
                )}
                documentationRequirement={documentationRequirement}
                permissions={{
                  updateChartNotes: false,
                  updateManualDocumentation: false,
                }}
                missingChartNotesPrerequisites={!!productConfigurationWarning}
                bounceToSection={{
                  shouldBounce: false,
                  triggerBounce: () => {},
                }}
                detailed={false}
              />
            </div>
          )
        )}
        {visibleDocumentationRequirements.length > 0 && <hr />}
        {!demoClinicalGuidelines &&
          questionnaireResponses.map((questionnaire, index) => (
            <div key={questionnaire.id}>
              {index !== 0 && <hr />}
              <MedicalNecessityRow
                title={questionnaire.title}
                recommendation={questionnaire.status}
                questionsWithSelectedAnswers={
                  questionnaire.questionsWithSelectedAnswers
                }
              />
            </div>
          ))}
      </div>
    )
  }

  return (
    <ReviewCard
      status={severity}
      expanded={expanded}
      summary={summary}
      title="Medical Necessity Review"
    >
      {expandedContent()}
    </ReviewCard>
  )
}

export default MedicalNecessityReviewCard
