// @ts-strict-ignore
import React, { useState } from "react"
import {
  DmeOrder,
  DmeOrderWarning,
  DmeOrderWarningSeverity,
  Document,
  Employer,
  EmployerType,
  LineItemGroup,
  QuestionnaireResponse,
  RxDetailsOutput,
  SignatureType,
} from "sharedTypes"
import Actions from "../Actions"
import PatientPortal from "../PatientPortal"
import SubmitButton from "../SubmitButton"
import ReviewCards from "../ReviewCards"
import { Permissions, SignatureRequest, Submission } from "../../sharedTypes"
import { FormData } from "applications/FulfillmentEditor/components/FulfillmentForm"
import SelfSignAttestation from "components/SelfSignAttestation"
import SignOnScreenAttestation from "../SignOnScreenAttestation"
import ReviewerSignUpPromo from "../ReviewerSignUpPromo"
import cx from "classnames"
import ReorderForm from "applications/ReorderForm"

type Props = {
  warnings: DmeOrderWarning[]
  trackConversionEvents: boolean
  dmeOrder: DmeOrder
  downloadableDocuments: Document[]
  supplierEdit(): Promise<void>
  pullBack(): void
  setUserEmail(): void
  updateClinicalFacility(): void
  supplierUnaccept(): Promise<void>
  currentEmployer: Employer
  requiresTwoStepDownload: boolean
  permissions: Permissions
  signatureRequests: SignatureRequest[]
  sendSignatureRequest(params: {}): Promise<void>
  submission: Submission
  submit(): Promise<string | void>
  selfSign: boolean
  deliveryTicketSignedAt: string
  updateFulfillment(id: string, values: FormData): Promise<void>
  rxDetailsOutputs: RxDetailsOutput[]
  lineItemGroups: LineItemGroup[]
  extraAttestationRequested: boolean
  signProofOfDelivery(): void
  userEmail?: string
  dmeOrderSpecificUser?: boolean
  archive(): Promise<void>
  externalQuestionnairesCompleted?: boolean
  questionnaireResponses: QuestionnaireResponse[]
  isOptumFlow: boolean
}

const InternalReview = (props: Props) => {
  const {
    dmeOrder,
    currentEmployer,
    requiresTwoStepDownload,
    permissions,
    signatureRequests,
    submission,
    submit,
    selfSign,
    warnings,
    trackConversionEvents,
    downloadableDocuments,
    pullBack,
    setUserEmail,
    updateClinicalFacility,
    supplierEdit,
    supplierUnaccept,
    sendSignatureRequest,
    deliveryTicketSignedAt,
    updateFulfillment,
    rxDetailsOutputs,
    lineItemGroups,
    extraAttestationRequested,
    signProofOfDelivery,
    userEmail,
    dmeOrderSpecificUser,
    archive,
    externalQuestionnairesCompleted,
    questionnaireResponses,
    isOptumFlow,
  } = props

  const [isSubmitting, setSubmitting] = useState(false)
  const [isExtraAttestationChecked, setExtraAttestationChecked] = useState(
    false
  )
  const { id, reorderable } = dmeOrder
  const hasErrors = !!warnings.filter(
    ({ severity }) => severity === DmeOrderWarningSeverity.Error
  ).length
  const showSubmit = permissions.submit
  const showReorder = reorderable && permissions.reorder
  const submissionPending = isSubmitting || submission.status === "pending"
  const submitOrder = () => {
    setSubmitting(true)
    return submit()
      .then(() => setSubmitting(false))
      .catch(() => setSubmitting(false))
  }

  const signOnScreen =
    dmeOrder.signatureType === SignatureType.OnScreen &&
    currentEmployer.employerType === EmployerType.ClinicalFacility &&
    !selfSign

  function canUserSubmitOrder() {
    if (!permissions.submit) {
      return false
    }
    if (hasErrors) {
      return false
    }
    if (isOptumFlow && !externalQuestionnairesCompleted) {
      return false
    }
    if (
      signOnScreen &&
      extraAttestationRequested &&
      !isExtraAttestationChecked
    ) {
      return false
    }
    return true
  }

  const showPatientPortal = !!(
    dmeOrder.clinicalFacility.patientPortalEnabled ||
    dmeOrder.supplier?.patientPortalEnabled
  )

  const canSubmit = canUserSubmitOrder()

  return (
    <div>
      <Actions
        warnings={warnings}
        trackConversionEvents={trackConversionEvents}
        dmeOrder={dmeOrder}
        permissions={permissions}
        signatureRequests={signatureRequests}
        selfSign={selfSign}
        sendSignatureRequest={sendSignatureRequest}
        pullBack={pullBack}
        setUserEmail={setUserEmail}
        submit={submitOrder}
        submission={submission}
        supplierEdit={supplierEdit}
        supplierUnaccept={supplierUnaccept}
        currentEmployer={currentEmployer}
        submissionPending={submissionPending}
        showSubmit={showSubmit}
        canSubmit={canSubmit}
        showReorder={showReorder}
        updateFulfillment={updateFulfillment}
        extraAttestationRequested={extraAttestationRequested}
        isExtraAttestationChecked={isExtraAttestationChecked}
        setExtraAttestationChecked={setExtraAttestationChecked}
        userEmail={userEmail}
        dmeOrderSpecificUser={dmeOrderSpecificUser}
        archive={archive}
      />
      {permissions.signUpAsReviewer && (
        <ReviewerSignUpPromo doctor={dmeOrder.doctor} />
      )}
      {showPatientPortal && (
        <PatientPortal
          employer={currentEmployer}
          globalFacilityEnabled={dmeOrder.clinicalFacility.patientPortalEnabled}
          globalSupplierEnabled={dmeOrder.supplier?.patientPortalEnabled}
          orderStatus={dmeOrder.orderStatus}
          latestPatientPortalEventTimestamp={
            dmeOrder.latestPatientPortalEventTimestamp
          }
        />
      )}
      <ReviewCards
        currentEmployer={currentEmployer}
        deliveryTicketSignedAt={deliveryTicketSignedAt}
        dmeOrder={dmeOrder}
        downloadableDocuments={downloadableDocuments}
        lineItemGroups={lineItemGroups}
        rxDetailsOutputs={rxDetailsOutputs}
        permissions={permissions}
        requiresTwoStepDownload={requiresTwoStepDownload}
        sendSignatureRequest={sendSignatureRequest}
        signatureRequests={signatureRequests}
        signProofOfDelivery={signProofOfDelivery}
        warnings={warnings}
        updateFulfillment={updateFulfillment}
        updateClinicalFacility={updateClinicalFacility}
        questionnaireResponses={questionnaireResponses}
        isOptumFlow={isOptumFlow}
      />
      <div className={cx("wide-fixed-row")}>
        {showSubmit && (
          <>
            <SubmitButton
              signNow={selfSign || signOnScreen}
              submissionPending={submissionPending}
              currentEmployer={currentEmployer}
              submit={submit}
              disabled={!canSubmit}
            />
            {selfSign && (
              <div className="canopy-mbs-8x">
                <SelfSignAttestation />
              </div>
            )}
            {signOnScreen && (
              <div className="canopy-mbs-8x">
                <SignOnScreenAttestation
                  extraAttestationRequested={extraAttestationRequested}
                  isExtraAttestationChecked={isExtraAttestationChecked}
                  onChange={(event) =>
                    setExtraAttestationChecked(event.target.checked)
                  }
                  doctor={dmeOrder.doctor}
                />
              </div>
            )}
          </>
        )}
        {showReorder && (
          <ReorderForm dmeOrderId={id} currentEmployer={currentEmployer} />
        )}
      </div>
    </div>
  )
}

export default InternalReview
