import React from "react"
import { Form } from "components/form"
import {
  ChartNote,
  ChartNoteQuestion,
  DocumentationRequirement,
  DmeOrder,
  QuestionnaireResponse,
} from "sharedTypes"
import DocumentationRequirementsSelection from "./DocumentationRequirementsSelection"
import ReadOnlyChartNote from "./ReadOnlyChartNote"
import { useFeatureFlags } from "components/FeatureFlagContext"

import cx from "classnames"
import OptumDocumentationRequirements from "./OptumDocumentationRequirements"

type Props = {
  className: string
  documentationRequirements: DocumentationRequirement[]
  questionnaireResponses?: QuestionnaireResponse[]
  updateDocumentationRequirementsRequested(
    requestedIds: string[]
  ): Promise<void>
  chartNotes: ChartNote[]
  fetchChartNoteQuestions({ id }: { id: string }): Promise<ChartNoteQuestion[]>
  dmeOrder: DmeOrder
}

const SupplierDocumentationRequirements = ({
  className,
  documentationRequirements,
  questionnaireResponses = [],
  updateDocumentationRequirementsRequested,
  chartNotes,
  fetchChartNoteQuestions,
  dmeOrder,
}: Props) => {
  const requestedDocumentationRequirementIds = documentationRequirements
    .filter((docReq) => docReq.requested)
    .map((docReq) => docReq.externalId)

  const { isFeatureEnabled } = useFeatureFlags()
  const shouldShowPayerEnhancedGuidelines =
    isFeatureEnabled("payorEnhancedClinicalGuidelines") && dmeOrder.optum

  if (documentationRequirements.length === 0 && chartNotes.length === 0) {
    return <></>
  }

  const renderAvailableDocumentation = () => {
    if (chartNotes.length === 0) {
      return <></>
    }

    return (
      <>
        <hr />
        <p className="color-dark-gray">Available Documentation:</p>
        {chartNotes.map((chartNote) => (
          <div key={chartNote.name}>
            <ReadOnlyChartNote
              chartNote={chartNote}
              fetchChartNoteQuestions={fetchChartNoteQuestions}
            />
          </div>
        ))}
      </>
    )
  }

  return (
    <>
      <div className={className}>
        <div className="well">
          <Form
            initialValues={{ requestedDocumentationRequirementIds }}
            onSubmit={({ requestedDocumentationRequirementIds }) =>
              updateDocumentationRequirementsRequested(
                requestedDocumentationRequirementIds
              )
            }
            submitOnChange
          >
            <DocumentationRequirementsSelection
              documentationRequirements={documentationRequirements}
            />
          </Form>

          {!shouldShowPayerEnhancedGuidelines && renderAvailableDocumentation()}
        </div>
      </div>

      {shouldShowPayerEnhancedGuidelines && (
        <OptumDocumentationRequirements
          className={cx("wide-fixed-row", "canopy-mbs-8x")}
          questionnaireResponses={questionnaireResponses}
          documentationRequirements={documentationRequirements}
          dmeOrder={dmeOrder}
          chartNotes={chartNotes}
          fetchChartNoteQuestions={fetchChartNoteQuestions}
          updateDocumentationRequirementsRequested={
            updateDocumentationRequirementsRequested
          }
        />
      )}
    </>
  )
}

export default SupplierDocumentationRequirements
