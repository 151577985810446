import React, { useEffect, useState } from "react"
import { useHistory } from "react-router-dom"
import { PAYOR_BASE_ROUTES } from "../../constants/payor_routes"
import { CanopyIcon } from "@parachutehealth/canopy-icon"
import LeftSidebar, { Option } from "components/LeftSidebar"

const PayorSidebar = (): React.JSX.Element => {
  const history = useHistory()

  const [activeRoute, setActiveRoute] = useState<PAYOR_BASE_ROUTES>(
    history.location.pathname
  )

  useEffect(() => {
    const currentPath = history.location.pathname
    setActiveRoute(currentPath)
  }, [history.location.pathname])

  const payorSidebarOptions: Option[] = [
    {
      icon: () => <CanopyIcon name="parachute-box" />,
      text: "Orders",
      onClick: () => history.push(PAYOR_BASE_ROUTES.ORDERS_DASHBOARD),
      active: activeRoute === PAYOR_BASE_ROUTES.ORDERS_DASHBOARD,
    },
    {
      icon: () => <CanopyIcon name="user" />,
      text: "Member search",
      onClick: () => history.push(PAYOR_BASE_ROUTES.MEMBER_SEARCH),
      active: activeRoute === PAYOR_BASE_ROUTES.MEMBER_SEARCH,
    },
  ]

  return <LeftSidebar options={payorSidebarOptions} renderExpanded />
}

export default PayorSidebar
