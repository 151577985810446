import React, { useState } from "react"
import classNames from "classnames"
import cx from "classnames"
import { CanopyIcon } from "@parachutehealth/canopy-icon"
import Tooltip from "components/Tooltip"
import { useGlobalContext } from "hooks"
import { inOrderNavReplacement } from "applications/Workflow/utilities/utilityNav"
import { Tab } from "./index"
import { SidebarTab } from "../Navigation/sharedTypes"
import { canopyColorPrimitivesMagenta40 } from "@parachutehealth/canopy-tokens-color"
import { OrderStatus } from "sharedTypes"
import { getEmployerType } from "utilities/url"
import { EventCategory, trackEvent } from "utilities/tracking"
import Cookies from "js-cookie"
import { useFeatureFlags } from "../../../../components/FeatureFlagContext"

interface Props {
  tabs: Tab[]
  onClick(tab: Tab): void
  onClose(): void
  currentTab: SidebarTab
  fixed?: boolean
  orderStatus: OrderStatus
  portalMessagingSupplierEnabled?: boolean
}

interface PatientChatCalloutProps {
  tab: Tab
  currentTab: SidebarTab
  onClick(tab: Tab): void
  onClose(): void
}

const PatientChatCallout = ({
  tab,
  currentTab,
  onClick,
  onClose,
}: PatientChatCalloutProps) => {
  return (
    <Tooltip
      defaultTooltipShown
      disabled={currentTab === SidebarTab.PatientMessaging}
      trigger={[]}
      overlayClassName="patient-chat-tooltip"
      overlay={
        <>
          <div className="patient-chat-tooltip__header">
            <h2>Chat with a Patient</h2>
            <div
              className="float-right icon clickable"
              onClick={(e) => {
                e.stopPropagation()
                return onClose()
              }}
              role="button"
              style={{
                alignItems: "center",
                display: "flex",
                justifyContent: "center",
              }}
            >
              <CanopyIcon size="medium" name="xmark" />
            </div>
          </div>
          <p>
            Verify patient info, notify them about co-pays, and ensure smooth
            communication overall with this feature.
          </p>
          <button
            data-testid="learn-more-chat-btn"
            onClick={() => {
              trackEvent(EventCategory.PatientPortalChat, "clicked-chat-popup")
              onClick(tab)
            }}
          >
            Learn more
          </button>
        </>
      }
      placement={Tooltip.Placement.Left}
      width={Tooltip.Width.Unlimited}
    >
      {tab.icon}
    </Tooltip>
  )
}

const RightSidebar: React.FC<Props> = ({
  tabs,
  onClick,
  onClose,
  currentTab,
  fixed,
  orderStatus,
  portalMessagingSupplierEnabled,
}) => {
  const context = useGlobalContext()
  const showTabs = !inOrderNavReplacement(context)
  const tab = tabs.find((tab) => tab.sidebarTab === currentTab)
  const { isFeatureEnabled } = useFeatureFlags()

  const [seenPatientChatCallout, setSeenPatientChatCallout] = useState(() => {
    if (!!Cookies.get("seenPatientChatCallout")) {
      return true
    } else {
      if (isFeatureEnabled("chatCommercialization")) {
        Cookies.set("seenPatientChatCallout", "true", { expires: 365 })
      }
      return false
    }
  })

  const unreadAlert = (tab: Tab) => (
    <div
      className="position-absolute"
      style={{ right: "12px" }}
      id={`unread-alert-${tab.tabName}`}
      data-testid={`test-alert-${tab.tabName}`}
    >
      <svg
        width="7"
        height="7"
        viewBox="0 0 7 7"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <circle
          cx="3.5"
          cy="3.5"
          r="3.5"
          fill={canopyColorPrimitivesMagenta40}
        />
      </svg>
    </div>
  )

  const renderTab = (tab: Tab) => {
    return (
      <button
        key={tab.sidebarTab}
        className="btn btn-brand-o btn-default btn-full-xs"
        type="button"
        onClick={() => onClick(tab)}
        data-analytics={tab.analytics?.closed}
      >
        {tab.tabName}
      </button>
    )
  }

  const renderTooltip = (tab: Tab) => {
    if (
      isFeatureEnabled("chatCommercialization") &&
      !seenPatientChatCallout &&
      tab.sidebarTab === "patientMessaging" &&
      !portalMessagingSupplierEnabled
    ) {
      return (
        <PatientChatCallout
          tab={tab}
          currentTab={currentTab}
          onClick={() => {
            setSeenPatientChatCallout(true)
            onClick
          }}
          onClose={() => {
            setSeenPatientChatCallout(true)
            onClose
          }}
        />
      )
    } else {
      return (
        <Tooltip
          trigger={["hover"]}
          overlay={tab.tooltip}
          placement={Tooltip.Placement.Left}
          width={Tooltip.Width.Unlimited}
        >
          {tab.icon}
        </Tooltip>
      )
    }
  }

  const renderButton = (tab: Tab) => {
    return (
      <div key={tab.sidebarTab}>
        {tab.alertDot && unreadAlert(tab)}
        <button
          type="button"
          onClick={() => onClick(tab)}
          data-analytics={tab.analytics?.closed}
          className={classNames({
            active: tab.sidebarTab === currentTab,
          })}
          aria-label={tab.tooltip}
        >
          {renderTooltip(tab)}
        </button>
        <hr />
      </div>
    )
  }
  const employerType = getEmployerType()
  const shouldActivityBarBeWider =
    orderStatus === OrderStatus.SupplierReview ||
    orderStatus === OrderStatus.Accepted ||
    (employerType === "Supplier" &&
      orderStatus === OrderStatus.FacilityInProgress)
  const containerClass = () => {
    if (shouldActivityBarBeWider) return "col-md-5"
    return "col-md-4"
  }
  return (
    <div
      className={classNames("canopy-p-0 right-sidebar", {
        fixed: fixed,
        [containerClass()]: tab,
      })}
      data-analytics-context="Sidebar"
    >
      {showTabs && !tab && (
        <div className="mobile-buttons">{tabs.map(renderTab)}</div>
      )}
      {showTabs && (
        <div className="sidebar-square-buttons">{tabs.map(renderButton)}</div>
      )}
      {tab && (
        <div className="right-sidebar-body canopy-pbs-8x bl bb bc-p4">
          <div className="clearfix">
            <div className={cx("col-8", "canopy-px-0", "float-left")}>
              <h3 className="canopy-typography-heading-xlarge">{tab.title}</h3>
              {tab.subtitle}
            </div>
            <span className="float-right">
              {tab.headerButton}
              <div
                className="float-right icon clickable"
                onClick={onClose}
                role="button"
                data-analytics="close-sidebar"
                aria-label="close sidebar"
                style={{
                  alignItems: "center",
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                <CanopyIcon size="medium" name="xmark" />
              </div>
            </span>
          </div>
          <div className="content-wrapper">{tab.content}</div>
        </div>
      )}
    </div>
  )
}

export default RightSidebar
