import React from "react"
import {
  OptionItem,
  SelectOption,
} from "@parachutehealth/canopy-combobox-field"
import TherapySelect from "./TherapySelect"
import { TherapyType } from "../sharedTypes"
import { ApplicationError, SurveyQuestion } from "../../../sharedTypes"
import { saveTherapy, TherapySelectionsResponse } from "../api"
import { Link } from "react-router-dom"
import Survey from "../../Workflow/components/Survey"
import { handleError } from "../../../utilities/error"

interface TherapyProps {
  diagnosisPath: string
  pendingOrderId: string
  therapies: TherapyType[]
}

const Therapy = ({
  diagnosisPath,
  pendingOrderId,
  therapies,
}: TherapyProps) => {
  const [rxQuestions, setRxQuestions] = React.useState<SurveyQuestion[]>([])
  const [value, setValue] = React.useState<SelectOption | null>(null)

  const mapTherapiesToOptions: (therapies: TherapyType[]) => SelectOption[] = (
    therapies
  ) => {
    return therapies.map(
      (therapy: TherapyType) =>
        ({
          label: therapy.name,
          value: String(therapy.id),
        } as SelectOption)
    )
  }

  const options: SelectOption[] = mapTherapiesToOptions(therapies)

  const handleTherapySelect: (therapy: OptionItem) => Promise<void> = async (
    therapy
  ) => {
    setValue(therapy)

    if (!therapy) {
      setRxQuestions([])
      return
    }

    try {
      const response: TherapySelectionsResponse = await saveTherapy(
        pendingOrderId,
        therapy.value
      )
      setRxQuestions(response.data.questions)
    } catch (e) {
      handleError(e as ApplicationError)
    }
  }

  const answerQuestion: (
    question: SurveyQuestion,
    answerType: string,
    answerValue: string
    // this will be removed when the work for WF-29049 is done
    // eslint-disable-next-line unused-imports/no-unused-vars
  ) => Promise<void> = (question, answerType, answerValue) => {
    // TODO: save answers WF-29049
    return Promise.resolve()
  }

  return (
    <>
      <h1 className="canopy-ml-8x">Therapy</h1>
      <TherapySelect
        options={options}
        value={value}
        handleTherapySelect={handleTherapySelect}
      />
      <div className="canopy-ml-8x canopy-mt-10x">
        <Survey
          questions={rxQuestions}
          answerQuestion={answerQuestion}
          showMostCommon={true}
        />
      </div>
      <Link to={diagnosisPath}>Diagnosis</Link>
    </>
  )
}

export default Therapy
