import React from "react"
import {
  DmeOrderConnection,
  PayorDmeOrdersSortEnum,
  SortDirectionEnum,
} from "../../../../../graphql/__generated__/graphql"
import { useQuery } from "@apollo/client"
import { SearchBar } from "../../components/SearchBar/SearchBar"
import { DataGridOptions, Employer } from "../../../../../sharedTypes"
import { MaterialThemeProvider } from "../../../../../themes/theme"
import ParachuteMuiDataGridContainer from "../../../../../components/ParachuteMuiDataGrid/ParachuteMuiDataGridContainer"
import { OrdersDashboardTable } from "./DataGrid/OrdersDashboardTable"
import { ordersDashboardTableColumnDefinitions } from "./DataGrid/tableColumnDefinitions"
import { Box } from "@material-ui/core"
import { useFeatureFlags } from "components/FeatureFlagContext"
import { OrdersDashboard_GetPayorDmeOrders } from "../graphql/ordersDashboardGetDmeOrders"
import { GridSortDirection } from "@mui/x-data-grid-pro"

interface OrdersDashboardProps {
  payor: Employer
}

const PAGE_SIZE = 25
const INITIAL_SORT_FIELD = PayorDmeOrdersSortEnum.AuthSubmitted
const INITIAL_SORT_DIRECTION = SortDirectionEnum.Desc

const OrdersDashboard = ({ payor }: OrdersDashboardProps): JSX.Element => {
  const { data, refetch, fetchMore, loading } = useQuery(
    OrdersDashboard_GetPayorDmeOrders,
    {
      notifyOnNetworkStatusChange: true,
      fetchPolicy: "cache-and-network",
      variables: {
        first: PAGE_SIZE,
        sortDirection: INITIAL_SORT_DIRECTION,
        sort: INITIAL_SORT_FIELD,
      },
    }
  )

  const dmeOrders = data?.payorDmeOrders as DmeOrderConnection

  const handleSearch = async (memberId?: string) => {
    await refetch({ memberId: memberId })
  }

  const initialGridOptions: Partial<DataGridOptions> = {
    sort: [
      {
        field: INITIAL_SORT_FIELD,
        sort: INITIAL_SORT_DIRECTION.toLowerCase() as GridSortDirection,
      },
    ],
  }

  const { isFeatureEnabled } = useFeatureFlags()
  const orderDetailsEnabled = isFeatureEnabled("payorOrderDetails")
  const authDetailsEnabled = isFeatureEnabled("authRequestsPage")

  return (
    <>
      <h1 className="canopy-typography-heading-2xlarge canopy-mbs-12x canopy-mbe-0">
        Orders
      </h1>
      <p className="canopy-mbe-20x">
        All Parachute orders for members with {payor.name || "payor"} plans
      </p>
      <MaterialThemeProvider>
        <ParachuteMuiDataGridContainer>
          <Box my={2}>
            <SearchBar
              placeholder="Search member ID"
              hiddenLabel={true}
              searchButtonVariant="secondary"
              onSearch={handleSearch}
            />
            <OrdersDashboardTable
              pageSize={PAGE_SIZE}
              loading={loading}
              refetch={refetch}
              paginate={fetchMore}
              dmeOrderData={dmeOrders}
              columnDefs={ordersDashboardTableColumnDefinitions({
                orderDetailsEnabled,
                authDetailsEnabled,
              })}
              initialOptions={initialGridOptions}
            ></OrdersDashboardTable>
          </Box>
        </ParachuteMuiDataGridContainer>
      </MaterialThemeProvider>
    </>
  )
}

export default OrdersDashboard
