import React from "react"
import * as styles from "./PromotedSupplierDiscoveryBanner.module.scss"
import { CanopyNotice } from "@parachutehealth/canopy-notice"
import { CanopyLink } from "@parachutehealth/canopy-link"
import { CanopyIcon } from "@parachutehealth/canopy-icon"
import { Supplier } from "../sharedTypes"
import type { CanopyIconNames } from "@parachutehealth/canopy-icons/build/canopyIconGlyphNames"

interface PromotedSupplierDiscoveryBannerProps {
  onConfirm: () => void
  onDismiss: () => void
  supplier: Supplier
}

interface BannerLinkProps {
  icon: CanopyIconNames
  onClick: () => void
  text: string
}

const BannerLink: React.FC<BannerLinkProps> = ({ icon, text, onClick }) => {
  return (
    <div className={styles.promotedSupplierBannerLinkContainer}>
      <CanopyIcon
        className={styles.promotedSupplierLinkIcon}
        size="medium"
        name={icon}
      />
      <CanopyLink
        className={styles.promotedSupplierBannerLink}
        onClick={onClick}
        href="#"
      >
        {text}
      </CanopyLink>
    </div>
  )
}

const PromotedSupplierDiscoveryBanner: React.FC<PromotedSupplierDiscoveryBannerProps> = ({
  onConfirm,
  onDismiss,
  supplier,
}) => {
  return (
    <CanopyNotice
      actions={[
        {
          as: BannerLink,
          text: "Thank you",
          icon: "check",
          onClick: (e) => {
            e.preventDefault()
            onConfirm()
          },
        },
        {
          as: BannerLink,
          text: "We don't use them",
          icon: "xmark",
          onClick: (e) => {
            e.preventDefault()
            onDismiss()
          },
        },
      ]}
      className={styles.promotedSupplierBanner}
      message="Based on order history it looks like your facility orders from them via fax."
      title={`Did you know ${supplier.name} is available on Parachute?`}
      variant="discovery"
    />
  )
}

export default PromotedSupplierDiscoveryBanner
