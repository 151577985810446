import { QueryClient, useQuery } from "@tanstack/react-query"
import { Patient } from "./sharedTypes"

export const infinitySoThatThisWillNotRefetchUnlessSomeoneInvalidatesTheCacheManually = Infinity

export const patientQueryKey = ["csrInboxState", "patient"]

export const setPatientQueryData = (client: QueryClient, patient: Patient) => {
  client.setQueryData(patientQueryKey, patient)
}
export const usePatientQuery = () =>
  useQuery<Patient>({
    queryKey: patientQueryKey,
    queryFn: () => ({ firstName: "", lastName: "", dateOfBirth: "", sex: "" }),
    staleTime: infinitySoThatThisWillNotRefetchUnlessSomeoneInvalidatesTheCacheManually,
  })
