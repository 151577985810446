import { DataGridPro, GridColDef } from "@mui/x-data-grid-pro"
import React, { useState } from "react"
import { CatalogProduct } from "../../../../../types/sharedTypes"
import { getPackagesForProduct, ProductPackageDataGridResponse } from "./api"
import { CountContext } from "../../../../../contexts/CountContext"
import useServerSideDataGrid, {
  ServerSideDataGridOptions,
  ServerSideDataGridParams,
} from "../../../../../hooks/useServerSideDataGrid"
import { isTest } from "../../../../../../../utilities/environment"
import DataGridToolbar from "../../../../../components/DataGridToolbar"
import { CanopyFlex } from "@parachutehealth/canopy-flex"
import { packageDetailUrl } from "../../../../../urls/packages"
import CreateSimplePackageDialog from "../CreateSimplePackageDialog"
import { usePolicies } from "../../../../../contexts/PoliciesContext"

type ProductPackageDataGridOptions = ServerSideDataGridOptions

const defaultOptions: ProductPackageDataGridOptions = {
  page: 1,
  // todo: make this be driven by the backend? right now it's set in two different places
  sort: [{ field: "packageName", sort: "asc" }],
}

const defaultFilterModel = {
  columnFilters: [{ name: "archived", operator: "is", value: "false" }],
}

const columns: GridColDef[] = [
  {
    field: "packageExternalId",
    flex: 1,
    headerName: "ID",
    hide: true,
    minWidth: 200,
  },
  {
    field: "packageName",
    headerName: "Package Name",
    flex: 2,
    minWidth: 200,
    renderCell: (params) => (
      <a href={packageDetailUrl(params.row.packageExternalId)}>
        {params.row.packageName}
      </a>
    ),
  },
  {
    field: "required",
    headerName: "Required",
    type: "boolean",
    renderCell: (params) => {
      return params.value ? "Yes" : "No"
    },
    flex: 0.75,
    filterable: true,
    sortable: true,
  },
  {
    field: "archived",
    headerName: "Archived?",
    type: "boolean",
    renderCell: (params) => {
      return params.value ? "Yes" : "No"
    },
    flex: 0.75,
    filterable: true,
    hide: true,
    sortable: true,
  },
]

type Props = {
  product: CatalogProduct
}
const PackagesDataGrid = (props: Props) => {
  const { product } = props

  const { hasPermission } = usePolicies()

  const [gridData, setGridData] = React.useState<
    ProductPackageDataGridResponse
  >({
    records: [],
    totalCount: 0,
  })

  const { changeCount } = React.useContext(CountContext)
  const [loading, setLoading] = useState(false)
  const beforeFetch = () => setLoading(true)
  const afterFetch = () => setLoading(false)

  const fetchFunction = async (
    params: ServerSideDataGridParams
  ): Promise<void> => {
    const data = await getPackagesForProduct(product.externalId, params)
    changeCount("packages", data.totalCount)
    setGridData((prev) => ({
      ...prev,
      records: data.records,
      totalCount: data.totalCount,
    }))
  }

  const {
    refetch,
    filterModel,
    options,
    handlePageChange,
    handleFilterModelChange,
    handleSortModelChange,
  } = useServerSideDataGrid<ProductPackageDataGridOptions>({
    trackHistory: false,
    defaultOptions,
    columnDefinitions: columns,
    fetchFunction,
    beforeFetch,
    afterFetch,
    defaultFilterModel,
  })

  return (
    <div>
      <CanopyFlex
        justifyContent="space-between"
        alignItems="flex-end"
        paddingBlockEnd="12X"
      >
        <div>
          <div className="canopy-typography-heading-large canopy-mbe-4x">
            Packages
          </div>
          <div className="canopy-typography-body-medium">
            Navigate to packages containing this product.
          </div>
        </div>
        {product.buildableAsPackage &&
          hasPermission("Catalog::Package", "create") && (
            <CreateSimplePackageDialog product={product} onSuccess={refetch} />
          )}
      </CanopyFlex>
      <DataGridPro
        className="borderless"
        filterModel={filterModel}
        onFilterModelChange={handleFilterModelChange}
        loading={loading}
        columns={columns}
        rows={gridData.records}
        rowCount={gridData.totalCount}
        autoHeight
        getRowId={(row) => row.id}
        disableVirtualization={isTest()}
        hideFooterRowCount
        hideFooterSelectedRowCount
        filterMode="server"
        paginationMode="server"
        sortingMode="server"
        sortModel={options.sort}
        onSortModelChange={handleSortModelChange}
        components={{
          Toolbar: DataGridToolbar,
        }}
        componentsProps={{
          toolbar: { filter: true },
        }}
        pageSize={50}
        onPageChange={(page) => {
          handlePageChange(page + 1)
        }} // account for DataGrid's zero-based indexing
        //onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
        rowsPerPageOptions={[50]}
        pagination={true}
        density="compact"
      />
    </div>
  )
}

export default PackagesDataGrid
