import React from "react"
import { Event, Notice as NoticeType, Nullable } from "sharedTypes"
import ExternalLinks from "./ExternalLinks"
import Notice from "./utilities/Notice"
import { EventRawContent, PlainTextEventContent } from "./EventRawContent"
import ServiceValidationMessage from "../../applications/Workflow/containers/Sidebar/containers/PatientPortal/components/ServiceValidationMessage"

type Props = {
  event: Event
  notice?: Nullable<NoticeType>
  maxLength?: number
}

const innerContent = ({
  event,
  maxLength,
}: {
  event: Event
  maxLength?: number
}) => {
  if (event.data?.serviceValidation) {
    return (
      <ServiceValidationMessage
        serviceValidation={event.data.serviceValidation}
        supplierName={event.userEmployerName}
      />
    )
  } else if (event.rawContent) {
    return <EventRawContent event={event} maxLength={maxLength} />
  } else {
    return <PlainTextEventContent text={event.content} />
  }
}

const Content: React.FC<Props> = ({ event, notice, maxLength, children }) => {
  return (
    <>
      {event.outerContent && (
        <div className="outer-message-body">{event.outerContent}</div>
      )}
      {(event.content || event.rawContent) && (
        <div className="message-body">
          {innerContent({ event, maxLength })}{" "}
          <ExternalLinks links={event.externalLinks} />
          {children}
        </div>
      )}
      {notice && <Notice notice={notice} />}
    </>
  )
}

export default Content
