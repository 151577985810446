import React from "react"
import ContentArea from "../../../../../components/ContentArea"
import { CanopyRequiredFieldKey } from "@parachutehealth/canopy-required-field-key"
import { CanopyButton } from "@parachutehealth/canopy-button"
import Form from "../../../../../../../components/form/Form"
import { StaticFieldDefinition } from "../../../../../types/form/sharedTypes"
import FormSegment from "../../../../../components/Formik/FormSegment"
import { Divider } from "@material-ui/core"
import { booleanRadioGroup } from "../../../../../components/Formik/DynamicFormField"
import { getAllPackagingLabels } from "applications/Cms/api/packagingLabels"
import { getAllUnits } from "applications/Cms/api/units"
import { useQuery } from "@tanstack/react-query"
import { Link } from "react-router-dom"
import { attributesUrl } from "../../../../../urls/attributes"
import { FormikErrors } from "formik"
import {
  requiredFields,
  generateInitialValues,
} from "applications/Cms/utilities/form"

interface AttributeFormValues {
  selectionCaption?: string
  name: string
  valueType: string
  attributeAutoSelectionEnabled: boolean
  packagingLabelIds?: string[]
  unitIds?: string[]
}

type Props = {
  onSave: (values: AttributeFormValues, setErrors: () => {}) => void
}

const generalDetailStaticFields: StaticFieldDefinition[] = [
  { label: "Name", name: "name", type: "text", required: true },
  {
    label: "Attribute type",
    name: "valueType",
    type: "card",
    options: [
      {
        label: "Text",
        description: "An attribute that consists of freeform characters",
        value: "text",
        icon: "text",
      },
      {
        label: "Number",
        description: "An attribute that contains a number",
        value: "number",
        icon: "calculator-simple",
      },
    ],
    required: true,
  },
]

const additionalInformationStaticFields: StaticFieldDefinition[] = [
  {
    label: "Enable Attribute auto-selection on optional Products",
    name: "attributeAutoSelectionEnabled",
    type: "radio",
    options: booleanRadioGroup(),
    required: true,
    description:
      "By selecting 'yes', optional Products (Accessories) that contain this Attribute will auto-select this Attribute. Required Products will not be affected.",
  },
  {
    label: "Internal notes",
    name: "selectionCaption",
    type: "text",
    optional: true,
  },
]

const AttributeFormFields: React.FC<Props> = ({
  onSave,
}): React.JSX.Element => {
  const { data: packagingLabelOptions } = useQuery({
    queryKey: ["packagingLabelFetch"],
    queryFn: async () => {
      const results = await getAllPackagingLabels()
      return results.packagingLabels.map((result) => ({
        label: result.label,
        value: result.id.toString(),
      }))
    },
  })

  const { data: unitOptions } = useQuery({
    queryKey: ["unitFetch"],
    queryFn: async () => {
      const results = await getAllUnits()
      return results.units.map((result) => ({
        label: result.label,
        value: result.id.toString(),
      }))
    },
  })

  const attributeTypeSettingsFields: StaticFieldDefinition[] = [
    {
      label: "Applicable packaging label",
      description:
        "Select the ways this Attribute can be packaged (e.g. Box, Case)",
      name: "packagingLabelIds",
      type: "select",
      optional: true,
      options: packagingLabelOptions || [],
      multiple: true,
    },
    {
      label: "Applicable units of measure",
      description:
        "Select the units that this Attribute comes in (e.g. mL, inches)",
      name: "unitIds",
      type: "select",
      optional: true,
      options: unitOptions || [],
      multiple: true,
    },
  ]

  const validate = (values: AttributeFormValues) => {
    const errors: FormikErrors<AttributeFormValues> = {}

    const fieldNames = requiredFields(
      [generalDetailStaticFields, additionalInformationStaticFields].flat()
    ).map((field) => field.name)

    fieldNames.flat().forEach((fieldName) => {
      if (!values[fieldName]) {
        errors[fieldName] = "This field is required"
      }
    })
    return errors
  }

  const handleSubmit = async (values, { setErrors }) => {
    await onSave(values, setErrors)
  }

  const initialValues: Record<string, string> = generateInitialValues(
    [
      generalDetailStaticFields,
      additionalInformationStaticFields,
      attributeTypeSettingsFields,
    ].flat(),
    {}
  )

  return (
    <Form
      onSubmit={handleSubmit}
      validate={validate}
      initialValues={initialValues}
    >
      {({ values, isSubmitting }) => (
        <ContentArea variant="medium">
          <h3 className="canopy-typography-heading-large">General details</h3>
          <div className="canopy-mb-10x">
            <CanopyRequiredFieldKey />
          </div>
          <FormSegment>{generalDetailStaticFields}</FormSegment>
          {values.valueType === "number" && (
            <div>
              <Divider />
              <div className="canopy-pt-10x">
                <h3 className="canopy-typography-heading-large">
                  Attribute type settings
                </h3>

                <FormSegment>{attributeTypeSettingsFields}</FormSegment>
              </div>
            </div>
          )}
          <Divider />
          <div className="canopy-pt-10x">
            <h3 className="canopy-typography-heading-large">
              Additional information
            </h3>
            <FormSegment>{additionalInformationStaticFields}</FormSegment>
          </div>
          <CanopyButton size="small" variant="primary" type="submit">
            Save
          </CanopyButton>
          <CanopyButton
            className="canopy-mis-4x"
            size="small"
            variant="tertiary"
            disabled={isSubmitting}
            as={Link}
            to={attributesUrl()}
          >
            Cancel
          </CanopyButton>
        </ContentArea>
      )}
    </Form>
  )
}

export default AttributeFormFields
