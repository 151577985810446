import { post } from "services/api"
import { employerPrefix } from "utilities/url"

export type DmeOrder = {
  id: string
  clinicalFacility: {
    id: string
    name: string
  }
  supplier: {
    id: string
    name: string
  }
  orderStatus: string
  lineItemGroups: { description: string }[]
  createdAt: string
  requestedDeliveryDate: string
  supplierSystemIds: {
    orderId: string
    instanceId: string
  }[]
}

type DocumentationOrder = {
  clinician: {
    name: string
    npi: string
  }
  createdAt: string
  signingStatus: string
}

type ExternalOrder = {
  createdAt: string
  supplierSystemId: string
}

type OrderSearchResponse = {
  dmeOrders: DmeOrder[]
  documentationOrders: DocumentationOrder[]
  externalOrders: ExternalOrder[]
}

type ApiOrderSearchParameters = {
  masterPatientId?: string
  supplierSystemIds?: {
    supplierSystemPatientId: string
    supplierId: string
  }[]
}
type SearchOrdersParams = {
  supplierOrganizationId: string
  supplierId: string
  masterPatientId: string
  supplierSystemPatientIds: string[]
}
export const searchOrders = async ({
  supplierOrganizationId,
  supplierId,
  masterPatientId,
  supplierSystemPatientIds,
}: SearchOrdersParams): Promise<OrderSearchResponse> => {
  const prefix = employerPrefix("SupplierOrganization", supplierOrganizationId)
  const supplierSystemIds = (supplierSystemPatientIds ?? []).map(
    (supplierSystemPatientId) => ({
      supplierId,
      supplierSystemPatientId,
    })
  )
  const searchParams: ApiOrderSearchParameters = {
    masterPatientId,
    supplierSystemIds,
  }

  return post(
    `${prefix}/supplier_system_integrated_search/order_search.json`,
    searchParams
  ).then((response) => response.data)
}
