import { EmployerClassEnum } from "../../../../sharedTypes"

export const useEmployer = () => {
  const employerType = window?.parachute?.employerType

  const isSupplier = employerType === EmployerClassEnum.Supplier
  const isPayor = employerType === EmployerClassEnum.InsurancePayor

  return { currentEmployer: employerType, isSupplier, isPayor }
}
