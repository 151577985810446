import { employerPrefix } from "utilities/url"

export function dashboardUrl() {
  return `${employerPrefix()}/dashboard`
}

export function completeFacilityProfileUrl() {
  return `${employerPrefix()}/complete_profile`
}

export function updateFacilityNameUrl() {
  return `${employerPrefix()}/complete_profile/update_facility_name`
}

export function updateFacilityAddressUrl() {
  return `${employerPrefix()}/complete_profile/update_facility_address`
}

export function getFacilityProfileUrl() {
  return `${employerPrefix()}/complete_profile/facility_data`
}
