// @team @destroythefax
import React, { Dispatch, FC, SetStateAction, useState } from "react"
import OrderActionsDropdownButton from "../OrderActionsDropdown/OrderActionsDropdownButton"
import { UnsnoozeModal } from "components/ClinicalModals/UnsnoozeModal"

type Props = {
  unsnoozeDmeOrder: () => Promise<void>
  setOrderActionsDropdownOpen: Dispatch<SetStateAction<boolean>>
  removeClickOutsideDropdownMenuListener?(): void
  patientName: string
}

const UnsnoozeButton: FC<Props> = ({
  unsnoozeDmeOrder,
  setOrderActionsDropdownOpen,
  removeClickOutsideDropdownMenuListener,
  patientName,
}: Props) => {
  const [open, setOpen] = useState(false)

  const onClose = () => {
    setOpen(false)
    setOrderActionsDropdownOpen(false)
  }

  const onClick = () => {
    removeClickOutsideDropdownMenuListener?.()
    setOpen(true)
  }

  const handleUnsnooze = () => {
    unsnoozeDmeOrder().then(() => {
      onClose()
    })
  }

  return (
    <>
      <OrderActionsDropdownButton
        iconName="clock-rotate-left"
        onClick={onClick}
        subtitle="Remove snooze status from order"
        title="Unsnooze&nbsp;order"
      />
      <UnsnoozeModal
        isOpen={open}
        onClose={onClose}
        patientName={patientName}
        unsnoozeDmeOrder={handleUnsnooze}
      />
    </>
  )
}

export default UnsnoozeButton
