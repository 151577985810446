import React from "react"
import {
  CarrierLineItemAuthorization,
  Doctor,
} from "../../../../../graphql/__generated__/graphql"
import * as styles from "./AuthorizationRequestLineItem.module.scss"
import { CanopyButton } from "@parachutehealth/canopy-button"
import { LineItemDetail } from "./LineItemDetail/LineItemDetail"
import { LINE_ITEM_DETAILS } from "./constants/lineItemDetails"
import { isNullOrUndefined } from "../../../../../utilities/isNullOrUndefined"
import {
  AuthLineItemStatusToSeverity,
  AuthLineItemStatusToTitle,
} from "../../utilities/statusFormatters"
import AuthStatusPill from "../AuthStatusPill/AuthStatusPill"
import LineItemStatusBanner, {
  bannerStatuses,
} from "./LineItemStatusBanner/LineItemStatusBanner"

interface AuthorizationLineItemProps {
  lineItem: CarrierLineItemAuthorization
  dmeOrderId: string
  orderingPhysician: Doctor | undefined
  onEditClick: () => void
  hasEditAccess: boolean
}

export const AuthorizationRequestLineItemCard = ({
  lineItem,
  dmeOrderId,
  orderingPhysician,
  onEditClick,
  hasEditAccess,
}: AuthorizationLineItemProps) => {
  const lineItemHeading = `Prior auth line item: ${lineItem.hcpcs}`

  const showStatusBanner = bannerStatuses.includes(lineItem.decisionStatus)

  return (
    <div role="grid" className={styles.authCard}>
      <div className={styles.authCardHeader}>
        <h4 className="canopy-m-0 canopy-py-4x canopy-typography-heading-large flex-grow-1">
          {lineItemHeading}
        </h4>
        <AuthStatusPill
          statusLevel={AuthLineItemStatusToSeverity[lineItem.decisionStatus]}
          statusText={AuthLineItemStatusToTitle[lineItem.decisionStatus]}
        />
        {!!hasEditAccess && (
          <CanopyButton
            variant="secondary"
            size="small"
            onClick={() => onEditClick()}
            className="canopy-ml-4x"
          >
            Edit
          </CanopyButton>
        )}
      </div>
      <div className={styles.authCardBody}>
        {showStatusBanner && (
          <LineItemStatusBanner
            lineItem={lineItem}
            dmeOrderId={dmeOrderId}
            className="canopy-mb-8x"
          />
        )}
        {Object.keys(LINE_ITEM_DETAILS).map((keyName, index) => (
          <LineItemDetail
            key={index}
            title={LINE_ITEM_DETAILS[keyName].title}
            displayValue={
              (!!lineItem[keyName] &&
                LINE_ITEM_DETAILS[keyName].formatFn?.(lineItem[keyName])) ||
              lineItem[keyName]
            }
            requiredField={LINE_ITEM_DETAILS[keyName].required}
          />
        ))}

        <LineItemDetail
          title="Ordering physician"
          displayValue={
            !isNullOrUndefined(orderingPhysician)
              ? `${orderingPhysician?.name}${
                  orderingPhysician?.credential
                    ? `, ${orderingPhysician?.credential}`
                    : ""
                }`
              : orderingPhysician
          }
          requiredField={true}
        />
      </div>
    </div>
  )
}
