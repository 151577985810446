import React, { useState } from "react"
import { CircularProgress } from "@material-ui/core"
import { Form } from "components/form"
import { createProductCustomAttribute } from "applications/Cms/api/productCustomAttributes"
import { useFormikContext } from "formik"
import { CatalogProduct } from "applications/Cms/types/sharedTypes"
import AutocompleteField from "applications/Cms/components/AutocompleteField"
import { CanopyButton } from "@parachutehealth/canopy-button"
import { CanopyDialogModal } from "@parachutehealth/canopy-dialog-modal"
import { useQuery, useQueryClient } from "@tanstack/react-query"
import { getAvailableAttributes } from "./api"
import { CanopyLabel } from "@parachutehealth/canopy-label"
import { getCustomAttributeOptions } from "../../../../../api/attributeOptions"
import { CanopyFlex } from "@parachutehealth/canopy-flex"

export type Props = {
  onSave?: () => void
  product: CatalogProduct
  open?: boolean // for testing
}

type FormValues = {
  customAttribute: { id?: number; text: string }
  defaultAttributeValue: { id?: number; text: string }
}

const AddProductAttributeForm: React.FC<Props> = (
  props: Props
): React.JSX.Element => {
  const { product, open, onSave } = props

  const queryKey = ["getAvailableAttributes", product.externalId]

  const [dialogOpen, setDialogOpen] = useState<boolean>(!!open)
  const queryClient = useQueryClient()
  const { isFetching = true, data: attributeResponse } = useQuery({
    queryKey: queryKey,
    queryFn: () => getAvailableAttributes(product.externalId),
    refetchOnWindowFocus: false,
    enabled: dialogOpen,
  })

  const handleClickOpen = () => {
    setDialogOpen(true)
  }

  const handleClose = () => {
    setDialogOpen(false)
  }

  const handleSubmit = (
    params,
    { setSubmitting, setFieldError, resetForm }
  ) => {
    setSubmitting(true)
    createProductCustomAttribute(
      product.id,
      params.customAttribute,
      params.defaultAttributeValue
    ).then((response) => {
      if (!response.errors) {
        setDialogOpen(false)
        if (onSave) onSave()
        void queryClient.invalidateQueries({ queryKey: queryKey })
        resetForm()
      } else {
        setSubmitting(false)

        const customAttributeErr = response.errors.customAttributeId?.includes(
          "can't be blank"
        )
          ? "Field is required"
          : response.errors.customAttributeId
        setFieldError("customAttribute", customAttributeErr)

        const defaultCustomAttributeErr = response.errors.base?.includes(
          "You must provide either a description or a custom_attribute_option_value"
        )
          ? "Field is required when SKUs are present"
          : response.errors.defaultCustomAttributeOption
        setFieldError("defaultAttributeValue", defaultCustomAttributeErr)
      }
    })
  }

  const CustomAttributeAutocomplete = () => {
    const options =
      attributeResponse?.map((attr) => ({
        id: attr.id,
        text: attr.name,
      })) || []

    return (
      <>
        {/*
         * MUI autocompletes get visually hosed within CanopyFormField groups, so we're constructing our own here;
         * this is very suboptimal, and these MUI-based AutocompleteFields should be converted to Canopy ASAP
         */}
        <CanopyLabel
          htmlFor="addProductCustomAttributeField"
          required={true}
          className="canopy-mbe-4x"
        >
          Attribute
        </CanopyLabel>

        {/* Need to wrap this with a label to reproduce MUI behavior; TS doesn't think divs can have labels though */}
        {/* @ts-ignore */}
        <div label="addProductCustomAttributeField">
          <AutocompleteField
            id="addProductCustomAttributeField"
            data-testid="addProductCustomAttributeField"
            name="customAttribute"
            placeholder="Input an attribute"
            options={options}
            freeSolo={true}
          />
        </div>
      </>
    )
  }

  const DefaultAttributeValueField = () => {
    const { values } = useFormikContext<FormValues>()
    const customAttribute = values.customAttribute

    const { isFetching, data: customAttributeOptions } = useQuery({
      queryKey: ["defaultAttributeValueOptions", customAttribute],
      queryFn: () => fetchCustomAttributeOptions(customAttribute),
      refetchOnWindowFocus: false,
      enabled: Boolean(customAttribute?.id),
    })

    const fetchCustomAttributeOptions = async (customAttribute: {
      id?: number
    }): Promise<{ id: number; text: string }[]> => {
      if (!customAttribute || !customAttribute.id) return Promise.resolve([])

      const data = await getCustomAttributeOptions(customAttribute.id)
      return data.results
    }

    // TODO: make this smart enough to show a different set of inputs for numeric type attributes
    return (
      <>
        <CanopyLabel
          className="canopy-mbs-10x canopy-mbe-4x"
          htmlFor="addProductDefaultAttributeValueField"
        >
          Default
        </CanopyLabel>

        {isFetching && (
          <CanopyFlex className="canopy-m-4x" justifyContent="center">
            <CircularProgress size={32} color="inherit" />
          </CanopyFlex>
        )}

        {!isFetching && (
          /* Need to wrap this with a label to reproduce MUI behavior; TS doesn't think divs can have labels though */
          /* @ts-ignore */
          <div label="addProductDefaultAttributeValueField">
            <AutocompleteField
              id="addProductDefaultAttributeValueField"
              data-testid="addProductDefaultAttributeValueField"
              name="defaultAttributeValue"
              placeholder="Input a default attribute for existing SKUs"
              options={customAttributeOptions || []}
              freeSolo={true}
            />
          </div>
        )}
      </>
    )
  }

  return (
    <div>
      <CanopyButton size="small" variant="primary" onClick={handleClickOpen}>
        Add Attribute
      </CanopyButton>
      <Form
        initialValues={{
          customAttribute: null,
          defaultAttributeValue: null,
        }}
        onSubmit={handleSubmit}
      >
        {({ isSubmitting, submitForm, resetForm }) => (
          <>
            <CanopyDialogModal
              header="Add Attribute"
              open={dialogOpen}
              onClose={handleClose}
              style={{ width: "50%" }}
              primaryFooterButton={
                <CanopyButton
                  size="small"
                  onClick={submitForm}
                  variant="primary"
                  loading={isSubmitting}
                >
                  Add Attribute
                </CanopyButton>
              }
              secondaryFooterButton={
                <CanopyButton
                  size="small"
                  onClick={() => {
                    resetForm()
                    handleClose()
                  }}
                  variant="secondary"
                  disabled={isSubmitting}
                >
                  Cancel
                </CanopyButton>
              }
            >
              <>
                {isFetching && (
                  <CanopyFlex className="canopy-m-4x" justifyContent="center">
                    <CircularProgress color="inherit" />
                  </CanopyFlex>
                )}
                {!isFetching && (
                  <>
                    <CustomAttributeAutocomplete />

                    <DefaultAttributeValueField />
                  </>
                )}
              </>
            </CanopyDialogModal>
          </>
        )}
      </Form>
    </div>
  )
}
export default AddProductAttributeForm
