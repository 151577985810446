import { employerPrefix } from "utilities/url"

const BasePath = (clinicalFacilityId: string, pendingOrderId: string) =>
  `${employerPrefix(
    "ClinicalFacility",
    clinicalFacilityId
  )}/pending_orders/${pendingOrderId}`

export const DiagnosisPath = (
  clinicalFacilityId: string,
  pendingOrderId: string
) => `${BasePath(clinicalFacilityId, pendingOrderId)}/diagnosis`

export const TherapyPath = (
  clinicalFacilityId: string,
  pendingOrderId: string
) => `${BasePath(clinicalFacilityId, pendingOrderId)}/therapy_selections/new`
