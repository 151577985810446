import React from "react"
import { Route, Switch } from "react-router-dom"
import { RouteComponentProps } from "react-router"
import * as styles from "./index.module.scss"
import LLMResultJson from "./components/LLMResultJson"
import { SupplierIntakeNavbar } from "./components/SupplierIntakeNavbar"
import { Document } from "components/DocumentsBrowser/sharedTypes"
import { withBrowserRouter } from "routers/BrowserRouter"
import { inboxDashboardUrl, inboxDocumentUrl } from "./urls"
import { DataEntryColumn } from "./components/DataEntryColumn"
import { CsrInboxStateProvider } from "./csrInboxStateContext"
import { QueryClient, QueryClientProvider } from "@tanstack/react-query"
import { ErrorContextProvider } from "./error-context"
import { DocumentBrowserFrame } from "./components/DocumentBrowserFrame"
import { PatientSearch } from "./components/PatientSearch"
import { OrderHistory } from "./components/OrderHistory"
import { ExternalSystemBranchData } from "./components/DataEntryColumn/FacilityClinicianSection/ExternalSystemSelect"
import { OrderingClinicianData } from "./components/DataEntryColumn/ClinicianData"
import { Diagnosis as DiagnosisType } from "./components/DataEntryColumn/Diagnoses/api"
import {
  Insurance,
  LLMResult,
  Patient,
  PatientEmergencyContact,
  InternalStatus,
} from "./sharedTypes"
import { PatientAddress } from "./components/DataEntryColumn/ContactInformation/api"
import { PatientContactInformationProps } from "./components/DataEntryColumn/ContactInformation/PatientContactInformation/api"
import { InboxStateClinicalFacility } from "./components/DataEntryColumn/FacilityData"
import { setPatientQueryData } from "./queries"
import { Nullable } from "../../sharedTypes"
import { ROUTES } from "./routes"

type Props = {
  csrInboxState: {
    supplier: {
      id: string
      brightreeAcceptance: boolean
      supplierSystemInstanceId?: string
    }
    id: string
    supplierOrganization: { id: string }
    document: Document
    documentTitle: string
    clinician: OrderingClinicianData
    externalSystemBranch: ExternalSystemBranchData
    diagnoses: DiagnosisType[]
    insurances: Insurance[]
    patient: Patient
    patientAddress: PatientAddress
    patientContactInformation: PatientContactInformationProps
    patientEmergencyContact: PatientEmergencyContact
    facility: InboxStateClinicalFacility
    llmResult: Nullable<LLMResult>
    internalStatus?: InternalStatus
  }
  livePilotEnabled: boolean
  internalStatuses: InternalStatus[]
} & RouteComponentProps

const SupplierOrganizationInboxDocument = ({
  csrInboxState: {
    id,
    supplier,
    supplierOrganization,
    document,
    documentTitle,
    internalStatus,
    ...propsForThePage
  },
  internalStatuses,
}: Props) => {
  return (
    <>
      <SupplierIntakeNavbar
        dashboardUrl={inboxDashboardUrl(supplierOrganization.id, supplier.id)}
        documentTitle={documentTitle}
        internalStatuses={internalStatuses}
        internalStatus={internalStatus}
      />
      <div className={styles.columnContainer}>
        <div className={styles.documentColumn}>
          <DocumentBrowserFrame document={document} />
        </div>
        <div className={styles.dataEntryColumn}>
          <Switch>
            <Route path={ROUTES.JSON_BLOB}>
              <LLMResultJson {...propsForThePage} />
            </Route>
            <Route path={ROUTES.PATIENT_SEARCH}>
              <PatientSearch
                {...propsForThePage}
                supplier={supplier}
                csrInboxStateId={id}
                supplierOrganizationId={supplierOrganization.id}
              />
            </Route>
            <Route path={ROUTES.ORDER_HISTORY}>
              <OrderHistory />
            </Route>
            <Route path={ROUTES.NEW_ORDER}>
              <DataEntryColumn {...propsForThePage} />
            </Route>
          </Switch>
        </div>
      </div>
    </>
  )
}

const SupplierOrganizationInboxDocumentWithProviders = (props) => {
  const {
    livePilotEnabled,
    csrInboxState: { id, supplier, supplierOrganization, patient },
  } = props
  const queryClient: QueryClient = new QueryClient()
  setPatientQueryData(queryClient, patient)
  const basename = inboxDocumentUrl(supplierOrganization.id, id)

  // Ensure routing happens after queryClient initialization
  // so that there's no re-initialization after re-render
  const RoutedInboxDocument = withBrowserRouter(
    SupplierOrganizationInboxDocument,
    { basename }
  )
  return (
    <QueryClientProvider client={queryClient}>
      <ErrorContextProvider>
        <CsrInboxStateProvider
          id={id}
          supplierOrganization={supplierOrganization}
          supplier={supplier}
          livePilotEnabled={livePilotEnabled}
        >
          <RoutedInboxDocument {...props} />
        </CsrInboxStateProvider>
      </ErrorContextProvider>
    </QueryClientProvider>
  )
}

export default SupplierOrganizationInboxDocumentWithProviders
