import React, { useContext, useEffect } from "react"
import { RouterChildContext, useHistory, useParams } from "react-router-dom"
import {
  CatalogCustomAttribute,
  CatalogCustomAttributeOption,
  CatalogProduct,
} from "../../../../types/sharedTypes"
import { isNullOrUndefined } from "../../../../../../utilities/isNullOrUndefined"
import {
  Backdrop,
  CircularProgress,
  List,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip,
} from "@material-ui/core"
import { NoticeContext } from "../../../../contexts/NoticeContext"
import {
  destroyCatalogAttribute,
  getCatalogAttribute,
} from "../../../../api/attributes"
import SidebarLayout from "../../../../components/SidebarLayout"
import {
  archiveAttributeOption,
  getProductsForAttributeOption,
  rearrangeAttributeOptions,
} from "../../../../api/attributeOptions"
import { DndProvider } from "react-dnd"
import { HTML5Backend } from "react-dnd-html5-backend"
import RowContainer from "../../../../components/RowContainer"
import { DragIndicator } from "@material-ui/icons"
import { attributesUrl } from "../../../../urls/attributes"
import Breadcrumbs from "../../../../components/Breadcrumbs"
import DropdownMenu from "../../../../components/DropdownMenu"
import EditAttributeDrawer from "../EditAttributeDrawer"
import ConfirmDialog from "../../../../components/ConfirmDialog"
import EditAttributeOptionDrawer from "../EditAttributeOptionDrawer"
import NoRows from "../../../../components/DataGrid/NoRows"
import { CanopyButton } from "@parachutehealth/canopy-button"
import CmsDrawer from "../../../../components/CmsDrawer"
import StyledTable from "../../../../components/StyledTable"
import classNames from "classnames"
import * as styles from "./index.module.scss"
import { useFeatureFlags } from "components/FeatureFlagContext"
import { isNonBlankString } from "utilities/isNonBlankString"
import { CanopyIcon } from "@parachutehealth/canopy-icon"
import { CanopyText } from "@parachutehealth/canopy-text"

type InternalAttributeDetailsPageProps = {
  catalogAttribute: CatalogCustomAttribute
  reload: () => void
}

const InternalAttributeDetailsPage: React.FC<InternalAttributeDetailsPageProps> = (
  props: InternalAttributeDetailsPageProps
): React.JSX.Element => {
  const history: RouterChildContext["router"]["history"] = useHistory()
  const { showNotice } = useContext(NoticeContext)
  const { reload, catalogAttribute } = props

  const [tableRows, setTableRows] = React.useState<
    CatalogCustomAttributeOption[]
  >([...(catalogAttribute.options || [])])

  const [destroyDialogOpen, setDestroyDialogOpen] = React.useState<boolean>(
    false
  )
  const [editDrawerOpen, setEditDrawerOpen] = React.useState<boolean>(false)

  const { isFeatureEnabled } = useFeatureFlags()

  const isAttrEnhancementFlagOn = isFeatureEnabled(
    "cmsAttributeEnhancementsRefresh"
  )

  const [
    attributeOptionSelectedForDeletion,
    setAttributeOptionSelectedForDeletion,
  ] = React.useState<CatalogCustomAttributeOption | undefined>(undefined)

  const [
    attributeOptionSelectedForEdit,
    setAttributeOptionSelectedForEdit,
  ] = React.useState<CatalogCustomAttributeOption | undefined>(undefined)

  useEffect(() => {
    document.title = `Attribute: ${catalogAttribute.name}`
  })

  const OpenContent = (): React.ReactNode[] => {
    const contentNodes: React.ReactNode[] = []
    const InternalNotes = (): React.JSX.Element => {
      if (!isNonBlankString(catalogAttribute.selectionCaption)) {
        return <></>
      } else {
        return (
          <div
            className={`${classNames(
              styles.internalNotesContainer
            )} canopy-p-6x`}
          >
            <div>
              <CanopyIcon
                fill="canopyColorPrimitivesBlue41"
                name="flag"
                size="small"
              />
              <CanopyText
                size="small"
                weight="bold"
                inline
                className="canopy-mis-2x"
              >
                Internal Note
              </CanopyText>
            </div>

            <CanopyText size="small">
              {catalogAttribute.selectionCaption}
            </CanopyText>
          </div>
        )
      }
    }

    const formatAttributeData = (catalogAttribute, key) => {
      if (catalogAttribute.valueType === "text") {
        return "N/A"
      }

      const labels = catalogAttribute.options
        ?.map((option) => option[key]?.label)
        .filter((label) => label)

      return labels && labels.length > 0
        ? [...new Set(labels)].join(", ")
        : "N/A"
    }

    const items: { [key: string]: React.JSX.Element } = {
      Values: <>{catalogAttribute.options?.length || 0}</>,
      Type: <>{catalogAttribute.valueType}</>,
      ...(catalogAttribute.valueType === "number"
        ? {
            "Applicable packaging": (
              <>{formatAttributeData(catalogAttribute, "packagingLabel")}</>
            ),
            "Applicable units of measure": (
              <>{formatAttributeData(catalogAttribute, "unit")}</>
            ),
          }
        : {}),
    }

    contentNodes.push(<InternalNotes />)
    contentNodes.push(
      <div key="usage-details">
        <CanopyText size="small" weight="bold">
          Details
        </CanopyText>
        <CanopyText size="small">
          <ul className="list-unstyled canopy-mb-0">
            {Object.keys(items).map((key) => {
              return (
                <li key={key} className="canopy-my-0">
                  {key}: <strong>{items[key]}</strong>
                </li>
              )
            })}
          </ul>
        </CanopyText>
      </div>
    )
    return contentNodes
  }

  const performAttributeDestroy = async () => {
    const response = await destroyCatalogAttribute(catalogAttribute.externalId)

    if (response === true) {
      history.push(attributesUrl())
      showNotice(
        `Successfully removed the attribute ${catalogAttribute.name}`,
        "success",
        [],
        true
      )
    } else {
      setDestroyDialogOpen(false)
      showNotice(
        `Failed to delete attribute: ${
          response["errors"] || "unknown error occurred"
        }`,
        "error"
      )
    }
  }

  const performAttributeOptionArchive = async () => {
    if (!attributeOptionSelectedForDeletion) {
      showNotice("No option is selected", "error")
      return
    }

    const success = await archiveAttributeOption(
      catalogAttribute.externalId,
      attributeOptionSelectedForDeletion.externalId
    )
    if (success) {
      showNotice("You've successfully archived that option", "success")
      reload()
    } else {
      showNotice("An error occurred while archiving that option", "error")
    }
  }

  const [productDrawerTarget, setProductDrawerTarget] = React.useState<
    CatalogCustomAttributeOption | undefined
  >(undefined)

  const ProductsDrawer = ({
    option,
  }: {
    option: CatalogCustomAttributeOption
  }): React.JSX.Element => {
    const [products, setProducts] = React.useState<CatalogProduct[]>([])
    const [loading, setLoading] = React.useState<boolean>(products.length === 0)

    React.useEffect(() => {
      getProductsForAttributeOption(
        catalogAttribute.externalId,
        option.externalId
      )
        .then((data) => setProducts(data))
        .finally(() => setLoading(false))
    }, [option])

    const onClose = () => {
      setProducts([])
      setProductDrawerTarget(undefined)
    }

    return (
      <CmsDrawer
        open={true}
        title={`Products using "${option.description}" value`}
        onClose={onClose}
      >
        {loading && (
          <div style={{ textAlign: "center" }}>
            <CircularProgress title="Loading..." color="primary" size={64} />
          </div>
        )}
        {!loading && (
          <StyledTable>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell
                    className="canopy-typography-body-large"
                    component="th"
                    scope="col"
                  >
                    Product
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {products.map((product) => {
                  return (
                    <TableRow key={product.id}>
                      <TableCell>
                        <a href={product.url}>{product.name}</a>
                      </TableCell>
                    </TableRow>
                  )
                })}
              </TableBody>
            </Table>
          </StyledTable>
        )}
      </CmsDrawer>
    )
  }

  const AttributeOptionsTable = (): React.JSX.Element => {
    const handleRowDrop = async (): Promise<void> => {
      const success: boolean = await rearrangeAttributeOptions(
        catalogAttribute.externalId,
        tableRows.map((x) => x.externalId)
      )
      if (!success) {
        showNotice(
          "An error occurred while attempting to reorder the options",
          "error"
        )
      }
    }
    const handleRowMove = (fromIndex: number, toIndex: number) => {
      const row = tableRows.at(fromIndex)
      const newTableRows = [...tableRows]
      newTableRows.splice(fromIndex, 1)
      newTableRows.splice(toIndex, 0, row!)
      setTableRows(newTableRows)
    }

    const productLink = (option: CatalogCustomAttributeOption) => {
      if (isNullOrUndefined(option.productCount) || option.productCount === 0) {
        return "-"
      }

      return (
        <a
          href=""
          onClick={(e) => {
            e.preventDefault()
            setProductDrawerTarget(option)
          }}
        >
          {option.productCount}
        </a>
      )
    }

    const attrTextValuePermissions =
      isAttrEnhancementFlagOn || catalogAttribute.valueType === "text"

    return (
      <TableContainer>
        <Table size="medium">
          <TableHead>
            <TableRow>
              <TableCell component="th"></TableCell>
              {[
                "Value",
                "Products",
                "Patient Preference",
                "External ID",
                "Actions",
              ].map((s) => {
                return (
                  <TableCell key={s} component="th" scope="col">
                    <b>{s}</b>
                  </TableCell>
                )
              })}
            </TableRow>
          </TableHead>
          <TableBody>
            {tableRows.length === 0 && (
              <TableRow>
                <TableCell className="canopy-pin-0" colSpan={6}>
                  <NoRows
                    message="There are currently no options for this attribute."
                    dataGrid={false}
                  />
                </TableCell>
              </TableRow>
            )}
            {tableRows.map((row, index) => (
              <RowContainer
                key={row.id}
                row={row}
                index={index}
                rearrangeable={true}
                onMove={handleRowMove}
                onDrop={handleRowDrop}
                icon={DragIndicator}
              >
                <TableCell>{row.description}</TableCell>
                <TableCell>{productLink(row)}</TableCell>
                <TableCell>{row.patientPreference ? "Yes" : "No"}</TableCell>
                <TableCell>{row.externalId}</TableCell>
                <TableCell>
                  {row.permissions?.edit && attrTextValuePermissions && (
                    <a
                      role="button"
                      className="color-primary"
                      onClick={() => setAttributeOptionSelectedForEdit(row)}
                    >
                      Edit
                    </a>
                  )}

                  {row.permissions?.edit &&
                    attrTextValuePermissions &&
                    row.permissions?.destroy && (
                      <b
                        role="presentation"
                        className="canopy-min-4x"
                        aria-hidden="true"
                      >
                        |
                      </b>
                    )}

                  {row.permissions?.destroy && (
                    <a
                      role="button"
                      className={classNames("color-danger", {
                        [styles.disabledLink]: (row.productCount ?? 0) > 0,
                      })}
                      onClick={() => {
                        if (row.productCount === 0) {
                          setAttributeOptionSelectedForDeletion(row)
                        }
                      }}
                    >
                      {(row.productCount ?? 0) > 0 ? (
                        <Tooltip title="Cannot delete this attribute value because it is associated with a product.">
                          <span>Delete</span>
                        </Tooltip>
                      ) : (
                        "Delete"
                      )}
                    </a>
                  )}
                </TableCell>
              </RowContainer>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    )
  }

  const CustomAttributeTable = (): React.JSX.Element => {
    return <AttributeOptionsTable />
  }
  const ClosedContent: React.JSX.Element = <List></List>

  const HeaderContent: React.JSX.Element = (
    <>
      <Breadcrumbs>
        {[
          { label: "All Attributes", to: attributesUrl() },
          { label: catalogAttribute.name },
        ]}
      </Breadcrumbs>
      <div className={classNames(styles.headerContainer)}>
        <h1 className="canopy-typography-heading-2xlarge canopy-mt-4x canopy-mbe-4x">
          {catalogAttribute.name}
        </h1>
        <div
          className={classNames(styles.rightHeader)}
          data-testid="actions-bar"
        >
          {catalogAttribute.permissions?.edit && (
            <CanopyButton
              // as={Link} //To link to new edit page in WF-27116
              // to={attributeEditUrl(catalogAttribute.externalId)} //To link to new edit page in WF-27116
              onClick={() => setEditDrawerOpen(true)} // this is for now; this button will be updated to link to the new edit page in WF-27116
              variant="secondary"
              size="small"
              iconStart="pencil"
              disabled={!Boolean(catalogAttribute.permissions?.edit)}
            >
              Edit Attribute
            </CanopyButton>
          )}
          <div>
            <DropdownMenu
              label="More actions"
              id="actions-menu"
              className="canopy-mis-4x"
            >
              {[
                {
                  label: "Audit log",
                  ifTrue: Boolean(catalogAttribute.auditLogUrl),
                  href: catalogAttribute.auditLogUrl,
                },
                {
                  label: "Delete",
                  variant: "danger",
                  disabled: () =>
                    Boolean(
                      catalogAttribute.options?.find(
                        (o) => o.productCount || 0 > 0
                      )
                    ),
                  disabledMessage:
                    "This attribute cannot be deleted because its options are in use",
                  ifTrue: Boolean(catalogAttribute.permissions?.destroy),
                  onClick: () => setDestroyDialogOpen(true),
                },
              ]}
            </DropdownMenu>
          </div>
          <ConfirmDialog
            title="Confirm Action"
            message="Deleting this value will make it unavailable for any Attribute to use."
            cancelButtonText="Cancel"
            confirmButtonText="Yes"
            open={destroyDialogOpen}
            onConfirm={performAttributeDestroy}
            onCancel={() => setDestroyDialogOpen(false)}
            handleClose={() => setDestroyDialogOpen(false)}
          />
        </div>
      </div>
    </>
  )

  const MainContent = (): React.JSX.Element => {
    return (
      <div className="canopy-p-4x">
        <h2 className="canopy-typography-heading-large canopy-mbe-4x">
          Attribute values
        </h2>
        <div>
          This attribute contains{" "}
          <b>{catalogAttribute?.options?.length} values</b>
        </div>
        <div className={styles.mainContent}>
          <>
            {productDrawerTarget && (
              <ProductsDrawer option={productDrawerTarget} />
            )}
          </>
        </div>
        {editDrawerOpen && (
          <EditAttributeDrawer
            customAttribute={catalogAttribute}
            onClose={() => setEditDrawerOpen(false)}
            open={editDrawerOpen}
            onSave={reload}
          />
        )}
        {attributeOptionSelectedForEdit && (
          <EditAttributeOptionDrawer
            customAttribute={catalogAttribute}
            open={Boolean(attributeOptionSelectedForEdit)}
            customAttributeOption={attributeOptionSelectedForEdit!}
            onClose={() => setAttributeOptionSelectedForEdit(undefined)}
            onSave={reload}
          />
        )}
        <ConfirmDialog
          title="Remove Attribute Value"
          message="Are you sure you want to remove this attribute value?"
          confirmButtonText="Yes, I'm sure"
          cancelButtonText="Cancel"
          open={!isNullOrUndefined(attributeOptionSelectedForDeletion)}
          onConfirm={performAttributeOptionArchive}
          onCancel={() => setAttributeOptionSelectedForDeletion(undefined)}
          handleClose={() => setAttributeOptionSelectedForDeletion(undefined)}
        />
        <CustomAttributeTable />
      </div>
    )
  }

  return (
    <>
      <SidebarLayout
        openContent={OpenContent()}
        mainContent={<MainContent />}
        closedContent={ClosedContent}
        headerContent={HeaderContent}
        sidebarTitle="Attribute Details"
      />
    </>
  )
}

const AttributeDetailsPage: React.FC = () => {
  const { attributeId } = useParams()

  const [catalogAttribute, setCatalogAttribute] = React.useState<
    CatalogCustomAttribute | undefined
  >()

  const [loading, setLoading] = React.useState<boolean>(
    isNullOrUndefined(catalogAttribute)
  )

  const loadAttribute = async (attributeId: string) => {
    await getCatalogAttribute(attributeId)
      .then((attr) => {
        setCatalogAttribute(attr)
      })
      .finally(() => setLoading(false))
  }

  const reload = () => {
    setLoading(true)
    setCatalogAttribute(undefined)
    void loadAttribute(attributeId)
  }

  useEffect(() => {
    if (!catalogAttribute) {
      void loadAttribute(attributeId)
    }
  }, [attributeId, catalogAttribute])

  if (loading) {
    return (
      <Backdrop style={{ zIndex: 999 }} open={loading}>
        <CircularProgress color="inherit" />
      </Backdrop>
    )
  } else {
    return (
      <DndProvider backend={HTML5Backend}>
        <InternalAttributeDetailsPage
          reload={reload}
          catalogAttribute={catalogAttribute!}
        />
      </DndProvider>
    )
  }
}

export default AttributeDetailsPage
