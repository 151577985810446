import React from "react"
import * as styles from "./ActionableOrderCard.module.scss"
import {
  employerHomeUrlWithCanceledOrderId,
  workflowsUrl,
} from "../../../../Workflow/urls"
import { Follower, OrderStatus, Originator, SignatureStatus } from "sharedTypes"
import StatusChip from "./StatusChip"
import classNames from "classnames"
import FollowersSection from "./FollowersSection"
import BottomSection from "./BottomSection"
import LastComment from "./LastComment"
import { DashboardEvent } from "../../../sharedTypes"
import Supplier from "./Supplier"
import NotSelected from "./NotSelected"
import {
  snoozeOrder,
  unsnoozeOrder,
  updateFollowers,
} from "components/ClinicalModals/api"
import { cancelOrder } from "components/ClinicalModals/api"
import { navigate } from "../../../../../utilities/navigation"

interface Props {
  dmeOrder: {
    externalId: string
    patient: {
      dateOfBirth: string
      firstName: string
      lastName: string
      mrn: string
      sex: string
    }
    signingClinician: string
    followers: {
      id: string
      name: string
      initials: string
    }[]
    modalFollowers: Follower[]
    facilityFollowers: {
      name: string
      initials: string
      id: string
    }[]
    orderStatus: OrderStatus
    signatureStatus: SignatureStatus
    categories: string[]
    relevantSuppliers: { name: string }[]
    lastComment: DashboardEvent | null
    snooze?: {
      snoozedUntil: string
    }
    cardStatuses?: { type?: string; text: string }[]
    unsatisfiedManualDocumentation: boolean
    permissions: {
      update: boolean
    }
    maxSnoozeDate: string
    originator: Originator
  }
  unreadEventCount: number
  fetchData: () => void
  width: number
}

const ActionableOrderCard: React.FC<Props> = ({
  dmeOrder,
  dmeOrder: {
    patient,
    externalId,
    lastComment,
    cardStatuses,
    signatureStatus,
    orderStatus,
    unsatisfiedManualDocumentation,
    relevantSuppliers,
    permissions,
    modalFollowers,
  },
  fetchData,
  unreadEventCount,
  width,
}) => {
  const fulfillmentStatusWraps = width > 390
  const stackChips = width < 317

  const patientName = patient.firstName + " " + patient.lastName
  const patientInfo = [patient.dateOfBirth, patient.sex, patient.mrn]
    .filter((info) => info)
    .join(" · ")

  const signingClinician = dmeOrder.signingClinician || <NotSelected />
  const productType = (dmeOrder.categories.length &&
    dmeOrder.categories.join(" · ")) || <NotSelected />

  const PatientAndOrderStatus = () => (
    <>
      {fulfillmentStatusWraps ? (
        <div className="d-flex justify-content-between canopy-mbe-8x">
          <div className="flex-column">
            <h3 className={styles.patientName}>{patientName}</h3>
            <span className={styles.patientInfo}>{patientInfo}</span>
          </div>
          <div className="flex-column">
            {cardStatuses &&
              cardStatuses.map((cardStatus) => (
                <StatusChip
                  cardStatus={cardStatus}
                  classes={{ label: styles.chipLabelOverride }}
                  key={`${externalId}-${cardStatus.text}`}
                />
              ))}
          </div>
        </div>
      ) : (
        <>
          <div className="canopy-mbe-4x">
            {cardStatuses &&
              cardStatuses.map((cardStatus) => (
                <StatusChip
                  cardStatus={cardStatus}
                  classes={{
                    label: classNames(
                      styles.chipLabelOverride,
                      styles.truncate
                    ),
                  }}
                  key={`${externalId}-${cardStatus.text}`}
                />
              ))}
          </div>
          <h3 className={styles.patientName}>{patientName}</h3>
          <span className={styles.patientInfo}>{patientInfo}</span>
        </>
      )}
    </>
  )

  const syncFollowers = async (params) => {
    await updateFollowers(params, dmeOrder.externalId)
    fetchData()
  }

  const snoozeDmeOrder = async (params) => {
    await snoozeOrder(params, dmeOrder.externalId)
    fetchData()
  }

  const unsnoozeDmeOrder = async () => {
    await unsnoozeOrder(dmeOrder.externalId)
    fetchData()
  }

  const cancelDmeOrder: (
    cancelReason?: string,
    cancelComment?: string
  ) => Promise<void> = async (cancelReason, cancelComment) => {
    await cancelOrder(cancelReason, cancelComment, dmeOrder.externalId)
    navigate(employerHomeUrlWithCanceledOrderId(dmeOrder.externalId))
  }

  return (
    <a
      className={styles.linkContainer}
      href={workflowsUrl(externalId)}
      data-testid="dashboard-actionable-order-card"
    >
      {unreadEventCount > 0 && (
        <div className={styles.commentBadge}>{unreadEventCount}</div>
      )}
      <div className={styles.card}>
        <PatientAndOrderStatus />
        <div className={styles.bottomCardRow}>
          <div>
            <h4 className={styles.detailHeader}>SIGNING CLINICIAN</h4>
            <span className={styles.detailContent}>{signingClinician}</span>
          </div>
          <div className="flex-1">
            <h4 className={styles.detailHeader}>PRODUCT TYPE</h4>
            <span className={styles.detailContent}>{productType}</span>
          </div>
        </div>
        <div className={classNames(styles.bottomCardRow)}>
          <Supplier relevantSuppliers={relevantSuppliers} />
          <FollowersSection dmeOrderFollowers={dmeOrder.facilityFollowers} />
        </div>
        <LastComment
          lastComment={lastComment}
          unreadEventCount={unreadEventCount}
        />
        <BottomSection
          signatureStatus={signatureStatus}
          orderStatus={orderStatus}
          unsatisfiedManualDocumentation={unsatisfiedManualDocumentation}
          stackChips={stackChips}
          actionsDropdownPermission={permissions?.update}
          modalFollowers={modalFollowers}
          dmeOrder={dmeOrder}
          syncFollowers={syncFollowers}
          snoozeDmeOrder={snoozeDmeOrder}
          unsnoozeDmeOrder={unsnoozeDmeOrder}
          cancelDmeOrder={cancelDmeOrder}
        />
      </div>
    </a>
  )
}

export default ActionableOrderCard
