import React from "react"
import { unique } from "utilities/array"
import { DocumentationRequirement, DmeOrderCreatedVia } from "sharedTypes"
import {
  SendBackRecipient,
  SendBackFormValues,
  SelectedRecipient,
  SupplierFaxBackDocument,
  SendBackFormKeys,
} from "./sharedTypes"
import { Form, Select, TextArea } from "components/form"
import SendBackRecipientsSelector from "./SendBackRecipientsSelector"
import SupplierFollowablesSelector from "./SupplierFollowablesSelector"
import FollowUpOwnerSelector from "./FollowUpOwnerSelector"
import DocumentationRequirementsSelection from "./DocumentationRequirementsSelection"
import { validateSendBack } from "./utilities"
import { GroupedSendBackRecipients } from "applications/Workflow/containers/Review/components/SupplierSendBack/SupplierSendBackForm"
import { FacilitySelectProps } from "applications/FacilitySelectForm/components/FacilitySelect"
import LabelSubtext from "./LabelSubtext"
import { SupplierFaxDocumentSelector } from "./SupplierFaxDocumentSelector"

type Props = {
  cancel(): void
  documentationRequirements: DocumentationRequirement[]
  facilityEmail: string
  searchFacilityRecipients(
    term: string
  ): Promise<SendBackRecipient[] | GroupedSendBackRecipients[]>
  searchSupplierFollowables(term: string): Promise<SendBackRecipient[]>
  supplierFaxBackDocuments: SupplierFaxBackDocument[]
  supplierSendBack(
    values: SendBackFormValues,
    setErrors: (errors: string) => void
  ): void
  faxNumbers: string[]
  defaultFacilityRecipient: SendBackRecipient
  defaultSupplierRecipient: SendBackRecipient
  defaultSupplierTeamRecipients: SendBackRecipient[]
  hasFacilityRecipients: boolean
  isNonParachuteFacility: boolean
  selectedFacilityName: string
  setSearchRecipientRecommendations: (recommendationIds: string[]) => void
  selectedRecipients: SelectedRecipient[]
  createdVia: DmeOrderCreatedVia
  facilitySelectProps: FacilitySelectProps
}

const SupplierSendBackReasons = [
  "Missing documentation",
  "Product or service not available",
  "Discharge date has changed",
  "Waiting on patient info",
  "New diagnosis code(s) needed",
  "Other",
]

function SupplierSendBackFormSection(props: Props) {
  const {
    cancel,
    documentationRequirements,
    facilityEmail,
    searchFacilityRecipients,
    searchSupplierFollowables,
    supplierFaxBackDocuments,
    supplierSendBack,
    faxNumbers,
    defaultFacilityRecipient,
    defaultSupplierRecipient,
    defaultSupplierTeamRecipients,
    hasFacilityRecipients,
    setSearchRecipientRecommendations,
  } = props

  const buildInitialRecipients = () => {
    const recipients: SelectedRecipient[] = []

    if (defaultFacilityRecipient) {
      recipients.push({
        channel: "followable",
        destination: defaultFacilityRecipient.value,
      })
    }

    if (!hasFacilityRecipients && facilityEmail) {
      recipients.push({ channel: "email", destination: facilityEmail })
    }
    faxNumbers.forEach((faxNumber) =>
      recipients.push({ channel: "fax", destination: faxNumber })
    )

    return recipients
  }

  const hasInvitations = () => {
    return faxNumbers.length || facilityEmail
  }

  const initialSupplierFollowables = () => {
    if (!hasFacilityRecipients || hasInvitations()) {
      return defaultSupplierRecipientFollowables()
    }
    if (defaultSupplierRecipient) {
      return [defaultSupplierRecipient]
    }
    return []
  }

  const defaultSupplierRecipientFollowables = () => {
    return [
      ...[defaultSupplierRecipient],
      ...defaultSupplierTeamRecipients,
    ].filter((r) => r)
  }

  const initialValues: SendBackFormValues = {
    instructions: "",
    followUpOwner: "",
    reasons: [],
    recipients: buildInitialRecipients(),
    supplierFollowables: initialSupplierFollowables().map(({ value }) => value),
    requestedDocumentationRequirementIds: documentationRequirements
      .filter((docReq) => docReq.requested)
      .map((docReq) => docReq.externalId),
    supplierFaxBackDocumentIds: [],
  }
  const renderSubmitButton = ({ disabled }) => {
    return (
      <button type="submit" className="btn btn-brand" disabled={disabled}>
        Push to Facility
      </button>
    )
  }
  const onSubmit = (values: SendBackFormValues, { setErrors }) => {
    supplierSendBack(values, setErrors)
  }

  const documentationRequirementInformation = (values) => {
    const currentDocumentationRequirements = documentationRequirements.map(
      (dr) => ({
        ...dr,
        requested: values.requestedDocumentationRequirementIds.includes(
          dr.externalId
        ),
      })
    )
    return (
      <DocumentationRequirementsSelection
        documentationRequirements={currentDocumentationRequirements}
      />
    )
  }

  return (
    <Form
      initialValues={initialValues}
      onSubmit={onSubmit}
      validate={validateSendBack}
      validateOnMount
    >
      {({ values, isValid, setFieldValue }) => {
        const setDefaultSupplierFollowables = () => {
          if (defaultSupplierTeamRecipients.length) {
            setFieldValue(
              "supplierFollowables",
              unique([
                ...values.supplierFollowables.filter(
                  (recipient) => recipient !== null
                ),
                ...defaultSupplierTeamRecipients.map(({ value }) => value),
              ])
            )
          }
        }

        const hasSmsRecipients = () => {
          return values.recipients.some((r) => r.channel?.toString() === "sms")
        }

        return (
          <>
            <SendBackRecipientsSelector
              setFieldValue={setFieldValue}
              defaultFacilityRecipient={defaultFacilityRecipient}
              searchFacilityRecipients={searchFacilityRecipients}
              hasFacilityRecipients={hasFacilityRecipients}
              showChannelSelector
              setDefaultSupplierFollowables={setDefaultSupplierFollowables}
              setSearchRecipientRecommendations={
                setSearchRecipientRecommendations
              }
              selectedRecipients={values.recipients}
            />
            <div className="row gutter-10">
              <div className="col-md-12">
                <Select
                  label="Pushback Reason(s)"
                  placeholder="Select reason(s) for push back"
                  options={SupplierSendBackReasons.map((r) => ({
                    label: r,
                    value: r,
                  }))}
                  name={SendBackFormKeys.reasons}
                  isMulti
                  closeMenuOnSelect={false}
                />
              </div>
            </div>
            {documentationRequirementInformation(values)}
            <hr />
            <SupplierFaxDocumentSelector
              supplierFaxBackDocuments={supplierFaxBackDocuments}
              selectedRecipients={values.recipients}
            />
            <TextArea
              label={
                <>
                  Note for Facility (Optional)
                  <LabelSubtext>
                    Your note will be seen by clinicians in Parachute and on fax
                    coversheets
                  </LabelSubtext>
                </>
              }
              placeholder="Add a note"
              name={SendBackFormKeys.instructions}
              rows={3}
            />
            <FollowUpOwnerSelector search={searchSupplierFollowables} />
            <SupplierFollowablesSelector
              supplierFollowables={defaultSupplierRecipientFollowables()}
              search={searchSupplierFollowables}
            />
            {hasSmsRecipients() && (
              <div className="canopy-mbe-12x font-subparagraph">
                A link to order information will be sent via SMS text message.
                By entering this phone number, you confirm that you have
                permission to send text messages to the phone number entered and
                that the recipient consents to receive such messages. Message &
                data rates may apply. Mobile Carriers are not liable for delayed
                or undelivered messages.{" "}
                <a
                  className="color-info"
                  href="https://www.parachutehealth.com/privacy-policy"
                  target="_blank"
                  rel="noreferrer"
                >
                  View Privacy Policy
                </a>
              </div>
            )}
            <div className="text-right">
              <button type="button" className="btn btn-link" onClick={cancel}>
                Cancel
              </button>
              {renderSubmitButton({ disabled: !isValid })}
            </div>
          </>
        )
      }}
    </Form>
  )
}

export default SupplierSendBackFormSection
