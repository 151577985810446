import LeftSidebar, {
  Option,
} from "../../components/SupplierOrganizationDashboard/LeftSidebar"
import React, { useState } from "react"
import SalesCollaborationToolsNetworkTable from "./SalesCollaborationToolsNetworkTable"
import { CanopyButton } from "@parachutehealth/canopy-button"
import { CanopyFlex } from "@parachutehealth/canopy-flex"
import { inviteReferralUrl } from "./api"
import { SalesCollaborationToolsNetworkLegendModal } from "./SalesCollaborationToolsNetworkLegendModal"
import * as styles from "./SalesCollaborationToolsNetwork.module.scss"

export interface Props {
  supplierOrgExternalId: string
}

const SalesCollaborationToolsNetwork = (props: Props) => {
  const { supplierOrgExternalId } = props
  const [modalVisible, setModalVisible] = useState(false)

  const openModal = () => {
    setModalVisible(true)
  }

  const closeModal = () => {
    setModalVisible(false)
  }

  return (
    <div className={styles.mainContainer}>
      <LeftSidebar active={Option.Network} />
      <SalesCollaborationToolsNetworkLegendModal
        onClose={closeModal}
        visible={modalVisible}
      />
      <div className="row mt-n3">
        <div className="canopy-mbs-12x col-12">
          <h1>
            <span className={styles.headerText}>Network</span>
          </h1>
          <div className={styles.subheaderText}>
            Add new facilities, manage existing facilities, and grow your
            Parachute network.
          </div>
          <div className={styles.subheaderText}>
            <b>Note:</b> The data displayed is aggregated across all suppliers
            you are currently employed with.
          </div>
          <hr />
          <CanopyFlex
            direction="row"
            justifyContent="flex-end"
            className="canopy-mb-8x"
          >
            <CanopyButton
              iconStart="circle-info"
              onClick={openModal}
              size="small"
              variant="tertiary"
            >
              Legend
            </CanopyButton>
            <CanopyButton
              as="a"
              href={inviteReferralUrl(supplierOrgExternalId)}
              size="small"
              variant="primary"
            >
              Invite referral
            </CanopyButton>
          </CanopyFlex>
          <SalesCollaborationToolsNetworkTable
            supplierOrgExternalId={supplierOrgExternalId}
          />
        </div>
      </div>
    </div>
  )
}

export default SalesCollaborationToolsNetwork
