import { Link, useParams } from "react-router-dom"
import React, { useContext, useEffect } from "react"
import { CatalogProduct } from "../../../../types/sharedTypes"
import { useQuery } from "@tanstack/react-query"
import {
  archiveCatalogProduct,
  getCatalogProduct,
  unarchiveCatalogProduct,
} from "../../../../api/products"
import { Backdrop, CircularProgress } from "@material-ui/core"
import { DndProvider } from "react-dnd"
import { HTML5Backend } from "react-dnd-html5-backend"
import SidebarLayout from "../../../../components/SidebarLayout"
import { initUserInstruments } from "../../../../../../services/clientsideInstrumentation"
import Breadcrumbs from "../../../../components/Breadcrumbs"
import { editProductUrl, productsUrl } from "../../../../urls/products"
import { NoticeContext } from "../../../../contexts/NoticeContext"
import { CanopyNotice } from "@parachutehealth/canopy-notice"
import DropdownMenu from "../../../../components/DropdownMenu"
import { CanopyButton } from "@parachutehealth/canopy-button"
import InternalNote from "../../../../components/InternalNote"
import CategoriesList from "./CategoriesList"
import { isNullOrUndefined } from "../../../../../../utilities/isNullOrUndefined"
import { Notice } from "../../../../components/Notice"
import ConfirmDialog from "../../../../components/ConfirmDialog"
import { slugify } from "../../../../../../utilities/string"
import SimpleTabs from "applications/Cms/components/SimpleTabs"
import { CountContext, CountProvider } from "../../../../contexts/CountContext"
import TabBadge from "../../../../components/TabBadge"
import { CanopyFlex } from "@parachutehealth/canopy-flex"
import PackagesDataGrid from "./PackagesDataGrid"
import SkusAndAttributesTab from "./SkusAndAttributesTab"

type InternalProductDetailsPageProps = {
  product: CatalogProduct
  reload: () => void
}

const InternalProductDetailsPage: React.FC<InternalProductDetailsPageProps> = ({
  product,
  reload,
}: InternalProductDetailsPageProps) => {
  useEffect(() => {
    document.title = product.name
    void initUserInstruments()
  }, [product.name])

  const { showNotice } = useContext(NoticeContext)
  const { count } = React.useContext(CountContext)

  const [confirmationOpen, setConfirmationOpen] = React.useState(false)

  const onConfirmArchive = async () => {
    const success = await archiveCatalogProduct(product.externalId)
    if (success) {
      reload()
      showNotice(`Successfully archived ${product.name}`, "success")
    } else {
      showNotice("An error occurred while archiving", "error")
    }
  }

  const unarchive = async () => {
    await unarchiveCatalogProduct(product.externalId).then(
      (success: boolean) => {
        reload()

        if (success) {
          showNotice(`${product.name} unarchived `, "success")
        } else {
          showNotice("Failed to unarchive", "error")
        }
      }
    )
  }

  const onArchiveMenuClick = async () => {
    if (!product.archivedAt) {
      setConfirmationOpen(true)
    } else {
      await unarchive()
    }
  }

  const archiveState = product.archivedAt ? "Unarchive" : "Archive"

  const HeaderContent = (): React.JSX.Element => {
    return (
      <>
        <Breadcrumbs>
          {[
            { label: "All Products", to: productsUrl() },
            { label: product.name },
          ]}
        </Breadcrumbs>
        <CanopyFlex justifyContent="space-between" className="canopy-my-2x">
          <h1 className="canopy-typography-heading-xlarge canopy-mt-4x canopy-mbe-4x">
            {product.name}
          </h1>
          <CanopyFlex data-testid="actions-bar">
            {product.permissions?.edit && (
              <CanopyButton
                as={Link}
                to={editProductUrl(product.externalId)}
                variant="secondary"
                size="small"
                iconStart="pencil"
              >
                Edit Product settings
              </CanopyButton>
            )}

            <DropdownMenu
              className="canopy-mis-4x"
              label="More actions"
              id="actions-menu"
            >
              {[
                {
                  label: "View Audit Log",
                  href: product.auditLogUrl,
                  icon: "clock-rotate-left",
                },
                {
                  label: archiveState,
                  ifTrue: () => Boolean(product.permissions?.destroy),
                  variant: "danger",
                  onClick: onArchiveMenuClick,
                  icon: product.archivedAt
                    ? "box-archive-circle-arrow-up"
                    : "box-archive",
                },
              ]}
            </DropdownMenu>
          </CanopyFlex>
        </CanopyFlex>

        <ConfirmDialog
          title="Confirmation"
          message="Are you sure you want to archive this product? This will archive any associated SKUs and delete their Supplier SKUs as well."
          confirmButtonText="Confirm"
          cancelButtonText="Cancel"
          open={confirmationOpen}
          onConfirm={onConfirmArchive}
          onCancel={() => setConfirmationOpen(false)}
          handleClose={() => setConfirmationOpen(false)}
        />
      </>
    )
  }

  const ProductDetails = (): React.JSX.Element => {
    const items: { [p: string]: string | undefined } = {
      "Requires height": product.requiresHeight ? "Yes" : "No",
      "Requires weight": product.requiresWeight ? "Yes" : "No",
      "Formulary applicable": product.formularyApplicable ? "Yes" : "No",
    }

    return (
      <div>
        <h4 className="canopy-typography-heading-medium">
          Configuration details
        </h4>
        <dl
          data-testid="usage-details"
          className="canopy-typography-body-small canopy-mb-0"
        >
          {Object.keys(items).map((key) => {
            const slug = slugify(key)
            return (
              <React.Fragment key={key}>
                <dt id={`term-${slug}`} role="term" aria-label={key}>
                  {key}
                </dt>
                <dd aria-labelledby={`term-${slug}`} className="canopy-mbe-6x">
                  {items[key]}
                </dd>
              </React.Fragment>
            )
          })}
        </dl>
      </div>
    )
  }

  const ArchivedIndicator = (): React.JSX.Element => {
    if (isNullOrUndefined(product.archivedAt)) {
      return <></>
    } else {
      return (
        <Notice type="danger" role="note" aria-label="Archived" icon={false}>
          This product has been archived
        </Notice>
      )
    }
  }

  const openContent = (): React.ReactNode[] => {
    return [
      <ArchivedIndicator key="archivedIndicator" />,
      <InternalNote key="internalNote" note={product.internalNotes} />,
      <CategoriesList
        key="categorieList"
        categoryHierarchy={product.categoryHierarchies}
      />,
      <ProductDetails key="productDetails" />,
    ]
  }

  const MainContent = (
    <>
      <SimpleTabs fullWidth>
        {[
          {
            key: "skus",
            label: (
              <>
                Attributes
                <TabBadge>{count["attributes"]}</TabBadge>
                and SKUs
                <TabBadge>{count["skus"] || 0}</TabBadge>
              </>
            ),
            content: (
              <>
                <SkusAndAttributesTab product={product} />
              </>
            ),
          },
          {
            key: "packages",
            label: (
              <>
                Packages
                <TabBadge>{count["packages"] || 0}</TabBadge>
              </>
            ),
            content: <PackagesDataGrid product={product} />,
          },
        ]}
      </SimpleTabs>
    </>
  )

  const ClosedContent = (): React.JSX.Element => {
    return (
      <InternalNote key="internalNote" closed note={product.internalNotes} />
    )
  }

  return (
    <SidebarLayout
      openContent={openContent()}
      mainContent={MainContent}
      closedContent={<ClosedContent />}
      headerContent={HeaderContent}
      sidebarTitle="Product details"
    />
  )
}

const ProductDetailsPage: React.FC = () => {
  const { productId } = useParams()
  const { showNotice } = useContext(NoticeContext)

  const {
    refetch,
    isFetching = true,
    error: error,
    data: productData,
  } = useQuery({
    queryKey: ["catalogProduct", productId],
    queryFn: () => getCatalogProduct(productId),
    refetchOnWindowFocus: false,
  })

  if (error) {
    showNotice(`An error occurred: ${error["message"]}`, "error", [], true)
  }

  if (isFetching) {
    return (
      <Backdrop style={{ zIndex: 999 }} open={true}>
        <CircularProgress color="inherit" />
      </Backdrop>
    )
  } else if (error) {
    return (
      <CanopyNotice
        title="An error occurred"
        variant="error"
        message={error["message"]}
      />
    )
  } else {
    return (
      <DndProvider backend={HTML5Backend}>
        <CountProvider>
          <InternalProductDetailsPage
            reload={refetch}
            product={productData!.product}
          />
        </CountProvider>
      </DndProvider>
    )
  }
}

export default ProductDetailsPage
