import React, { useContext } from "react"
import Thumbnail from "components/Thumbnail"
import { EmployerType, LineItemGroup } from "sharedTypes"
import GlobalContext from "context/Global"
import { Link } from "react-router-dom"
import Confirm from "components/Confirm"
import * as routes from "../../../../../../routes"
import * as styles from "./CartLineItemGroup.module.scss"
import QuantityForm from "./QuantityForm"
import { NotificationGroup, Severity } from "../sharedTypes"
import Notifications from "../Notifications"
import FormularyPrice from "./FormularyPrice"
import classNames from "classnames"
import cx from "classnames"

type Props = {
  lineItemGroup: LineItemGroup
  canUpdate: boolean
  removeLineItemGroup(lineItemGroup: LineItemGroup): Promise<void>
  updateLineItemGroup(
    lineItemGroup: LineItemGroup,
    params: { quantity: number }
  ): Promise<void>
  notifications: NotificationGroup | undefined
  formularyPriceEnabled: boolean
}

const CartLineItemGroup = ({
  lineItemGroup,
  canUpdate,
  removeLineItemGroup,
  updateLineItemGroup,
  notifications,
  formularyPriceEnabled,
}: Props) => {
  const { currentEmployer } = useContext(GlobalContext)

  return (
    <div className={cx("well", "well-expand", "canopy-p-12x", styles.cartItem)}>
      <div className={styles.container}>
        <div className="row">
          <div className={styles.image}>
            <Thumbnail src={lineItemGroup.imageUrl} />
          </div>
          <div className={classNames([styles.displayName, "col"])}>
            <strong>{lineItemGroup.displayName}</strong>
            {currentEmployer.employerType === EmployerType.ClinicalFacility && (
              <div>
                <span className="clearfix font-xs">
                  {lineItemGroup.packageSupplier.name}
                </span>
              </div>
            )}
          </div>
          <div className={styles.quantityContainer}>
            {canUpdate ? (
              <QuantityForm
                update={(params: { quantity: number }) =>
                  updateLineItemGroup(lineItemGroup, params)
                }
                quantity={lineItemGroup.quantity}
              />
            ) : (
              lineItemGroup.quantity
            )}
          </div>
        </div>
        <div className="row canopy-mbs-5x">
          <div className="col">
            {lineItemGroup.lineItems.map(({ skuDescription }) => (
              <div key={skuDescription}>{skuDescription}</div>
            ))}
          </div>
        </div>
        <FormularyPrice
          lineItemGroup={lineItemGroup}
          formularyPriceEnabled={formularyPriceEnabled}
        />
        <div className="row">
          <div className="col-12">
            {notifications && !!notifications.errors.length && (
              <Notifications
                notifications={notifications.errors}
                severity={Severity.Danger}
              />
            )}
            {notifications && !!notifications.warnings.length && (
              <Notifications
                notifications={notifications.warnings}
                severity={Severity.Warning}
              />
            )}
          </div>
        </div>
        {canUpdate && (
          <div className="row canopy-mbs-8x">
            <div className="col-6">
              <Confirm
                title={`Remove ${lineItemGroup.displayName} from order?`}
                cancelText="No, don't remove"
                confirmText="Yes, remove"
                render={(confirm) => (
                  <a
                    className="color-danger bold"
                    onClick={confirm(() => removeLineItemGroup(lineItemGroup))}
                  >
                    Remove
                  </a>
                )}
              />
            </div>
            <div className="col-6 text-right">
              <Link
                className="link"
                to={routes.productsPackageConfigurationPath(
                  lineItemGroup.packageSupplier.externalId,
                  lineItemGroup.packageConfigurationId
                )}
              >
                Edit
              </Link>
            </div>
          </div>
        )}
      </div>
    </div>
  )
}

export default CartLineItemGroup
