import React from "react"
import { format } from "utilities/date"
import { titleize } from "utilities/string"
import { CanopyButton } from "@parachutehealth/canopy-button"
import { CanopyFlex } from "@parachutehealth/canopy-flex"
import Card from "components/Card"
import { DmeOrder } from "./api"
import { toSentence } from "utilities/array"
import { orderUrl } from "../../urls"

type OrderCardProps = {
  order: DmeOrder
}

export const OrderCard: React.FC<OrderCardProps> = ({
  order,
}: OrderCardProps) => {
  const orderHref = `${window.origin}${orderUrl(
    order.supplier.id,
    order.id
  )}/review`
  return (
    <Card>
      <div className="canopy-mb-12x">
        <div className="float-right canopy-typography-body-small">
          Order ID: {order.id}
        </div>
        <h4 className="canopy-typography-heading-medium canopy-mb-1x">
          {order.clinicalFacility.name}
        </h4>
        <div className="canopy-typography-body-small">
          {toSentence(
            order.lineItemGroups.map(({ description }) => description)
          )}
        </div>
      </div>
      <CanopyFlex justifyContent="space-between">
        <div className="canopy-typography-body-small">
          <div>
            <span className="canopy-typography-heading-small">STATUS</span>{" "}
            {titleize(order.orderStatus)}
          </div>
          <div>
            <span className="canopy-typography-heading-small">DELIVERY</span>{" "}
            {format(order.requestedDeliveryDate)}
          </div>
        </div>
        <CanopyButton
          as="a"
          href={orderHref}
          target="_blank"
          variant="secondary"
          iconStart="eye"
          size="small"
        >
          View
        </CanopyButton>
      </CanopyFlex>
    </Card>
  )
}
