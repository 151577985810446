import { useMutation, useQuery } from "@apollo/client"
import { CanopyButton } from "@parachutehealth/canopy-button"
import React, { useState } from "react"
import { CarrierAuthPage_GetCarrierAuthorizationById } from "./graphql/carrierAuthPageGetCarrierAuthById"

import { CanopyNotice } from "@parachutehealth/canopy-notice"
import { withApollo } from "hocs/withApollo"
import {
  CarrierAuthorization,
  CarrierAuthStatusesEnum,
  CarrierLineItemAuthorization,
  CarrierLineItemAuthorizationUpdateMutationVariables,
  Doctor,
} from "../../../graphql/__generated__/graphql"
import { EmployerClassEnum } from "../../../sharedTypes"
import { format, DATE_TIME_FORMAT } from "../../../utilities/date"
import { sendError } from "../../../utilities/error"
import { isNullOrUndefined } from "../../../utilities/isNullOrUndefined"
import { scrollTop } from "../../../utilities/scroll"
import { AuthorizationDetails } from "./components/AuthorizationDetails/AuthorizationDetails"
import { AuthorizationRequestLineItemCard } from "./components/AuthorizationLineItemCard/AuthorizationRequestLineItemCard"
import { EditDrawer } from "./components/EditDrawer/EditDrawer"
import { OrderLinker } from "./components/OrderLinker/OrderLinker"
import { PayorLineItemEditForm } from "./components/PayorLineItemEditForm/PayorLineItemEditForm"
import { SupplierLineItemEditForm } from "./components/SupplierLineItemEditForm/SupplierLineItemEditForm"
import { CarrierAuthorizationSubmit } from "./graphql/carrierAuthorizationSubmit"
import { CarrierLineItemAuthorizationDelete } from "./graphql/carrierLineItemAuthorizationDelete"
import { CarrierLineItemAuthorizationUpdate } from "./graphql/carrierLineItemAuthorizationUpdate"
import { useEmployer } from "./hooks/useEmployer"
import * as styles from "./index.module.scss"

type PageNotice = {
  status: "success" | "information" | "error"
  message: string
}

interface CarrierAuthorizationRequestProps {
  carrierAuthorizationId: string
}

const calculateAccessByEmployer = (
  employer: string | undefined,
  carrierAuthorization: CarrierAuthorization
) => {
  switch (employer) {
    case EmployerClassEnum.Supplier:
      return isNullOrUndefined(carrierAuthorization.requestSubmitDateTime)
    case EmployerClassEnum.InsurancePayor:
      return !!carrierAuthorization.requestSubmitDateTime
    default:
      return false
  }
}

const SupplierPreSubmissionMessage = () => {
  return (
    <>
      <h3 className="canopy-typography-heading-xlarge canopy-mb-0">
        Review & submit authorization request
      </h3>
      <p className="canopy-typography-body-medium canopy-mt-2x canopy-mb-8x">
        Review and complete the missing required information. Changes made on
        this page will NOT be reflected on the order.
      </p>
    </>
  )
}

const CarrierAuthorizationRequest = ({
  carrierAuthorizationId,
}: CarrierAuthorizationRequestProps) => {
  const [pageAlert, setPageAlert] = useState<PageNotice | null>(null)
  const [submissionNotice, setSubmissionNotice] = useState<PageNotice | null>(
    null
  )
  const [openEditDrawer, setOpenEditDrawer] = useState(false)
  const [editItem, setEditItem] = useState<CarrierLineItemAuthorization | null>(
    null
  )
  const [editDrawerHeader, setEditDrawerHeader] = useState<string>("")

  const { currentEmployer, isSupplier, isPayor } = useEmployer()

  const onMutationComplete = (pageAlert: PageNotice) => {
    setPageAlert(pageAlert)
    setEditItem(null)
    setOpenEditDrawer(false)
  }

  const { data } = useQuery(CarrierAuthPage_GetCarrierAuthorizationById, {
    variables: { id: carrierAuthorizationId },
    notifyOnNetworkStatusChange: true,
    onError: () => {
      sendError(
        `Error fetching carrier authorization ${carrierAuthorizationId}`
      )
    },
    onCompleted: (result) => {
      if (
        result?.carrierAuthorization?.authStatus ===
        CarrierAuthStatusesEnum.PayorReviewComplete
      ) {
        setSubmissionNotice(null)
      } else if (
        result?.carrierAuthorization?.authStatus ===
        CarrierAuthStatusesEnum.PayorInReview
      ) {
        const prettyPrintSubmitAt = format(
          result.carrierAuthorization.requestSubmitDateTime.toLocaleString(),
          DATE_TIME_FORMAT
        )
        setSubmissionNotice({
          status: "information",
          message: `Authorization request submitted ${prettyPrintSubmitAt}`,
        })
      }
    },
  })

  const [updateLineItem] = useMutation(CarrierLineItemAuthorizationUpdate, {
    refetchQueries: [CarrierAuthPage_GetCarrierAuthorizationById],
    onCompleted: (data) => {
      const { hcpcs } = data?.carrierLineItemAuthorizationUpdate
        ?.carrierLineItemAuthorization as CarrierLineItemAuthorization
      onMutationComplete({
        status: "success",
        message: `Prior auth line item: ${hcpcs} has been updated`,
      })
    },
    onError: () => {
      onMutationComplete({
        status: "error",
        message: `We had trouble updating prior auth line item ${editItem?.hcpcs}. Please try again.`,
      })
    },
  })

  const [deleteLineItem] = useMutation(CarrierLineItemAuthorizationDelete, {
    refetchQueries: [CarrierAuthPage_GetCarrierAuthorizationById],
    onCompleted: () => {
      setOpenEditDrawer(false)
      onMutationComplete({
        status: "information",
        message: `Prior auth line item: ${editItem?.hcpcs} has been deleted`,
      })
    },
    onError: (_error) => {
      onMutationComplete({
        status: "error",
        message: `We had trouble completing your request. Please try again.`,
      })
    },
  })

  const [
    carrierAuthorizationSubmit,
    { loading: carrierAuthorizationSubmitLoading },
  ] = useMutation(CarrierAuthorizationSubmit, {
    refetchQueries: [CarrierAuthPage_GetCarrierAuthorizationById],
    onCompleted: (result) => {
      const updatedAuth =
        result.carrierAuthorizationSubmit?.carrierAuthorization
      if (updatedAuth) {
        const prettyPrintSubmitAt = format(
          updatedAuth.requestSubmitDateTime.toLocaleString(),
          DATE_TIME_FORMAT
        )
        setPageAlert(null)
        setSubmissionNotice({
          status: "information",
          message: `Authorization request submitted ${prettyPrintSubmitAt}`,
        })
        scrollTop()
      }
    },
    onError: (error) => {
      if (error.message === "missing_required_info_error") {
        setPageAlert({ status: "error", message: "Missing required info" })
      } else {
        setPageAlert({
          status: "error",
          message:
            "Your request is unable to be submitted at this time. Please try again later.",
        })
      }
    },
  })

  const submitAuthorization = () => {
    carrierAuthorizationSubmit({
      variables: {
        carrierAuthorizationId: carrierAuthorizationId,
      },
    })
  }

  const carrierAuthorization = data?.carrierAuthorization as CarrierAuthorization

  const hasEditAccess =
    !!carrierAuthorization &&
    calculateAccessByEmployer(currentEmployer, carrierAuthorization)
  const hasSubmitAccess = hasEditAccess && isSupplier

  const showEditForm = () => {
    if (editItem) {
      if (isSupplier) {
        return (
          <SupplierLineItemEditForm
            lineItem={editItem}
            onSubmit={async (
              updatedFields: CarrierLineItemAuthorizationUpdateMutationVariables
            ) => {
              await updateLineItem({ variables: { ...updatedFields } })
            }}
            onDelete={async (lineItemId: string) => {
              await deleteLineItem({ variables: { id: lineItemId } })
            }}
            onCancel={() => {
              setOpenEditDrawer(false)
              setEditItem(null)
            }}
          />
        )
      } else if (isPayor) {
        return (
          <PayorLineItemEditForm
            onCancel={() => {
              setOpenEditDrawer(false)
              setEditItem(null)
            }}
            onSubmit={async (
              updatedFields: CarrierLineItemAuthorizationUpdateMutationVariables
            ) => {
              await updateLineItem({ variables: { ...updatedFields } })
            }}
            lineItem={editItem}
          />
        )
      }
    }
  }

  return (
    <div className="canopy-mx-8x">
      <h1 className="canopy-typography-heading-2xlarge canopy-mb-0 canopy-my-12x">
        Auth request {carrierAuthorizationId}
      </h1>
      {hasSubmitAccess && <SupplierPreSubmissionMessage />}

      {pageAlert &&
        /*  Typescript gets angry because
                            CanopyNotice doesn't support closable prop
                            for any variants except "success" and "discovery" */
        (pageAlert.status === "success" ? (
          <CanopyNotice
            className="canopy-mb-4x"
            title={pageAlert.message}
            closable={true}
            onClose={() => setPageAlert(null)}
            variant="success"
          />
        ) : (
          <CanopyNotice
            className="canopy-mb-4x"
            title={pageAlert.message}
            variant={pageAlert.status}
          />
        ))}
      {submissionNotice && (
        <CanopyNotice
          title={submissionNotice.message}
          variant={submissionNotice.status}
        />
      )}
      <div className={styles.authCardsContainer}>
        <div className={styles.authorizationDetailsContainer}>
          {carrierAuthorization?.carrierLineItemAuthorizations &&
            carrierAuthorization.carrierLineItemAuthorizations.map(
              (lineItem) => (
                <AuthorizationRequestLineItemCard
                  key={lineItem.id}
                  orderingPhysician={
                    carrierAuthorization.orderingDoctor as Doctor
                  }
                  lineItem={lineItem}
                  dmeOrderId={carrierAuthorization.dmeOrder?.id || ""}
                  hasEditAccess={hasEditAccess}
                  onEditClick={() => {
                    setOpenEditDrawer(true)
                    setEditItem(lineItem)
                    setEditDrawerHeader(
                      `Prior auth line item: ${lineItem?.hcpcs}`
                    )
                  }}
                />
              )
            )}
          {hasSubmitAccess && (
            <CanopyButton
              variant="primary"
              size="medium"
              className="canopy-mt-8x"
              loading={carrierAuthorizationSubmitLoading}
              onClick={submitAuthorization}
            >
              Submit authorization request
            </CanopyButton>
          )}

          {carrierAuthorization?.requestSubmitDateTime && (
            <AuthorizationDetails authorization={carrierAuthorization} />
          )}
        </div>
        {carrierAuthorization && (
          <OrderLinker carrierAuthorization={carrierAuthorization} />
        )}
      </div>

      <EditDrawer
        open={openEditDrawer}
        anchor="right"
        heading={editDrawerHeader}
        onClose={() => setOpenEditDrawer(false)}
      >
        {showEditForm()}
      </EditDrawer>
    </div>
  )
}

export default withApollo(CarrierAuthorizationRequest)
