import React from "react"
import { CarrierAuthorization } from "../../../../../graphql/__generated__/graphql"
import { AuthorizationDetailsSection } from "./AuthorizationDetailsSection/AuthorizationDetailsSection"
import {
  DOCTOR_DETAILS,
  FACILITY_DETAILS,
  PATIENT_DETAILS,
  PLAN_DETAILS,
  REQUEST_DETAILS,
  SUBMITTER_DETAILS,
  SUBSCRIBER_DETAILS,
  SUPPLIER_DETAILS,
  SUPPLIER_LEGAL_ENTITY_DETAILS,
} from "./constants/authorizationDetailsDefinitions"
import MuiAccordion from "@material-ui/core/Accordion"
import * as styles from "./AuthorizationDetails.module.scss"
import { AccordionDetails, AccordionSummary } from "@material-ui/core"
import ExpandMoreIcon from "@material-ui/icons/ExpandMore"
import {
  AuthStatusToSeverity,
  AuthStatusToTitle,
} from "../../utilities/statusFormatters"
import AuthStatusPill from "../AuthStatusPill/AuthStatusPill"

interface AuthorizationDetailsProps {
  authorization: CarrierAuthorization
}

export const AuthorizationDetails = ({
  authorization,
}: AuthorizationDetailsProps) => {
  const sections = [
    REQUEST_DETAILS,
    SUPPLIER_DETAILS,
    SUPPLIER_LEGAL_ENTITY_DETAILS,
    SUBSCRIBER_DETAILS,
    PLAN_DETAILS,
    PATIENT_DETAILS,
    FACILITY_DETAILS,
    DOCTOR_DETAILS,
    SUBMITTER_DETAILS,
  ]

  return (
    <MuiAccordion className={styles.accordionRoot}>
      <AccordionSummary expandIcon={<ExpandMoreIcon />}>
        <div className={styles.authAccordionHeader}>
          <h4 className="canopy-typography-heading-large canopy-m-0 canopy-py-4x">
            Auth request details
          </h4>
          {authorization.authStatus && (
            <AuthStatusPill
              statusLevel={AuthStatusToSeverity[authorization.authStatus]}
              statusText={AuthStatusToTitle[authorization.authStatus]}
            />
          )}
        </div>
      </AccordionSummary>
      <AccordionDetails className="d-block">
        {sections.map((section) => (
          <AuthorizationDetailsSection
            key={section.title}
            authorization={authorization}
            dataDefinition={section}
          />
        ))}
      </AccordionDetails>
    </MuiAccordion>
  )
}
