import React from "react"
import {
  CanopyComboboxField,
  SelectOption,
} from "@parachutehealth/canopy-combobox-field"
import { CanopyNotice } from "@parachutehealth/canopy-notice"
import { CanopyForm } from "@parachutehealth/canopy-form"
import { CanopyRequiredFieldKey } from "@parachutehealth/canopy-required-field-key"

interface TherapySelectProps {
  options: SelectOption[]
  value: SelectOption | null
  handleTherapySelect: (therapy) => void
}

const TherapySelect = ({
  options,
  value,
  handleTherapySelect,
}: TherapySelectProps) => {
  return (
    <CanopyForm className="col-lg-4">
      <CanopyRequiredFieldKey />
      <CanopyComboboxField
        label="Therapy"
        placeholder="Choose a therapy"
        required={true}
        options={options}
        value={value}
        onChange={handleTherapySelect}
      />
      {!value && <CanopyNotice title="Choose a therapy to continue." />}
    </CanopyForm>
  )
}

export default TherapySelect
