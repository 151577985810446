import { get } from "../../services/api"
import { SalesCollaborationFacilitiesResponse } from "./sharedTypes"

export const inviteReferralUrl = (supplierOrganizationExternalId: string) => {
  return `/u/r/${supplierOrganizationExternalId}/supplier_sign_up`
}

export const getSalesCollaborationFacilities = async (
  supplierOrganizationId: string,
  params: { [key: string]: any } = {}
): Promise<SalesCollaborationFacilitiesResponse> => {
  const url = `/u/r/${supplierOrganizationId}/networks.json`
  return await get(url, params).then((response) => {
    return response.data as SalesCollaborationFacilitiesResponse
  })
}
