import React from "react"
import { TherapyType } from "./sharedTypes"
import Therapy from "./Therapy/Therapy"
import { Route, Switch } from "react-router-dom"
import Diagnosis from "./Diagnosis/Diagnosis"
import { DiagnosisPath, TherapyPath } from "./routes"
import { withBrowserRouter } from "../../routers/BrowserRouter"

interface DoctorPendingOrderWorkflowProps {
  clinicalFacilityId: string
  pendingOrderId: string
  therapies: TherapyType[]
}

const DoctorPendingOrderWorkflow = ({
  clinicalFacilityId,
  pendingOrderId,
  therapies,
}: DoctorPendingOrderWorkflowProps) => {
  const diagnosisPath = DiagnosisPath(clinicalFacilityId, pendingOrderId)
  const therapyPath = TherapyPath(clinicalFacilityId, pendingOrderId)

  return (
    <Switch>
      <Route
        path={diagnosisPath}
        render={() => {
          return <Diagnosis therapyPath={therapyPath} />
        }}
      />
      <Route
        path="/"
        render={() => {
          return (
            <Therapy
              therapies={therapies}
              pendingOrderId={pendingOrderId}
              diagnosisPath={diagnosisPath}
            />
          )
        }}
      />
    </Switch>
  )
}

export default withBrowserRouter(DoctorPendingOrderWorkflow, {})
