import React, { useState, useCallback } from "react"
import { BrowserRouter, Route, Switch, useHistory } from "react-router-dom"
import BackToDashboardLink from "./components/BackToDashboardLink"
import ClinicalFacilityProfileName from "./components/ClinicalFacilityProfileName"
import ClinicalFacilityProfileAddress from "./components/ClinicalFacilityProfileAddress"
import { getFacilityProfile } from "./api"

export type ClinicalFacilityProfile = {
  externalId: string
  name: string
  line1: string
  line2: string
  city: string
  state: string
  zip: string
}

type Props = {
  clinicalFacility: ClinicalFacilityProfile
}

const ClinicalFacilityProfileWorkflow = (props: Props): React.JSX.Element => {
  const [facility, setFacility] = useState(props.clinicalFacility)

  const refreshFacility = useCallback(async () => {
    try {
      const updatedFacility = await getFacilityProfile()
      setFacility(updatedFacility)
      return updatedFacility
    } catch (error) {
      console.error("Error fetching facility data:", error)
    }
  }, [])

  const WorkflowRoutes = () => {
    const history = useHistory()

    return (
      <>
        <Switch>
          <Route
            exact
            path="/"
            render={() => (
              <ClinicalFacilityProfileName
                clinicalFacility={facility}
                onUpdate={refreshFacility}
                next={() => history.push("/facility_address")}
              />
            )}
          />
          <Route
            path="/facility_address"
            render={() => (
              <ClinicalFacilityProfileAddress
                clinicalFacility={facility}
                onUpdate={refreshFacility}
                next={() => (window.location.href = "/")}
                back={() => history.push("/")}
              />
            )}
          />
        </Switch>
      </>
    )
  }

  return (
    <BrowserRouter basename={`/u/f/${facility.externalId}/complete_profile`}>
      <BackToDashboardLink />
      <WorkflowRoutes />
    </BrowserRouter>
  )
}

export default ClinicalFacilityProfileWorkflow
