import React, { useContext, useState } from "react"
import { CanopyButton } from "@parachutehealth/canopy-button"
import { Form } from "../../../../../../../components/form"
import { CanopyDialogModal } from "@parachutehealth/canopy-dialog-modal"
import { createSimplePackage } from "../../../../../api/simplePackages"
import {
  CatalogPackage,
  CatalogPackageProduct,
  CatalogProduct,
} from "../../../../../types/sharedTypes"
import DynamicFormField, {
  booleanRadioGroup,
} from "../../../../../components/Formik/DynamicFormField"
import { NoticeContext } from "../../../../../contexts/NoticeContext"

type Props = {
  product: CatalogProduct
  open?: boolean // for testing,
  onSuccess?: (
    catalogPackage: CatalogPackage,
    catalogPackageProducts: CatalogPackageProduct[]
  ) => void
}

const CreateSimplePackageDialog: React.FC<Props> = (
  props: Props
): React.JSX.Element => {
  const { open = false, product, onSuccess } = props

  const [dialogOpen, setDialogOpen] = useState<boolean>(open)

  const { showNotice } = useContext(NoticeContext)

  const handleClickOpen = () => {
    setDialogOpen(true)
  }

  const handleClose = () => {
    setDialogOpen(false)
  }

  const handleSubmit = (params: Record<string, any>, { setSubmitting }) => {
    createSimplePackage(product.id, params.addToSuppliers)
      .then((response) => {
        if (onSuccess) onSuccess(response.package, response.packageProducts)
        showNotice(
          `Package ${response.package.name} created successfully`,
          "success"
        )
      })
      .catch((_error) => {
        // there's no validation errors that are fixing, so just close the modal and show the error
        showNotice(`Error creating package`, "error")
      })
      .finally(() => {
        setSubmitting(false)
        handleClose()
      })
  }

  return (
    <div>
      <CanopyButton onClick={handleClickOpen} variant="primary" size="small">
        Create Simple Package
      </CanopyButton>
      <Form
        initialValues={{
          addToSuppliers: false,
        }}
        onSubmit={handleSubmit}
      >
        {({ isSubmitting, submitForm }) => (
          <CanopyDialogModal
            size="large"
            header="Create Simple Package"
            open={dialogOpen}
            onClose={handleClose}
            primaryFooterButton={
              <CanopyButton
                variant="primary"
                type="submit"
                size="small"
                loading={isSubmitting}
                onClick={submitForm}
              >
                Create Simple Package
              </CanopyButton>
            }
            secondaryFooterButton={
              <CanopyButton
                disabled={isSubmitting}
                onClick={handleClose}
                variant="secondary"
                size="small"
              >
                Cancel
              </CanopyButton>
            }
          >
            <p>
              By proceeding, you will create a new package with the same name as
              this product, containing <em>only</em> this product. Additional
              options may be specified below.
            </p>
            <label className="canopy-typography-font-weight-bold">
              Options
            </label>
            <DynamicFormField
              definition={{
                description:
                  "If checked, any suppliers with variants of this product will be configured for the new package",
                type: "radio",
                label: "Add to Suppliers",
                name: "addToSuppliers",
                options: booleanRadioGroup(),
                initialValue: ["false"],
              }}
            />
          </CanopyDialogModal>
        )}
      </Form>
    </div>
  )
}

export default CreateSimplePackageDialog
