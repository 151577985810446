import React, { useEffect, useState } from "react"
import CancelButton from "../CancelButton"
import FollowersButton from "../FollowersButton"
import { DmeOrder, DmeOrderPermissions, History, Page } from "sharedTypes"
import { UpdateFollowersParams } from "components/ClinicalModals/sharedTypes"
import { PatientFormParams, SidebarTab } from "../../sharedTypes"
import PatientModal from "../PatientModal"
import FollowersModal from "components/ClinicalModals/FollowersModal"
import BottomNavigation from "../BottomNavigation"
import LeftNavigation from "../LeftNavigation"
import TopNavigation from "../TopNavigation"
import PatientLink from "../PatientLink"
import CanopyBackedFeedbackButton from "components/FeedbackModalButton/CanopyBackedFeedbackButton"
import * as styles from "../LeftNavigation/index.module.scss"
import cx from "classnames"

type Props = {
  nextPagePath: string
  pages: Page[]
  history: History
  permissions: DmeOrderPermissions
  dmeOrder: DmeOrder
  patientLinkDisabled: boolean
  updatePatient(id: string, params: PatientFormParams): Promise<void>
  updateFollowers(params: UpdateFollowersParams, orderId: string): Promise<void>
  toggle(): void
  close(): void
  inOrderNavReplacement: boolean
  currentTab: SidebarTab
  setSidebarTab(tab: SidebarTab): void
  isFacilityUser?: boolean
  followDmeOrder(): Promise<void>
  unfollowDmeOrder(followingId: string): Promise<void>
  snoozeDmeOrder(params): Promise<void>
  unsnoozeDmeOrder(): Promise<void>
  isSidebarOpen: boolean
}

function getButtons({
  classes,
  followers,
  onFollowersClick,
  permissions,
  dmeOrder,
}) {
  return [
    <FollowersButton
      key="followers-button"
      className={classes.followers}
      followers={followers}
      onClick={onFollowersClick}
    />,
    permissions.cancel && !permissions.snooze && (
      <CancelButton
        patientName={`${dmeOrder.patient.firstName} ${dmeOrder.patient.lastName}`}
        key="cancel-button"
        className={classes.action}
        originator={dmeOrder.originator}
      />
    ),
  ].filter(Boolean)
}

const InternalNavigation: React.FC<Props> = ({
  nextPagePath,
  pages,
  history,
  permissions,
  dmeOrder,
  patientLinkDisabled,
  updatePatient,
  updateFollowers,
  toggle,
  close,
  inOrderNavReplacement,
  currentTab,
  setSidebarTab,
  followDmeOrder,
  unfollowDmeOrder,
  unsnoozeDmeOrder,
  snoozeDmeOrder,
  isSidebarOpen,
}) => {
  const [open, setOpen] = useState<boolean>(false)
  const [showFollowersModal, setShowFollowersModal] = useState<boolean>(false)
  const [showPatientModal, setShowPatientModal] = useState<boolean>(false)
  const [showSnoozeModal, setShowSnoozeModal] = useState<boolean>(false)

  useEffect(() => {
    const onClose = () => {
      setOpen(false)
      close()
    }

    history.listen(onClose)
  }, [history, close])

  const closeFollowersModal = () => {
    setOpen(true)
    setShowFollowersModal(false)
  }

  const onEditPatientClick = () => {
    if (patientLinkEditable()) {
      setOpen(false)
      setShowPatientModal(true)
    }
  }

  const closePatientModal = () => {
    setOpen(true)
    setShowPatientModal(false)
  }

  const onUpdatePatient = (params: PatientFormParams) => {
    const patientId = dmeOrder.patient.id
    return updatePatient(patientId, params).then(() => {
      setOpen(true)
      setShowPatientModal(false)
    })
  }

  const onToggle = () => {
    setOpen(!open)
    toggle()
  }

  const onFollowersClick = () => {
    setShowFollowersModal(true)
    setOpen(false)
  }

  const patientLinkEditable = () => {
    return (
      permissions.patientEditable ||
      permissions.mrnEditable ||
      permissions.supplierSystemPatientLinkage.manage
    )
  }

  const patientLink = () => {
    return (
      <PatientLink
        disabled={patientLinkDisabled}
        editable={patientLinkEditable()}
        supplierPatientIdVisible={permissions.supplierSystemPatientLinkage.show}
        dmeOrder={dmeOrder}
        onClick={onEditPatientClick}
      />
    )
  }

  const { followers } = dmeOrder

  return (
    <>
      {inOrderNavReplacement && (
        <TopNavigation
          currentTab={currentTab}
          setSidebarTab={setSidebarTab}
          showTransferTab={
            dmeOrder.permissions.sidebar.showTransferredOrderOriginalCart
          }
          dmeOrder={dmeOrder}
        />
      )}
      <div className="workflow-left-nav nav-primary">
        <div
          className={`d-none d-lg-flex flex-column justify-content-between ${styles.container}`}
        >
          <div>
            <LeftNavigation
              buttons={getButtons({
                classes: {
                  followers: styles.transparent,
                  action: styles.transparent,
                },
                followers,
                onFollowersClick,
                permissions,
                dmeOrder,
              })}
              originator={dmeOrder!.originator!}
              patientLink={patientLink()}
              pages={pages}
              nextPagePath={nextPagePath}
              dmeOrder={dmeOrder}
              followDmeOrder={followDmeOrder}
              unfollowDmeOrder={unfollowDmeOrder}
              setShowSnoozeModal={setShowSnoozeModal}
              unsnoozeDmeOrder={unsnoozeDmeOrder}
              showSnoozeModal={showSnoozeModal}
              snoozeDmeOrder={snoozeDmeOrder}
              permissions={permissions}
            />
          </div>
          <CanopyBackedFeedbackButton />
        </div>
        <div
          className={cx({
            "d-none d-md-block d-lg-none": isSidebarOpen,
            "d-block d-lg-none": !isSidebarOpen,
          })}
        >
          <BottomNavigation
            open={open}
            buttons={getButtons({
              classes: {
                followers: "",
                action: "",
              },
              followers,
              onFollowersClick,
              permissions,
              dmeOrder,
            })}
            dmeOrder={dmeOrder}
            patientLink={patientLink()}
            pages={pages}
            nextPagePath={nextPagePath}
            toggle={onToggle}
            originator={dmeOrder!.originator!}
            followDmeOrder={followDmeOrder}
            unfollowDmeOrder={unfollowDmeOrder}
            snoozeDmeOrder={snoozeDmeOrder}
            unsnoozeDmeOrder={unsnoozeDmeOrder}
            permissions={permissions}
            followers={followers}
            onFollowersClick={onFollowersClick}
          />
        </div>
        {showFollowersModal && (
          <FollowersModal
            show={showFollowersModal}
            closeModal={closeFollowersModal}
            dmeOrder={dmeOrder}
            updateFollowers={updateFollowers}
            editable={permissions.follow}
          />
        )}
        {showPatientModal && (
          <PatientModal
            show={showPatientModal}
            cancel={closePatientModal}
            dmeOrder={dmeOrder}
            updatePatient={onUpdatePatient}
            demographicsEditable={permissions.patientEditable}
            mrnEditable={permissions.mrnEditable}
            supplierPatientIdEditable={
              permissions.supplierSystemPatientLinkage.manage
            }
            supplierPatientIdVisible={
              permissions.supplierSystemPatientLinkage.show
            }
          />
        )}
      </div>
    </>
  )
}

export default InternalNavigation
