import React from "react"
import { Nullable } from "sharedTypes"

import { useFormikParachute } from "applications/shared/hooks/useFormikParachute/useFormikParachute"
import { CanopyButton } from "@parachutehealth/canopy-button"
import { CanopyForm } from "@parachutehealth/canopy-form"
import { CanopyTextInputField } from "@parachutehealth/canopy-text-input-field"
import { CanopyFormFieldGroup } from "@parachutehealth/canopy-form-field-group"
import {
  CanopySelectField,
  SelectOptions,
} from "@parachutehealth/canopy-select-field"
import { states } from "utilities/address"
import { updateClinicalFacility } from "./api"
import { navigate } from "utilities/navigation"
import { baseUrl } from "utilities/url"
import FaxNumbersFormGroup from "./components/FaxNumbersFormGroup"

type ClinicalFacility = {
  name: string
  npi: Nullable<string>
  line1: string
  line2: Nullable<string>
  city: string
  state: string
  zip: string
  email: Nullable<string>
  phoneNumber: string
  faxNumber: Nullable<string>
  faxNumbers: string[]
  timeZone: string
  facilityType: string
}
type Props = {
  clinicalFacility: ClinicalFacility
  facilityTypes: SelectOptions
  timeZones: SelectOptions
  multipleFaxNumbers: boolean
}

type NonNullableProperties<T> = {
  [P in keyof T]: T[P] extends Nullable<infer U> ? U : T[P]
}

const blankValues = <T extends Record<string, any>>(
  values: T
): NonNullableProperties<T> => {
  return Object.fromEntries(
    Object.entries(values).map(([key, value]) => [key, value ?? ""])
  ) as NonNullableProperties<T>
}

const ClinicalFacilityManageSettings = (props: Props) => {
  const formik = useFormikParachute({
    initialValues: blankValues(props.clinicalFacility),
    onSubmit: (values, formikHelpers) =>
      updateClinicalFacility(values)
        .then(() => {
          navigate(baseUrl())
        })
        .catch(({ response }) => {
          formikHelpers.setErrors(response.data.errors)
        }),
  })

  return (
    <div style={{ width: "500px" }}>
      <h1>Edit Clinical Facility</h1>
      <CanopyForm onSubmit={formik.handleSubmit}>
        <CanopyTextInputField
          label="Name"
          name="name"
          onChange={formik.handleChange}
          value={formik.values.name}
          feedbackMessage={formik.errors.name}
        />
        <CanopyTextInputField
          label="Facility NPI (NPI-2)"
          name="npi"
          optional
          onChange={formik.handleChange}
          value={formik.values.npi}
          feedbackMessage={formik.errors.npi}
        />
        <CanopyTextInputField
          label="Address Line 1"
          name="line1"
          onChange={formik.handleChange}
          value={formik.values.line1}
          feedbackMessage={formik.errors.line1}
        />
        <CanopyTextInputField
          label="Address Line 2"
          name="line2"
          optional
          onChange={formik.handleChange}
          value={formik.values.line2}
          feedbackMessage={formik.errors.line2}
        />
        <CanopyFormFieldGroup label="">
          {() => (
            <>
              <CanopyTextInputField
                label="City"
                name="city"
                onChange={formik.handleChange}
                value={formik.values.city}
                feedbackMessage={formik.errors.city}
              />
              <CanopySelectField
                label="State"
                name="state"
                options={states.map((state) => ({
                  label: state[0],
                  value: state[1],
                }))}
                onChange={formik.handleChange}
                value={formik.values.state}
                feedbackMessage={formik.errors.state}
              />
              <CanopyTextInputField
                label="Zip"
                name="zip"
                onChange={formik.handleChange}
                value={formik.values.zip}
                feedbackMessage={formik.errors.zip}
              />
            </>
          )}
        </CanopyFormFieldGroup>
        <CanopyTextInputField
          label="Email"
          name="email"
          optional
          onChange={formik.handleChange}
          value={formik.values.email}
          feedbackMessage={formik.errors.email}
        />
        <CanopyTextInputField
          label="Phone Number"
          name="phoneNumber"
          onChange={formik.handleChange}
          value={formik.values.phoneNumber}
          feedbackMessage={formik.errors.phoneNumber}
        />
        {props.multipleFaxNumbers && (
          <FaxNumbersFormGroup
            faxNumbers={props.clinicalFacility.faxNumbers}
            onChange={(faxNumbers) => {
              formik.setFieldValue("faxNumbers", faxNumbers)
            }}
          />
        )}
        {!props.multipleFaxNumbers && (
          <CanopyTextInputField
            label="Fax Number"
            name="faxNumber"
            optional
            onChange={formik.handleChange}
            value={formik.values.faxNumber}
            feedbackMessage={formik.errors.faxNumber}
          />
        )}
        <CanopySelectField
          label="Time Zone"
          name="timeZone"
          onChange={formik.handleChange}
          value={formik.values.timeZone}
          feedbackMessage={formik.errors.timeZone}
          options={props.timeZones}
        />
        <CanopySelectField
          label="Facility Type"
          name="facilityType"
          options={props.facilityTypes}
          onChange={formik.handleChange}
          value={formik.values.facilityType}
          feedbackMessage={formik.errors.facilityType}
        />
        <CanopyButton type="submit" loading={formik.isSubmitting}>
          Update Clinical Facility
        </CanopyButton>
      </CanopyForm>
    </div>
  )
}

export default ClinicalFacilityManageSettings
