import React, { useCallback, useEffect, useRef, useState } from "react"
import { ClinicalFacility } from "sharedTypes"
import { useDebounce } from "hooks/useDebounce"
import DashboardHeader from "../DashboardHeader"
import DashboardColumn from "../DashboardColumn"
import LeftSidebar from "components/ClinicalDashboard/LeftSidebar"
import { useFeatureFlags } from "components/FeatureFlagContext"
import {
  SidebarListDoctor,
  SidebarListFacility,
} from "components/ClinicalDashboard/sharedTypes"
import { useEffectThatWontRunOnMount } from "hooks/useEffectThatWontRunOnMount"
import { AxiosPromise } from "axios"
import { canopyColorTextSecondary } from "@parachutehealth/canopy-tokens-color"
import { CanopyButton } from "@parachutehealth/canopy-button"
import Icon from "components/Icon"
import { CanopyIcon } from "@parachutehealth/canopy-icon"
import { CanopyText } from "@parachutehealth/canopy-text"

type Props = {
  clinicalFacility: ClinicalFacility
  limit: number
  title: "Incoming orders" | "Sent back for review" | "New messages"
  subtitle: string
  unreadEventCounts: object
  getClinicalFacilityDashboardResults(values?): Promise<any>
  sidebarInformation: {
    incomingOrdersEnabled: boolean
    isReviewer: boolean
    lastAccessedFacilityEmployerId?: string
    doctors?: SidebarListDoctor[]
    facilities?: SidebarListFacility[]
    lastAccessedDoctorEmployerId?: string
  }
  sidebarExpanded: boolean
  updateSidebarExpandedPreference: (sidebarExpanded: boolean) => AxiosPromise
}

const InternalClinicalFacilityDashboardSubpage: React.FC<Props> = ({
  clinicalFacility,
  limit,
  title,
  subtitle,
  getClinicalFacilityDashboardResults,
  sidebarInformation,
  sidebarExpanded,
  updateSidebarExpandedPreference,
}: Props) => {
  const [loading, setLoading] = useState(true)
  const [inProgressOrders, setInProgressOrders] = useState([])
  const [submittedOrders, setSubmittedOrders] = useState([])
  const [doneOrders, setDoneOrders] = useState([])
  const [unreadEventCounts, setUnreadEventCounts] = useState({})
  const { isFeatureEnabled } = useFeatureFlags()
  const incomingOrdersEnabledSetting = isFeatureEnabled(
    "incomingOrdersEnabledSetting"
  )
  const [initialLeftSidebarExpanded, setInitialLeftSidebarExpanded] = useState<
    boolean
  >(sidebarExpanded)

  const debouncedGetClinicalFacilityDashboardResults = useDebounce(
    getClinicalFacilityDashboardResults
  )
  const fetch = useCallback(
    () =>
      debouncedGetClinicalFacilityDashboardResults()
        .then((results) => {
          setInProgressOrders(results.inProgressOrders)
          setSubmittedOrders(results.submittedOrders)
          setDoneOrders(results.doneOrders)
          setUnreadEventCounts(results.unreadEventCounts)
          setLoading(false)
        })
        .catch(() => {
          setLoading(false)
        }),
    [debouncedGetClinicalFacilityDashboardResults]
  )

  useEffect(() => {
    fetch()
  }, [fetch])

  function onToggleExpand() {
    setInitialLeftSidebarExpanded(!initialLeftSidebarExpanded)
  }

  useEffectThatWontRunOnMount(() => {
    updateSidebarExpandedPreference(initialLeftSidebarExpanded)
  }, [initialLeftSidebarExpanded])

  const noOrders =
    !loading &&
    !inProgressOrders.length &&
    !submittedOrders.length &&
    !doneOrders.length

  const emptyStateMessage =
    title === "New messages"
      ? "No orders at your facility have new messages."
      : title === "Incoming orders"
      ? "There are no incoming orders at your facility"
      : title === "Sent back for review"
      ? "No orders at your facility have been sent back for review"
      : "" // This should never happen

  const columnRef = useRef<HTMLDivElement>(null)
  const [columnWidth, setColumnWidth] = useState(0)
  const handleResize = () => {
    if (columnRef.current) {
      setColumnWidth(columnRef.current.offsetWidth)
    }
  }

  useEffect(() => {
    handleResize()
    window.addEventListener("resize", handleResize)

    return () => {
      window.removeEventListener("resize", handleResize)
    }
  }, [initialLeftSidebarExpanded])

  return (
    <div className="position-relative">
      <LeftSidebar
        active={LeftSidebar.Options.Orders}
        sidebarInformation={sidebarInformation}
        showSignatureRequestsDashboardNewPill={false}
        featureFlags={{
          incomingOrdersEnabledSetting: !!incomingOrdersEnabledSetting,
        }}
        onToggleExpand={onToggleExpand}
        expandLeftSidebar={initialLeftSidebarExpanded}
      />
      <div className="row mt-n3">
        <div className="col-12 canopy-mbs-12x">
          <>
            <CanopyButton
              as="a"
              accessibleText="navigate back"
              className="canopy-pie-4x"
              href={`${window.location.origin}/u/f/${clinicalFacility.externalId}`}
              iconStart="arrow-left"
              size="large"
              variant="tertiary"
            >
              {`View all orders at ${clinicalFacility.name}`}
            </CanopyButton>
            <p
              className="canopy-typography-heading-2xlarge canopy-mt-16x"
              aria-label="page title"
            >
              {title}
            </p>
            <h3
              className="canopy-typography-body-medium canopy-mbe-0 canopy-pb-8x"
              aria-label="subtitle"
              style={{ color: canopyColorTextSecondary }}
            >
              {subtitle}
            </h3>
          </>
          {loading ? (
            <div className="text-center">
              <Icon type="spinner" spin />
            </div>
          ) : noOrders ? (
            <div className="js-order-columns">
              <div className="row">
                <div className="col-lg-4 col-12">
                  <DashboardHeader
                    title="Orders in progress"
                    limit={limit}
                    count={inProgressOrders.length}
                  />
                </div>
                <div className="col-lg-4 col-12">
                  <DashboardHeader
                    title="Sent to supplier"
                    limit={limit}
                    count={submittedOrders.length}
                  />
                </div>
                <div className="col-lg-4 col-12">
                  <DashboardHeader
                    title="Accepted by supplier"
                    limit={limit}
                    count={doneOrders.length}
                  />
                </div>
              </div>
              <div className="row">
                <div className="col-12">
                  <div
                    className="d-flex flex-column align-items-center justify-content-center"
                    style={{ marginTop: "76px" }}
                  >
                    <CanopyIcon
                      className="canopy-mbe-16x"
                      fill="canopyColorBrandSecondary"
                      name="sparkles"
                      size="large"
                    />
                    <h4 className="canopy-typography-heading-2xlarge canopy-mbe-8x">
                      Your work here is done!
                    </h4>
                    <CanopyText className="canopy-mbe-8x">
                      {emptyStateMessage}
                    </CanopyText>
                    <CanopyButton
                      as="a"
                      href={`${window.location.origin}/u/f/${clinicalFacility.externalId}`}
                      variant="secondary"
                    >
                      View all orders
                    </CanopyButton>
                  </div>
                </div>
              </div>
            </div>
          ) : (
            <div className="js-order-columns">
              <div className="row">
                <div className="col-lg-4 col-12" ref={columnRef}>
                  <DashboardHeader
                    title="Orders in progress"
                    limit={limit}
                    count={inProgressOrders.length}
                  />
                  <DashboardColumn
                    fetchData={fetch}
                    dmeOrders={inProgressOrders}
                    clinicalFacility={clinicalFacility}
                    unreadEventCounts={unreadEventCounts}
                    limit={limit}
                    width={columnWidth}
                    showProgress
                  />
                </div>
                <div className="col-lg-4 col-12">
                  <DashboardHeader
                    title="Sent to supplier"
                    limit={limit}
                    count={submittedOrders.length}
                  />
                  <DashboardColumn
                    fetchData={fetch}
                    dmeOrders={submittedOrders}
                    clinicalFacility={clinicalFacility}
                    unreadEventCounts={unreadEventCounts}
                    limit={limit}
                    width={columnWidth}
                    showSwatch
                    showMissingDocument
                  />
                </div>
                <div className="col-lg-4 col-12">
                  <DashboardHeader
                    title="Accepted by supplier"
                    limit={limit}
                    count={doneOrders.length}
                  />
                  <DashboardColumn
                    fetchData={fetch}
                    dmeOrders={doneOrders}
                    clinicalFacility={clinicalFacility}
                    unreadEventCounts={unreadEventCounts}
                    limit={limit}
                    width={columnWidth}
                    showSwatch
                  />
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  )
}

export default InternalClinicalFacilityDashboardSubpage
