import React, {
  useState,
  Dispatch,
  SetStateAction,
  useRef,
  useEffect,
} from "react"
import { CanopyButton } from "@parachutehealth/canopy-button"
import { canopyColorTextDanger } from "@parachutehealth/canopy-tokens-color"
import { Originator } from "sharedTypes"
import OrderActionsDropdownButton from "applications/Workflow/containers/Navigation/components/OrderActionsDropdown/OrderActionsDropdownButton"
import CancelModal, {
  CancelModalRef,
} from "components/ClinicalModals/CancelModal"
import { navigate } from "utilities/navigation"
import {
  incomingOrdersUrl,
  employerHomeUrlWithCanceledOrderId,
  currentOrderId,
} from "applications/Workflow/urls"
import { cancelOrder } from "components/ClinicalModals/api"

type Props = {
  className?: string
  orderActionMenu?: boolean
  originator: Originator
  setOrderActionsDropdownOpen?: Dispatch<SetStateAction<boolean>>
  removeClickOutsideDropdownMenuListener?(): void
  patientName: string
}

const CancelButton = ({
  className,
  orderActionMenu = false,
  originator,
  setOrderActionsDropdownOpen,
  removeClickOutsideDropdownMenuListener,
  patientName,
}: Props) => {
  const [cancelWithReason, setCancelWithReason] = useState(false)
  const cancelModalRef = useRef<CancelModalRef>(null)
  const activatorElementRef = React.useRef<HTMLButtonElement>(null)

  useEffect(() => {
    setCancelWithReason(cancelModalRef.current?.cancelWithReason ?? false)
  }, [])

  const onClick = () => {
    cancelModalRef.current?.open()
  }

  const cancelDmeOrder = async (
    cancelReason?: string,
    cancelComment?: string
  ) => {
    await cancelOrder(cancelReason, cancelComment, currentOrderId())
    if (document.cookie.includes("incoming_orders_workflow_back_path")) {
      navigate(incomingOrdersUrl)
    } else {
      navigate(employerHomeUrlWithCanceledOrderId(currentOrderId()))
    }
  }

  return (
    <>
      {orderActionMenu ? (
        <OrderActionsDropdownButton
          iconName="xmark"
          title="Cancel&nbsp;order"
          subtitle={
            cancelWithReason
              ? "End the order and provide a reason"
              : "Delete the order permanently"
          }
          onClick={onClick}
          danger
        />
      ) : (
        <CanopyButton
          fullWidth
          onClick={onClick}
          type="button"
          style={{ color: canopyColorTextDanger }}
          className={className}
          variant="tertiary"
          ref={activatorElementRef}
        >
          Cancel Order
        </CanopyButton>
      )}
      <CancelModal
        patientName={patientName}
        cancelDmeOrder={cancelDmeOrder}
        ref={cancelModalRef}
        activatorElementRef={activatorElementRef}
        onClose={() => setOrderActionsDropdownOpen?.(false)}
        originator={originator}
        removeClickOutsideDropdownMenuListener={
          removeClickOutsideDropdownMenuListener
        }
      />
    </>
  )
}

export default CancelButton
