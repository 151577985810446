import React from "react"
import { Link } from "react-router-dom"
import classNames from "classnames"
import { CanopyIcon } from "@parachutehealth/canopy-icon"
import { CanopyButton } from "@parachutehealth/canopy-button"
import * as styles from "./Diagnosis.module.scss"

interface DiagnosisProps {
  therapyPath: string
}

const Diagosis = ({ therapyPath }: DiagnosisProps) => {
  return (
    <div className="canopy-ml-8x">
      <div className="canopy-mb-8x">
        <Link
          className={classNames(`link ${styles.linkText}`)}
          to={therapyPath}
        >
          <CanopyIcon name="arrow-left" className="canopy-mb-2x canopy-mr-4x" />
          <span>Back to therapy</span>
        </Link>
      </div>

      <h1 className="canopy-typography-heading-xlarge">Diagnosis</h1>
      {/* todo: replace this alert with error notice in WF-28993 */}
      <CanopyButton
        as={Link}
        to="#"
        onClick={() => alert("you clicked the button!")}
      >
        Continue to qualification
      </CanopyButton>
    </div>
  )
}

export default Diagosis
