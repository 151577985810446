import { useEffect, useState } from "react"
import {
  GridColDef,
  GridFilterInputValue,
  GridFilterModel,
  GridFilterOperator,
  GridLinkOperator,
  GridSortModel,
} from "@mui/x-data-grid-pro"
import isEqual from "lodash.isequal"
import { useHistory } from "react-router-dom"
import { toRailsStyle } from "../../../utilities/urlSearchParams"
import { handleError } from "../../../utilities/error"
import { ApplicationError } from "../../../sharedTypes"
import {
  camelizeSortModel,
  parameterizeFilterModel,
  parameterizeSortModel,
} from "../utilities/dataGrid"
import { camelCase } from "lodash"

export type ColumnFilter = {
  name: string
  operator: string
  value: string
}

type ServerSideDataGridFilters = {
  columnFilters?: ColumnFilter[]
  columnFilterLinkOperator?: GridLinkOperator | null
}

// alias the MUI type to centralize the dependency here
export type ServerSideDataGridSort = GridSortModel

export type ServerSideDataGridOptions = {
  page: number
  sort?: ServerSideDataGridSort
  filterModel?: GridFilterModel
}

export type ServerSideDataGridParams = {
  page?: number
  sort?: string[] // serialized as ["column:direction", "column2:direction"]
  columnFilters?: string[]
  columnFilterLinkOperator?: GridLinkOperator
}

export type ServerSideDataGridHookProps<T extends ServerSideDataGridOptions> = {
  defaultFilterModel?: ServerSideDataGridFilters
  defaultOptions?: T
  columnDefinitions: GridColDef[]
  trackHistory?: boolean
  fetchFunction: (params: ServerSideDataGridParams) => Promise<void>
  beforeFetch?: () => void
  afterFetch?: () => void
}

export const arrayContainsGridFilterOperator: GridFilterOperator = {
  label: "contains",
  value: "arrayContains",
  getApplyFilterFn: (_filterItem, _column) => null,
  InputComponent: GridFilterInputValue,
}

const useServerSideDataGrid = <T extends ServerSideDataGridOptions>({
  defaultFilterModel,
  defaultOptions,
  columnDefinitions,
  fetchFunction,
  beforeFetch,
  afterFetch,
  trackHistory = true,
}: ServerSideDataGridHookProps<T>): {
  options: T
  filterModel?: GridFilterModel
  handlePageChange: (newPage: number) => void
  handleFilterChange: (name: keyof T, newValue: any) => void
  handleFilterModelChange: (model: GridFilterModel) => void
  handleSortModelChange: (model: GridSortModel) => void
  optionsToParams: (options: T) => ServerSideDataGridParams
  refetch: () => Promise<void>
} => {
  const history = useHistory()

  const [filterModel, setFilterModel] = useState<GridFilterModel | undefined>(
    convertFilterToModel(defaultFilterModel)
  )

  const setupDefaultOptions = (defaultOptions?: T): T => {
    return {
      page: 1,
      ...defaultOptions,
      filterModel: filterModel,
      sort: camelizeSortModel(defaultOptions?.sort),
    } as T // hacky
  }

  const [options, setOptions] = useState<T>(setupDefaultOptions(defaultOptions))

  const refetch = async () => {
    if (beforeFetch) beforeFetch()
    await fetchData().then(() => {
      if (afterFetch) afterFetch()
    })
  }

  useEffect(() => {
    void refetch()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [options])

  const handlePageChange = (newPage: number): void => {
    setOptions((prev) => ({
      ...prev,
      page: newPage,
    }))
  }

  /**
   * Convert the Options object (which encapsulates all the various toggles and filters on the DataGrid page)
   * into a meaningful one-dimensional parameters object that can be serialized as query parameters.
   *
   * It performs transformations on the minimum necessary properties for the Datagrid (filters, sorts, pages) and will
   * include any other properties as-is.
   */
  const optionsToParams = (options: T): ServerSideDataGridParams => {
    const { page, filterModel, sort, ...other } = options

    const parameterizedSortModel = parameterizeSortModel(sort)
    const filters = parameterizeFilterModel(columnDefinitions, filterModel)
    const linkOperator =
      filters && filters.length > 0 ? filterModel?.linkOperator : undefined

    return {
      page,
      sort: parameterizedSortModel,
      columnFilters: filters,
      columnFilterLinkOperator: linkOperator,
      // TODO: this might generate garbage if there are complex objects in the options subtype;
      // do we need to allow the passing of an optional function to extract a Record<string, any>?
      ...other,
    }
  }

  /**
   * This private function wraps the _real_ data fetching function provided by the implementing component.
   * It handles the error handling, history management, and options->parameters conversion
   */
  const fetchData = async (): Promise<void> => {
    try {
      const params = optionsToParams(options)

      await fetchFunction(params)

      if (trackHistory) generateHistory(params)
    } catch (e) {
      handleError(e as ApplicationError)
    }
  }

  const generateHistory = (params: Record<string, any>) => {
    history.push({
      pathname: history.location.pathname,
      search: toRailsStyle(params).toString(),
    })
  }

  const handleFilterChange = (name: keyof T, newValue: any): void => {
    setOptions((prev) => ({
      ...prev,
      [name]: newValue,
      page: 1, // if search criteria change, reset to page 1
    }))
  }

  const handleSortModelChange = (newSortModel: GridSortModel) => {
    if (!isEqual(newSortModel, options.sort)) {
      setOptions((prev) => {
        return {
          ...prev,
          sort: newSortModel,
        }
      })
    }
  }

  const handleFilterModelChange = (model: GridFilterModel): void => {
    // MUI needs to update *its* current grid model for the filter selection to proceed,
    // even if we don't end up updating our internal state model
    setFilterModel(model)

    const completeFilterModel = {
      linkOperator: model.linkOperator || GridLinkOperator.And,
      items: model.items.filter(
        (item) => item.columnField && item.operatorValue && item.value
      ),
    }

    if (isEqual(completeFilterModel, options?.filterModel)) {
      return
    }

    handleFilterChange("filterModel", completeFilterModel)
  }

  return {
    filterModel,
    options,
    handleFilterChange,
    handleSortModelChange,
    handlePageChange,
    handleFilterModelChange,
    optionsToParams,
    refetch,
  }
}

/**
 * Converts pre-selected filters sent by the controller into the format that DataGrid
 * needs.
 */
const convertFilterToModel = (
  raw?: ServerSideDataGridFilters
): GridFilterModel | undefined => {
  if (!raw) {
    return undefined
  }
  return {
    linkOperator: raw?.columnFilterLinkOperator || GridLinkOperator.And,
    items:
      raw?.columnFilters?.map((cf, index) => ({
        id: index,
        columnField: camelCase(cf.name),
        operatorValue: cf.operator,
        value: cf.value,
      })) || [],
  }
}

export default useServerSideDataGrid
