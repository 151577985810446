import React, { useState } from "react"
import { CanopyNotice } from "@parachutehealth/canopy-notice"
import { ChartNoteHelperType } from "../sharedTypes"
import { CanopyDialogModal } from "@parachutehealth/canopy-dialog-modal"
import { CanopyButton } from "@parachutehealth/canopy-button"

type Props = {
  type: ChartNoteHelperType
  hasDefaultChoices?: boolean
  isOptum: boolean
}

function ChartNoteHelper({ type, hasDefaultChoices, isOptum }: Props) {
  const [show, setShow] = useState(false)
  const showModal = () => setShow(true)
  const hideModal = () => setShow(false)
  const [showExternal, setShowExternal] = useState(false)
  const showExternalModal = () => setShowExternal(true)
  const hideExternalModal = () => setShowExternal(false)

  return (
    <>
      <div className="well-wide">
        {isOptum ? (
          <CanopyNotice
            title="The following information is required by the patient's health
              plan"
            message="Please select answers that match the patient’s condition,
                  which will be used to assess medical necessity for prior
                  authorization."
            actions={[
              {
                onClick: () => showExternalModal(),
                text: "Why is this needed?",
              },
            ]}
          />
        ) : (
          <div>
            <strong>
              The following information is required by the supplier(s) on this
              order.
            </strong>
            <p>
              {type === ChartNoteHelperType.Prefill &&
                hasDefaultChoices &&
                "Most common conditions have been selected. "}
              {type === ChartNoteHelperType.Common &&
                hasDefaultChoices &&
                "Most common conditions have been highlighted. "}
              <span>
                Please select answers that match the patient’s condition.{" "}
              </span>
              <a className="link color-info" onClick={showModal}>
                Learn more
              </a>
            </p>
          </div>
        )}
      </div>

      <CanopyDialogModal
        header="About health plan medical review"
        onClose={() => hideExternalModal()}
        open={showExternal}
        primaryFooterButton={
          <CanopyButton onClick={hideExternalModal}>Got it</CanopyButton>
        }
        size="medium"
      >
        We’ve embedded medical necessity criteria defined by the patient’s
        health plan and specific to the durable medical equipment (DME) being
        ordered in an easy to navigate question and answer format.
        <h2 className="canopy-typography-heading-medium canopy-mt-6x">
          How will the answers be used?
        </h2>
        They will be used to determine if a piece of equipment is medically
        necessary and meets coverage requirements. The answers you provide in
        this section will be sent to the patient’s health plan along with the
        authorization request to be completed by the supplier.
      </CanopyDialogModal>

      <CanopyDialogModal
        header="About Documentation"
        onClose={() => hideModal()}
        open={show}
        primaryFooterButton={
          <CanopyButton onClick={hideModal}>Got it</CanopyButton>
        }
        size="medium"
      >
        <h4>What is the purpose of documentation?</h4>
        <p className="color-dark-gray canopy-mbe-12x">
          Suppliers of medical equipment, documents, and services require
          clinical information to process and submit orders to payers for
          reimbursement.
        </p>
        <h4>Why are there variations of documentation?</h4>
        <p className="color-dark-gray">
          Each supplier may require slightly different information. Parachute
          works with connected suppliers to determine the information required,
          how that information is displayed, and the way questions must be
          completed.
        </p>
      </CanopyDialogModal>
    </>
  )
}

export default ChartNoteHelper
